const React = require("react");
const createReactClass = require("create-react-class");
const Mousetrap = require("mousetrap");
const _ = require("underscore");

const Popover = createReactClass({
    displayName: "Popover",

    getInitialState() {
        return {
            style: this.getStyle(this.props),
        };
    },

    componentDidMount() {
        this._isMounted = true;
        const self = this;

        this._previousMouseBindings = Mousetrap.unbindWithHelp("esc", true)[0];
        Mousetrap.bindWithHelp("esc", () => {
            if (self && self.props) {
                self.props.onClose();
            }
            return false;
        });
        this.updateStyle();
    },

    getDefaultProps() {
        return {
            animate: true,
            style: {},
        };
    },

    componentDidUpdate(prevProps) {
        this.updateStyle(this.props, prevProps);
    },

    componentWillUnmount() {
        this._isMounted = false;
        Mousetrap.bindWithHelp("esc", this._previousMouseBindings);
    },

    getStyle(props) {
        const MIN_DISTANCE_TOP = 22;
        const target = props.target;

        if (target === "center") {
            if (!this._isMounted) {
                return { top: 0, left: 0 };
            }

            return _.extend({}, props.style, {
                top: MIN_DISTANCE_TOP,
                /*top: Math.max(
                    // eslint-disable-next-line no-magic-numbers
                    Math.floor((document.body.clientHeight - this.refs.content.offsetHeight) / 2),
                    MIN_DISTANCE_TOP
                ),*/
                // eslint-disable-next-line no-magic-numbers
                left: Math.floor((document.body.clientWidth - this.refs.content.offsetWidth) / 2),
                maxHeight: document.body.clientHeight,
            });
        }

        const style = _.extend({}, props.style, {
            left: target.left + target.width,
            top: target.top,
        });

        // Count from bottom if value is provided
        if (target.bottom) {
            style.top = undefined;
            style.bottom = target.bottom;
        }

        if (!this._isMounted) {
            return style;
        }

        const isWidthInPixels = !_.isNullish(style.width) && _.isNumber(style.width);
        if (isWidthInPixels && style.left + style.width > document.body.clientWidth) {
            if (target.left - style.width > 0) {
                style.left = target.left - style.width;
            } else {
                style.left = target.left;
            }
        }

        if (style.top + this.refs.content.offsetHeight > document.body.clientHeight) {
            style.top = target.top + target.height - this.refs.content.offsetHeight;
        }

        if (style.top < 0) {
            style.top = 0;
        }

        const boundingRect = this.refs.content.getBoundingClientRect();
        style.height = boundingRect.height;
        style.maxHeight = document.body.clientHeight;
        if (style.bottom) {
            style.maxHeight = boundingRect.bottom;
        }
        if (!target.bottom && style.top < MIN_DISTANCE_TOP) {
            style.top = MIN_DISTANCE_TOP;

            if (style.maxHeight + style.top > document.body.clientHeight) {
                style.maxHeight = document.body.clientHeight - style.top;
            }
        }
        if (style.top === 0) {
            style.top = undefined;
        }
        return style;
    },

    updateStyle(props = this.props, prevProps) {
        if (prevProps) {
            if (prevProps.target === this.props.target && _.isEqual(prevProps.style, props.style)) {
                return;
            }
        }
        const style = this.getStyle(props);
        if (!_.isEqual(style, this.state.style)) {
            this.setState({ style });
        }
    },

    render() {
        const classes = ["popup"];
        const style = _.clone(this.state.style);
        const arrowStyle = {
            top: this.props.target.top,
        };

        if (this.props.bottomArrow) {
            classes.push("below");
            // eslint-disable-next-line no-magic-numbers
            arrowStyle.left = (style.left + style.width) / 2;
        } else {
            if (style.left > this.props.target.left) {
                classes.push("left");
                arrowStyle.left = style.left;
            } else {
                classes.push("right");
                arrowStyle.left = style.left + style.width;
            }

            if (style.top < this.props.target.top) {
                classes.push("bottom");
            }
        }
        delete style.height;
        const children = React.Children.map(this.props.children, (child) =>
            React.cloneElement(child, { onContentChange: this.updateStyle })
        );
        return (
            <div className={classes.join(" ")}>
                <div ref="content" style={style}>
                    {children}
                </div>
                {this.props.target === "center" ? null : (
                    <span className="arrow" style={arrowStyle} />
                )}
            </div>
        );
    },
});

Popover.DEFAULT_WIDTH = 350;

module.exports = Popover;
