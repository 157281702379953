const React = require("react"); // Improvement: remove React dependency by passing results back to components instead of presenting modal from here.
const Language = require("../lib/Language");
import { MillenniumDateTime } from "millennium-time";
const API = require("../lib/TimeEditAPI");
const Log = require("../lib/Log");
const _ = require("underscore");

const FAKE_RESULT_CODE = -1;

const isLayerEmpty = (persistResult) => {
    const someHaveResult = _.some(
        Object.keys(persistResult).map((key) => persistResult[key]),
        (status) => status.result !== undefined
    );
    return !someHaveResult;
};

const HUNDRED_PERCENT = 100;
const NINETYNINE_PERCENT = 99;

const Experiment = {};

const splitByDates = (reservations, batchSize, movingBackward = false) => {
    const workingList = [].concat(reservations);
    const results = [];
    let first = 0;
    if (!movingBackward) {
        workingList.reverse();
    }
    while (first < workingList.length) {
        const nextBatch = [];
        const lastDate = new MillenniumDateTime(workingList[first].begin).getMillenniumDate();
        let sameDate = true;
        while (sameDate) {
            const next = workingList[first];
            const nextDate = new MillenniumDateTime(next.begin).getMillenniumDate();
            if (nextDate.getDayNumber() === lastDate.getDayNumber()) {
                nextBatch.push(next.id);
                first++;
                if (first === workingList.length) {
                    sameDate = false;
                }
            } else {
                sameDate = false;
            }
        }
        results.push(nextBatch);
    }
    return results;
};

const abort = (layerId, reportProgress, callback) => {
    reportProgress({ progress: null });
    API.deleteReservationLayer(layerId, true, (deleteSuccessful) => {
        if (!deleteSuccessful) {
            Log.error(Language.get("cal_res_side_layer_could_not_delete_layer"));
            callback(0, []);
            return;
        }
        callback(0, []);
    });
};

const addReservations = (
    reservationsToAdd,
    layerId,
    move,
    ignoreFields,
    reportProgress,
    callback
) => {
    const totalReservations = reservationsToAdd.length;
    let failures = [];
    let counter = 0;
    let groupMap = null;
    const calls = splitByDates(reservationsToAdd, API.MASS_BATCH_SIZE).map((batch) => (done) => {
        API.addToReservationLayer(
            layerId,
            batch,
            move,
            reservationsToAdd.map((res) => res.id),
            true,
            ignoreFields,
            groupMap,
            (addResult) => {
                groupMap = addResult.groupMap;
                counter += batch.length;
                const progress =
                    (counter / totalReservations) * HUNDRED_PERCENT < HUNDRED_PERCENT
                        ? Math.floor((counter / totalReservations) * HUNDRED_PERCENT)
                        : NINETYNINE_PERCENT;
                reportProgress({ progress });
                if (!addResult.success) {
                    failures = failures.concat(batch);
                } else {
                    failures = failures.concat(
                        addResult.status.filter((res, index) => {
                            if (res.details) {
                                // eslint-disable-next-line no-param-reassign
                                res.reservationId = batch[index];
                                return true;
                            }
                            return false;
                        })
                    );
                }
                done();
            },
            // eslint-disable-next-line no-unused-vars
            (errorType, failedMessage) => {
                counter += batch.length;
                const progress =
                    (counter / totalReservations) * HUNDRED_PERCENT < HUNDRED_PERCENT
                        ? Math.floor((counter / totalReservations) * HUNDRED_PERCENT)
                        : NINETYNINE_PERCENT;
                reportProgress({ progress });
                failures = failures.concat(batch);
                done();
            }
        );
    });
    _.runSync(calls, () => {
        callback(layerId, failures);
        reportProgress({ progress: null });
    });
};

const expandSelection = (reservationIds, layerId, move, ignoreFields, reportProgress, callback) => {
    API.getReservationsWithTime(
        reservationIds,
        true,
        (successful, expandedReservationList, slots) => {
            addReservations(
                expandedReservationList.map((res, index) => ({
                    id: res.id,
                    begin: new MillenniumDateTime(slots[index].begin),
                    end: new MillenniumDateTime(slots[index].end),
                })),
                layerId,
                move,
                ignoreFields,
                reportProgress,
                callback
            );
        },
        (errorType, message) => {
            // eslint-disable-next-line no-console
            console.log(errorType, message);
            abort(layerId, reportProgress, callback);
        }
    );
};

const createLayer = (
    name,
    reservationIds,
    move,
    ignoreFields,
    reportProgress,
    presentModal,
    callback
) => {
    const description = "Automatically created for experimentation";
    reportProgress({ progress: 0 });

    API.createReservationLayer(name, description, (result, status) => {
        if (!result) {
            if (status) {
                Log.error(status.details);
            } else {
                Log.error(Language.get("err_layer_name_not_unique"));
            }
            reportProgress({ progress: null });
            callback(0, []);
            return;
        }
        const newLayerId = result.id;
        API.getReservationsWithTime(
            reservationIds,
            false,
            (success, reservations, timeslots) => {
                if (!success) {
                    const messages = [
                        <li key="groupError">
                            {Language.get("nc_selection_contains_partial_groups")}
                        </li>,
                    ];
                    const buttons = [
                        {
                            title: Language.get("dialog_cancel"),
                            cb: () => abort(newLayerId, reportProgress, callback),
                        },
                        {
                            title: Language.get("nc_include_groups"),
                            cb: () => {
                                expandSelection(
                                    reservationIds,
                                    newLayerId,
                                    move,
                                    ignoreFields,
                                    reportProgress,
                                    callback
                                );
                            },
                        },
                    ];
                    const title = Language.get("nc_selection_contains_groups");
                    presentModal(<ul>{messages}</ul>, null, title, buttons, _.noop, false, true);
                } else {
                    addReservations(
                        reservations.map((res, index) => ({
                            id: res.id,
                            begin: new MillenniumDateTime(timeslots[index].begin),
                            end: new MillenniumDateTime(timeslots[index].end),
                        })),
                        newLayerId,
                        move,
                        ignoreFields,
                        reportProgress,
                        callback
                    );
                }
            },
            (errorType, message) => {
                Log.error(message);
                // eslint-disable-next-line no-console
                console.log(errorType, message);
                abort(newLayerId, reportProgress, callback);
            }
        );
    });
};

// eslint-disable-next-line no-unused-vars
const trackStart = (move, numReservations) => {
    // eslint-disable-next-line no-undef
    /*mixpanel.track(`DM started`, {
        "Number of reservations": numReservations,
        "Draft type": move ? "Change" : "Copy",
    });*/
};

Experiment.begin = (
    name,
    reservationIds,
    move,
    ignoreFields,
    reportProgress,
    presentModal,
    setActiveLayer,
    onLayerCreated,
    callback
) => {
    createLayer(
        name,
        reservationIds,
        move,
        ignoreFields,
        reportProgress,
        presentModal,
        (layerId, failures) => {
            if (layerId === 0 && failures.length === 0) {
                setActiveLayer(0);
                callback();
            }
            trackStart(move, reservationIds.length);
            const messages = failures
                .map((failed, index) => {
                    if (!failed.details) {
                        return null;
                    }
                    return (
                        <li key={`${failed.reference}:${index}`}>
                            <b>{failed.reservationId}:</b> {failed.details}{" "}
                        </li>
                    );
                })
                .filter((el) => el !== null);
            const allFailed = failures.length === reservationIds.length;
            const title = allFailed
                ? Language.get("nc_mass_change_could_not_create_draft")
                : Language.get(
                      "nc_draft_x_of_y_could_not_be_added",
                      messages.length,
                      reservationIds.length
                  );
            if (messages.length > 0) {
                presentModal(<ul>{messages}</ul>, null, title, null, _.noop, false, true);
            }
            if (allFailed) {
                API.deleteReservationLayer(layerId, true, (deleteSuccessful) => {
                    if (!deleteSuccessful) {
                        Log.error(Language.get("cal_res_side_layer_could_not_delete_layer"));
                        return;
                    }
                    setActiveLayer(0);
                    callback();
                });
            } else {
                onLayerCreated(layerId, move);
                callback();
            }
        }
    );
};

Experiment.persist = (
    activeLayer,
    ignoreUnchangedReservations,
    allowDoubleReservations,
    reportProgress,
    presentModal,
    setActiveLayer,
    callback
) => {
    const runMassChange = () => {
        reportProgress({ isWorking: true, massChangeProgress: 0 });
        let result = {};
        let totalNumber;
        API.getReservationsOnLayer(
            activeLayer,
            (resLists) => {
                // 0 - new, 1 - modified, 2 - cancelled
                const allIds = _.pluck(_.flatten(resLists), "id");
                API.getReservationsWithTime(
                    allIds,
                    true,
                    // eslint-disable-next-line no-unused-vars
                    (success, ids, timeslots, firstBegin, lastEnd) => {
                        totalNumber = ids.length;
                        const reservations = ids.map((res, index) => ({
                            id: res.id,
                            begin: new MillenniumDateTime(timeslots[index].begin),
                            end: new MillenniumDateTime(timeslots[index].end),
                        }));
                        const calls = splitByDates(reservations, API.MASS_BATCH_SIZE).map(
                            (batch) => (done) => {
                                API.persistReservationLayer(
                                    batch,
                                    ignoreUnchangedReservations,
                                    allowDoubleReservations,
                                    (res) => {
                                        // eslint-disable-next-line no-param-reassign
                                        delete res.class;
                                        result = _.extend(result, res);
                                        const numProcessed = Object.keys(result).length;
                                        reportProgress({
                                            massChangeProgress:
                                                numProcessed > 0
                                                    ? Math.round(
                                                          (numProcessed / totalNumber) *
                                                              HUNDRED_PERCENT
                                                      )
                                                    : 0,
                                        });
                                        done();
                                    },
                                    // eslint-disable-next-line no-unused-vars
                                    (errorType, failedMessage) => {
                                        const failures = {};
                                        batch.forEach(
                                            (id) =>
                                                (failures[id] = {
                                                    details: Language.get(
                                                        "nc_mass_change_took_too_long",
                                                        id
                                                    ),
                                                    result: FAKE_RESULT_CODE,
                                                    reference: id,
                                                })
                                        );
                                        result = _.extend(result, failures);
                                        const numProcessed = Object.keys(result).length;
                                        reportProgress({
                                            massChangeProgress:
                                                numProcessed > 0
                                                    ? Math.round(
                                                          (numProcessed / totalNumber) *
                                                              HUNDRED_PERCENT
                                                      )
                                                    : 0,
                                        });
                                        done();
                                    }
                                );
                            }
                        );
                        _.runSync(calls, () => {
                            reportProgress({ isWorking: false, massChangeProgress: 0 });
                            if (isLayerEmpty(result)) {
                                // eslint-disable-next-line no-undef
                                /*mixpanel.track(`DM persisted`, {
                                    "Number of reservations": totalNumber,
                                    Failures: 0,
                                });*/
                                API.deleteReservationLayer(
                                    activeLayer,
                                    true,
                                    (deleteSuccessful) => {
                                        if (!deleteSuccessful) {
                                            Log.error(
                                                Language.get(
                                                    "cal_res_side_layer_could_not_delete_layer"
                                                )
                                            );
                                            callback(activeLayer);
                                            return;
                                        }
                                        setActiveLayer(0);
                                        callback();
                                    }
                                );
                            } else {
                                const failed = Object.keys(result)
                                    .filter((key) => result[key].result !== undefined)
                                    .map((failure) => ({
                                        id: failure,
                                        result: result[failure],
                                    }))
                                    .sort((a, b) => a.result.result - b.result.result);
                                const failureList = failed.map((failure, index) => (
                                    <li key={index}>
                                        <b>{failure.id}:</b> {failure.result.details}
                                    </li>
                                ));
                                // eslint-disable-next-line no-undef
                                /*mixpanel.track(`DM persisted`, {
                                    "Number of reservations": totalNumber,
                                    Failures: failed.length,
                                });*/
                                presentModal(
                                    <div>
                                        {Language.get(
                                            "nc_draft_summary",
                                            totalNumber - failed.length,
                                            totalNumber,
                                            failed.length
                                        )}
                                        <br />
                                        <br />
                                        <ul>{failureList}</ul>
                                    </div>,
                                    "__PERFORM_MASS_CHANGE_RESULT__",
                                    Language.get("nc_asymetric_cluster_move_result_title"),
                                    null,
                                    _.noop,
                                    false,
                                    true
                                );
                                callback(activeLayer);
                            }
                        });
                    }
                );
            },
            // eslint-disable-next-line no-unused-vars
            (errorType, errorMessage) => {
                reportProgress({ isWorking: false, massChangeProgress: 0 });
                callback();
            }
        );
    };

    API.getPreferences(`dismissedModalDialogs.__EXPLAIN_MASS_CHANGE__`, (value) => {
        if (!value || value === null) {
            presentModal(
                Language.get("nc_draft_explanation", Language.get("cal_res_below_ok")),
                "__EXPLAIN_MASS_CHANGE__",
                Language.get("nc_mass_change_perform"),
                [
                    { title: Language.get("dialog_cancel") },
                    { title: Language.get("cal_res_below_ok"), cb: runMassChange },
                ]
            );
        } else {
            runMassChange();
        }
    });
};

Experiment.cancel = (activeLayer, reportProgress, presentModal, onLayerDeleted) => {
    const abortMassChangeFunction = (resLists) => {
        const allIds = _.pluck(_.flatten(resLists), "id");
        const totalNumber = allIds.length;
        reportProgress({ isWorking: true, massChangeProgress: 0 });
        // eslint-disable-next-line no-undef
        /*mixpanel.track(`DM discarded`, {
            "Number of reservations": allIds.length,
        });*/
        let result = [];
        const calls = _.splitArray(allIds, API.MASS_BATCH_SIZE).map((batch) => (done) => {
            API.deleteFromReservationLayer(
                batch,
                (res) => {
                    const numProcessed = result.length;
                    reportProgress({
                        massChangeProgress:
                            numProcessed > 0
                                ? Math.round((numProcessed / totalNumber) * HUNDRED_PERCENT)
                                : 0,
                    });
                    result = result.concat(res);
                    done();
                },
                // eslint-disable-next-line no-unused-vars
                (errorType, failedMessage) => {
                    const failureResult = {};
                    batch.forEach((id) => {
                        failureResult[id] = {
                            details: Language.get("nc_mass_change_took_too_long", id),
                            result: FAKE_RESULT_CODE,
                            reference: id,
                        };
                    });
                    result = result.concat(failureResult);
                    done();
                }
            );
        });

        _.runSync(calls, () => {
            API.deleteReservationLayer(
                activeLayer,
                true,
                (deleteSuccessful) => {
                    reportProgress({ isWorking: false, massChangeProgress: 0 });
                    if (!deleteSuccessful) {
                        Log.error(Language.get("cal_res_side_layer_could_not_delete_layer"));
                        return;
                    }
                    onLayerDeleted(0);
                },
                // eslint-disable-next-line no-unused-vars
                (errorType, failedMessage) => {
                    Log.error(Language.get("cal_res_side_layer_could_not_delete_layer"));
                }
            );
        });
    };

    API.getReservationsOnLayer(activeLayer, (resLists) => {
        // 0 - new, 1 - modified, 2 - cancelled
        const totalOnLayer = resLists.reduce((a, b) => a + b.length, 0);
        API.getPreferences(`dismissedModalDialogs.confirm_abort_mass_change`, (value) => {
            if (totalOnLayer > 0 && (value || value === null)) {
                const yesButton = {
                    title: Language.get("nc_draft_confirm_remove"),
                    cb: () => abortMassChangeFunction(resLists),
                    remember: true,
                    value: false,
                };
                const noButton = {
                    title: Language.get("nc_draft_dont_remove"),
                    remember: false,
                };
                const buttons = [yesButton, noButton];
                const message = Language.get("nc_draft_has_reservations");
                const abortMassChange = <div> {message} </div>;
                presentModal(
                    abortMassChange,
                    "confirm_abort_mass_change",
                    Language.get("nc_draft_confirm_cancel_title"),
                    buttons
                );
            } else {
                abortMassChangeFunction(resLists);
            }
        });
    });
};

module.exports = Experiment;
