const PropTypes = require("prop-types");
const React = require("react");
const ReactDOM = require("react-dom");
const Lang = require("../lib/Language");
const Mousetrap = require("mousetrap");
const pkg = require("../../package.json");
const TimeEdit = require("../lib/TimeEdit");

class About extends React.Component {
    static contextTypes = {
        user: PropTypes.object,
        env: PropTypes.object,
    };

    state = {
        info: {},
        showSupportInfoLines: this.props.showSupportText,
    };

    componentDidMount() {
        this._previousMouseBindings = Mousetrap.unbindWithHelp("esc", true)[0];
        Mousetrap.bindWithHelp("esc", this.props.close);
    }

    componentDidUpdate() {
        if (this.state.showSupportInfoLines) {
            ReactDOM.findDOMNode(this.refs.supportInfoArea).focus();
        }
    }

    componentWillUnmount() {
        Mousetrap.unbindWithHelp("esc");
        Mousetrap.bindWithHelp("esc", this._previousMouseBindings);
    }

    toggleSupportInfo = () => {
        if (this.props.supportInfoLines) {
            this.setState({ showSupportInfoLines: !this.state.showSupportInfoLines });
        }
    };

    copyToClipboard = () => {
        this.refs.supportInfoArea.select();
        document.execCommand("copy");
    };

    render() {
        let name = "";
        if (TimeEdit.serverInfo.server) {
            name = `Server ${TimeEdit.serverInfo.server.version}`;
        }

        let content = (
            <div className="versionText">
                <div className="logo" />
                {this.props.supportInfoLines[0]}
                <br />
                {name}
                <br />
                {TimeEdit.serverInfo.copyright}
                <br />
                <button className="default" onClick={this.toggleSupportInfo}>
                    {" "}
                    {Lang.get("nc_about_more_info")}{" "}
                </button>
            </div>
        );
        const infoLines = this.props.supportInfoLines
            .concat(" ")
            .concat(`Activity manager: ${pkg.dependencies["@timeedit/te-prefs-lib"]}`)
            .concat(`Exam plugin: ${pkg.dependencies["@timeedit/te-exam-plugin"]}`)
            .concat(`Auth components: ${pkg.dependencies["@timeedit/te-auth-components"]}`)
            .concat(`URL: ${window.location}`)
            .concat(`Environment: ${this.context.env.serverEnv}`)
            .concat(`Region: ${this.context.env.serverRegion}`)
            .concat(`Platform: ${this.context.env.platformVersion}`);
        if (this.state.showSupportInfoLines) {
            content = (
                <div className="versionText">
                    <textarea ref="supportInfoArea" readOnly={true} value={infoLines.join("\n")} />
                    <button className="modernButton" onClick={this.copyToClipboard}>
                        {Lang.get("cal_res_below_copy")}
                    </button>
                </div>
            );
        }

        return (
            <div className="modal aboutDialog entryFadeIn">
                <div className="close" onClick={this.props.close}>
                    X
                </div>
                <h1>{Lang.get("about_window_title")}</h1>
                <div className="inner">{content}</div>
            </div>
        );
    }
}

module.exports = About;
