const PropTypes = require("prop-types");
const React = require("react");
import { MillenniumDate, MillenniumDateTime } from "millennium-time";
const ReservationListSettings = require("./ReservationListSettings");
const Language = require("../lib/Language");
const _ = require("underscore");
const TemplateKind = require("../models/TemplateKind");
const ReservationTypeList = require("./ReservationTypeList");
const RC = require("../lib/ReservationConstants");
const API = require("../lib/TimeEditAPI");
const Reservation = require("../models/Reservation");

const COL_IDS = {
    ID: 0,
    DATE: 1,
    TIME: 2,
    TYPES: 3,
    FIELDS: 4,
    CREATED: 5,
    MODIFIED: 6,
    WEEK: 8,
    WEEKDAY: 9,
    LENGTH: 10,
    STATUS: 11,
    TAGS: 13,
};

const getAvailableColumns = (context) => {
    const columns = [
        { name: Language.get("cal_reservation_list_column_id"), sortable: true, id: COL_IDS.ID },
        {
            name: Language.get("cal_reservation_list_column_date"),
            sortable: true,
            id: COL_IDS.DATE,
        },
        {
            name: Language.get("cal_reservation_list_column_time"),
            sortable: false,
            id: COL_IDS.TIME,
        },
        {
            name: Language.get("cal_reservation_list_column_type_all"),
            sortable: false,
            id: COL_IDS.TYPES,
        },
        {
            name: Language.get("cal_reservation_list_column_field_all"),
            sortable: false,
            id: COL_IDS.FIELDS,
        },
        {
            name: Language.get("cal_reservation_list_column_created"),
            sortable: true,
            id: COL_IDS.CREATED,
        },
        {
            name: Language.get("cal_reservation_list_column_modified"),
            sortable: true,
            id: COL_IDS.MODIFIED,
        },
        {
            name: Language.get("cal_reservation_list_column_week"),
            sortable: false,
            id: COL_IDS.WEEK,
        },
        {
            name: Language.get("cal_reservation_list_column_week_day"),
            sortable: false,
            id: COL_IDS.WEEKDAY,
        },
        {
            name: Language.get("cal_reservation_list_column_length"),
            sortable: false,
            id: COL_IDS.LENGTH,
        },
        {
            name: Language.get("cal_reservation_list_column_status"),
            sortable: false,
            id: COL_IDS.STATUS,
        },
        { name: Language.get("nc_tags_title"), sortable: true, id: COL_IDS.TAGS },
    ];
    if (context.useNewReservationGroups) {
        columns.push({
            name: Language.get("nc_reservation_group_list_title"),
            sortable: false,
            id: 14,
        });
    }
    if (true) {
        // TODO Check if timezone column should be active
        columns.push({
            name: Language.get("cal_res_side_user_time_zone"),
            sortable: false,
            id: 15,
        });
    }
    return columns;
};

class RequestList extends React.Component {
    static contextTypes = {
        user: PropTypes.object,
        fireEvent: PropTypes.func,
        useNewReservationGroups: PropTypes.bool,
    };

    constructor(props, context) {
        super(props, context);
        const timeLimit = this.getTimeLimits();

        this.state = {
            validStatus: [],
            allStatus: [RC.STATUS.REQUESTED, RC.STATUS.REJECTED],
            searchOptions: {
                templateKind: TemplateKind.RESERVATION,
                searchString: "",
                beginTime: timeLimit.begin,
                endTime: timeLimit.end,
                name: "",
                allReservations: true,
                reservationStatus: [RC.STATUS.REQUESTED, RC.STATUS.REJECTED],
                includeMembers: RC.MEMBERS.EXCLUDE,
                useTemplateGroup: false,
                templateGroup: 0,
                reservationVariant: RC.VARIANT.RESERVATION,
                complete: false,
                incomplete: true,
                permission: RC.PERMISSION.READ,
                objectCategories: [],
                reservationCategories: [],
                placeholderType: null,
            },
            availableReservationCategories: [],
            availableReservationCheckboxes: [],
            availableObjectCategories: [],
            availableObjectCheckboxes: [],
        };
    }

    componentDidMount() {
        API.getObjectCategories(this.setState.bind(this));
    }

    componentDidUpdate(prevProps) {
        if (prevProps.data.limits !== this.props.data.limits) {
            const timeLimits = this.getTimeLimits();
            if (
                timeLimits.begin !== this.state.searchOptions.beginTime ||
                timeLimits.endTime !== this.state.searchOptions.endTime
            ) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({
                    // eslint-disable-line react/no-did-update-set-state
                    searchOptions: _.extend({}, this.state.searchOptions, {
                        beginTime: timeLimits.begin,
                        endTime: timeLimits.end,
                    }),
                });
            }
        }
    }

    onSelectionChanged(newSelectedReservations) {
        const status = _.uniq(
            _.flatten(
                newSelectedReservations.map((reservation) => {
                    if (reservation.status) {
                        return reservation.status.map((st) => st.status);
                    }
                    return [RC.STATUS.CONFIRMED];
                })
            ).filter((st) => st >= RC.STATUS.CONFIRMED && st < RC.STATUS.TIME)
        );
        Reservation.getValidStatus(status, (result) => {
            this.setState({ validStatus: result.map((st) => st.status) });
        });
    }

    getTimeLimits = () => {
        try {
            return {
                begin: MillenniumDateTime.fromDate(this.props.data.limits.getStartDate()).getMts(),
                end: MillenniumDateTime.fromDate(this.props.data.limits.getEndDate()).getMts(),
            };
        } catch (error) {
            return {
                begin: MillenniumDateTime.fromDate(MillenniumDate.today()).getMts(),
                end: 0,
            };
        }
    };

    onSearchSettingsChange = (settings, cb) => {
        let newSettings = _.extend(
            {},
            _.omit(settings, (value) => value === undefined)
        );
        // Request list should always search for reservations, not cancellations
        newSettings = _.extend(newSettings, {
            reservationVariant: RC.VARIANT.RESERVATION,
            complete: true,
            incomplete: true,
        });

        // Handle no selected status as both requested and rejected
        if (newSettings.reservationStatus && newSettings.reservationStatus.length === 0) {
            newSettings = _.extend(newSettings, {
                reservationStatus: [RC.STATUS.REQUESTED, RC.STATUS.REJECTED],
            });
        } else if (newSettings.reservationStatus) {
            newSettings = _.extend(newSettings, {
                reservationStatus: _.filter(
                    newSettings.reservationStatus,
                    (status) => status === RC.STATUS.REQUESTED || status === RC.STATUS.REJECTED
                ),
            });
        }

        this.setState({ searchOptions: _.extend({}, this.state.searchOptions, newSettings) }, cb);
    };

    limitTo = (categories, allowedCategories) => {
        const result = _.omit(
            categories,
            (value, category) =>
                !_.some(
                    allowedCategories,
                    (allowedCategory) =>
                        String(allowedCategory.id) === category &&
                        _.every(value, (val) => _.contains(allowedCategory.categories, val))
                )
        );
        return result;
    };

    getSettingsComponent = (
        saveDefaultSettings,
        saveSettings,
        getSavedSettings,
        removeSettings
    ) => {
        const opts = this.state.searchOptions;

        return (
            <ReservationListSettings
                prefix={"RQL"}
                isRequestList={true}
                onChange={this.onSearchSettingsChange}
                allReservations={opts.allReservations}
                reservationStatus={opts.reservationStatus}
                includeMembers={opts.includeMembers}
                saveDefaultSettings={saveDefaultSettings}
                saveSettings={saveSettings}
                removeSettings={removeSettings}
                getSavedSettings={getSavedSettings}
                templateKind={opts.templateKind}
                complete={opts.complete}
                incomplete={opts.incomplete}
                permission={opts.permission}
                useTemplateGroup={opts.useTemplateGroup}
                objectCategories={this.limitTo(
                    opts.objectCategories,
                    this.state.availableObjectCategories.concat(
                        this.state.availableObjectCheckboxes
                    )
                )}
                reservationCategories={this.limitTo(
                    opts.reservationCategories,
                    this.state.availableReservationCategories.concat(
                        this.state.availableReservationCheckboxes
                    )
                )}
                availableReservationCategories={this.state.availableReservationCategories}
                availableReservationCheckboxes={this.state.availableReservationCheckboxes}
                availableObjectCategories={this.state.availableObjectCategories}
                availableObjectCheckboxes={this.state.availableObjectCheckboxes}
                placeholderType={opts.placeholderType}
            />
        );
    };

    render() {
        const classes = {
            requestList: true,
        };

        return (
            <ReservationTypeList
                {...this.props}
                allowMultiSelection={true}
                onSearchSettingsChange={this.onSearchSettingsChange}
                onSelectionChanged={this.onSelectionChanged.bind(this)}
                searchOptions={this.state.searchOptions}
                defaultStatus={RC.STATUS.TIME}
                allStatus={this.state.allStatus}
                validStatus={this.state.validStatus}
                columns={getAvailableColumns(this.context)}
                defaultSelectedColumns={[COL_IDS.DATE, COL_IDS.TIME, COL_IDS.TYPES, COL_IDS.STATUS]}
                getSettingsComponent={this.getSettingsComponent}
                getDataMenuItems={this.getReservationMenuItems}
                title={Language.get("nc_request_list_title")}
                classes={classes}
                supportsEmail
                supportsBatchOperations={true}
                addTypeColumns={true}
            />
        );
    }
}

module.exports = RequestList;
