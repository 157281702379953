const React = require("react");
const createReactClass = require("create-react-class");
import { MillenniumTime } from "millennium-time";
const Language = require("../lib/Language");
const _ = require("underscore");
const API = require("../lib/TimeEditAPI");

const TOOLTIP_LEFT_OFFSET = 265;
const TOOLTIP_RIGHT_OFFSET = -10;

const PaddingTooltip = createReactClass({
    displayName: "PaddingTooltip",

    getInitialState() {
        return {
            objects: [],
            rules: [],
        };
    },

    componentDidMount() {
        this._isMounted = true;
        const objects = _.uniq(
            _.flatten([...this.props.padding.paddingItems.map((pI) => pI.objects)])
        );
        const paddingRules = _.uniq(
            _.flatten(
                this.props.padding.paddingItems.map((pI) => pI.items.map((i) => i.paddingRule))
            )
        );
        API.getPaddingRules(paddingRules, (rules) => {
            API.getObjectNames(objects, false, (result) => {
                this.setState({
                    objects: result,
                    rules: rules.filter((rl) => rl !== null && rl !== undefined),
                });
            });
        });
    },

    componentWillUnmount() {
        this._isMounted = false;
    },

    getObjectLabel(objectId) {
        const object = this.state.objects.find((obj) => obj.id === objectId);
        if (!object) {
            return objectId;
        }
        if (!object.fields || !object.fields[0] || !object.fields[0].values) {
            return objectId;
        }
        return object.fields[0].values.join(", ");
    },

    _renderPadding(padding) {
        if (!padding) {
            return null;
        }

        const paddingName = (ruleId) => {
            const rule = this.state.rules.find((rl) => rl.id === ruleId);
            if (rule) {
                if (rule.name) {
                    return rule.name;
                }
            }
            return ruleId;
        };

        const paddingDescription = (ruleId) => {
            const rule = this.state.rules.find((rl) => rl.id === ruleId);
            if (rule) {
                if (rule.description) {
                    return rule.description;
                }
            }
            return "";
        };

        return (
            <>
                <tr key={"padding"}>
                    <td className="fieldLabel">
                        <strong>{Language.get("nc_padding_title")}</strong>
                    </td>
                    <td className="wrapText">
                        {new MillenniumTime(padding.padding).format("HH:mm")}
                    </td>
                </tr>
                {padding.paddingItems.map((item, index) => (
                    <>
                        <tr key={`pd${index}`}>
                            <td className="fieldLabel">
                                <strong>{Language.get("nc_padding_object")}</strong>
                            </td>
                            <td>
                                {item.objects.map((obj) => this.getObjectLabel(obj)).join(", ")}
                            </td>
                        </tr>
                        <tr key={`dividerM${index}`}>
                            <td className="divider" colSpan="2" />
                        </tr>
                        {item.items.map((it, idx) => (
                            <>
                                {idx > 0 ? (
                                    <tr key={`divider${idx}`}>
                                        <td className="divider" colSpan="2" />
                                    </tr>
                                ) : null}
                                <tr key={`rule${idx}`}>
                                    <td className="fieldLabel">
                                        <strong>{Language.get("nc_padding_rule")}</strong>
                                    </td>
                                    <td>{paddingName(it.paddingRule)}</td>
                                </tr>
                                <tr key={`desc${idx}`}>
                                    <td className="fieldLabel">
                                        <strong>{Language.get("nc_padding_description")}</strong>
                                    </td>
                                    <td className="wrapText" style={{ wordBreak: "unset" }}>
                                        {paddingDescription(it.paddingRule)}
                                    </td>
                                </tr>
                                <tr key={`length${idx}`}>
                                    <td className="fieldLabel">
                                        <strong>
                                            {Language.get("cal_res_side_reservation_length")}
                                        </strong>
                                    </td>
                                    <td>{new MillenniumTime(it.padding).format("HH:mm")}</td>
                                </tr>
                            </>
                        ))}
                        <tr>
                            <td>&nbsp;</td>
                        </tr>
                    </>
                ))}
            </>
        );
    },

    render() {
        if (!this.props.padding) {
            return null;
        }

        const text = (
            <span>
                <table>
                    <tbody>{this._renderPadding(this.props.padding)}</tbody>
                </table>
            </span>
        );

        let tooltipStyle = null;
        if (this.props.mousePosition) {
            const leftOffset = this.props.tooltipLeft ? TOOLTIP_LEFT_OFFSET : TOOLTIP_RIGHT_OFFSET;
            const top = this.props.mousePosition.y - this.props.mousePosition.offset.top;
            let left =
                this.props.mousePosition.x - leftOffset - this.props.mousePosition.offset.left;
            if (this.props.tooltipLeft) {
                if (left < 0) {
                    left -= left;
                }
            }
            tooltipStyle = { top, left };
        }
        if (!tooltipStyle) {
            return null;
        }
        return this.props.isVisible ? (
            <div ref="tooltip" className="entryTooltip" style={tooltipStyle}>
                {text}
            </div>
        ) : null;
    },
});

module.exports = PaddingTooltip;
