const PropTypes = require("prop-types");
const React = require("react");
const ReactDOM = require("react-dom");
const Language = require("../lib/Language");
const Log = require("../lib/Log");
const _ = require("underscore");
const TC = require("../lib/TimeConstants");
import { MillenniumTime } from "millennium-time";

const FieldKind = {
    UNKOWN: 0,
    INTEGER: 1,
    TEXT: 2,
    EMAIL: 3,
    URL: 4,
    TELEPHONE: 5,
    COMMENT: 6,
    BOOLEAN: 7,
    SIGNATURE: 8,
    CATEGORY: 9,
    REFERENCE: 10,
    LENGTH: 11,
    TEXT_NO_SEARCH: 12,
    REFERENCE_NO_SEARCH: 13,
};

const ROWS_COMPACT = 2;
const ROWS_LARGE = 5;

class FieldInput extends React.Component {
    static propTypes = {
        value: PropTypes.string.isRequired,
        field: PropTypes.object.isRequired,
        definition: PropTypes.object.isRequired,
        editable: PropTypes.bool,
        onChange: PropTypes.func,
        onDelete: PropTypes.func,
        isCompact: PropTypes.bool,
    };

    static defaultProps = {
        editable: true,
        isCompact: false,
    };

    componentDidMount() {
        if (this._checkbox && this.props.indeterminate) {
            this._checkbox.indeterminate = true;
        }
        if (this.props.isFirstField) {
            const focusField = ReactDOM.findDOMNode(this.refs.textFocusField);
            if (focusField) {
                focusField.selectionStart = focusField.selectionEnd = focusField.value.length;
            }
        }
    }

    hasValue = () => this.props.field.values && this.props.field.values.length > 0;

    onHoursChange = (event) => {
        this.onLengthChange(event, true);
    };

    onMinutesChange = (event) => {
        this.onLengthChange(event, false);
    };

    onLengthChange = (event, isHours) => {
        if (this.props.editable === false || !this.props.onChange) {
            return;
        }
        let changedValue = parseInt(event.target.value, 10);
        if (isNaN(changedValue)) {
            changedValue = 0;
        }
        let newValue;
        if (isHours) {
            newValue =
                changedValue * TC.SECONDS_PER_HOUR + (this.props.value % TC.SECONDS_PER_HOUR);
        } else {
            newValue =
                changedValue * TC.SECONDS_PER_MINUTE +
                Math.floor(this.props.value / TC.SECONDS_PER_HOUR) * TC.SECONDS_PER_HOUR;
        }

        this.changeIfValid(String(newValue), event);
    };

    onChange = (event) => {
        if (this.props.editable === false || !this.props.onChange) {
            return;
        }

        let newValue = event.target.value;
        if (this.props.definition.kind === FieldKind.BOOLEAN) {
            newValue = event.target.checked ? "1" : "0";
        }

        this.changeIfValid(newValue, event);
    };

    changeIfValid = (newValue, event) => {
        const validation = this._validateInput(newValue);
        if (validation === true) {
            this.props.onChange(newValue, event);
            return;
        }

        Log.info(validation.message);

        if (this._validateInput(newValue, { mandatory: false, range: false }) === true) {
            this.props.onChange(newValue, event);
            return;
        }
    };

    /**
     * Validate field value. The second parameter is used for limiting which rules the value should be validated against.
     * This is useful to allow invalid states that may lead to a valid state (e.g. if the allowed range is 2-10, the
     * value 1 is invalid, but it should not be prevented since the user may be trying to input the value 10).
     * @param  {string} value Input value
     * @param  {object} rules Object where the rule names are keys and its value a boolean for whether or not the rule should be used for validation
     * @return {boolean|Error} Returns true if the value passed validation, otherwise an error is returned containing a message describing the reason.
     */
    _validateInput = (value, rules = {}) => {
        if (!value && !this.props.definition.mandatory) {
            return true;
        }
        if (rules.mandatory !== false && !value && this.props.definition.mandatory === true) {
            return new Error(Language.get("err_obj_field_mandatory", this.props.definition.name));
        }

        if (
            rules.length !== false &&
            this.props.definition.length &&
            value.length > this.props.definition.length
        ) {
            return new Error(Language.get("nc_field_error_too_long", this.props.definition.length));
        }

        if (this.props.definition.kind === FieldKind.INTEGER) {
            if (rules.integer !== false && value !== "" && !_.isInteger(value)) {
                return new Error(
                    Language.get("nc_validation_error_not_an_integer", this.props.definition.name)
                );
            }

            const num = parseInt(value, 10);
            if (
                rules.range !== false &&
                (num < this.props.definition.min || num > this.props.definition.max)
            ) {
                return new Error(
                    Language.get(
                        "err_obj_field_max_min",
                        this.props.definition.name,
                        `${this.props.definition.min || 0} - ${this.props.definition.max}`
                    )
                );
            }
        }

        if (rules.filter !== false && this.props.definition.filter) {
            const regExp = new RegExp(`^${this.props.definition.filter}+$`);
            if (!regExp.test(value)) {
                return new Error(
                    Language.get(
                        "err_obj_field_filter",
                        this.props.definition.name,
                        this.props.definition.filter
                    )
                );
            }
        }

        return true;
    };

    render() {
        if (!this.props.definition.multiple || !this.props.editable || !this.props.onDelete) {
            return this._renderInput({ field: true });
        }

        return (
            <div className="">
                <div className="removeButton leftFloating" onClick={this.props.onDelete}>
                    &#xf068;
                </div>
                {this._renderInput({ field: true })}
            </div>
        );
    }

    _renderInput = (classMap = {}) => {
        const isValid = this._validateInput(this.props.value) === true;
        // eslint-disable-next-line no-param-reassign
        classMap = _.extend(
            {
                validField: isValid,
                invalidField: !isValid,
            },
            classMap
        );

        if (this.props.definition.kind === FieldKind.BOOLEAN) {
            return (
                <input
                    type="checkbox"
                    value={this.props.value}
                    checked={this.hasValue() && this.props.field.values[0] === "1"}
                    disabled={!this.props.editable}
                    className={_.classSet(classMap)}
                    onChange={this.onChange}
                    ref={(node) => (this._checkbox = node)}
                />
            );
        }

        if (!this.props.editable && this.props.definition.kind !== FieldKind.CATEGORY) {
            if (this.props.definition.kind === FieldKind.URL) {
                const url = this.props.value;
                const href = url.indexOf("://") > -1 ? url : `http://${url}`;
                return (
                    <div className="fieldLine">
                        <a href={href} target="_blank">
                            {url}
                        </a>
                    </div>
                );
            }
            if (this.props.definition.kind === FieldKind.LENGTH) {
                const val = parseInt(this.props.value, 10);
                let formattedValue = "";
                if (!isNaN(val)) {
                    formattedValue = new MillenniumTime(val).format("HH:mm");
                }
                return <div className="fieldLine">{formattedValue}</div>;
            }
            if (this.props.definition.kind === FieldKind.COMMENT) {
                return (
                    <div className="fieldLine">
                        <textarea
                            rows={this.props.isCompact ? ROWS_COMPACT : ROWS_LARGE}
                            disabled
                            className={_.classSet(classMap)}
                            value={this.props.value}
                        />
                    </div>
                );
            }
            return <div className="fieldLine">{this.props.value}</div>;
        }

        if (this.props.definition.kind === FieldKind.CATEGORY) {
            const categories = (this.props.definition.categories || []).filter(
                (category) =>
                    !this.hasValue() ||
                    category === this.props.value ||
                    this.props.field.values.indexOf(category) === -1
            );
            const inputElement = (
                <select
                    className={_.classSet(classMap)}
                    value={this.props.value}
                    disabled={!this.props.editable}
                    onChange={this.onChange}
                >
                    <option />
                    {categories.map((category, idx) => (
                        <option key={idx} value={category}>
                            {category}
                        </option>
                    ))}
                </select>
            );

            if (this.props.definition.editable && this.props.editable) {
                return (
                    <div className="inputWrapper">
                        {inputElement}
                        <input
                            type="text"
                            className="categoryInput"
                            value={this.props.value}
                            onChange={this.onChange}
                        />
                    </div>
                );
            }
            if (this.props.editable) {
                return <div className="fieldLine">{inputElement}</div>;
            }
            return <div className="fieldLine">{this.props.value}</div>;
        }

        const placeholderText = this.props.indeterminate
            ? Language.get("nc_field_multiple_values")
            : "";
        if (this.props.definition.kind === FieldKind.COMMENT) {
            return (
                <textarea
                    rows={this.props.isCompact ? ROWS_COMPACT : ROWS_LARGE}
                    className={_.classSet(classMap)}
                    placeholder={placeholderText}
                    value={this.props.value}
                    onChange={this.onChange}
                />
            );
        }

        // eslint-disable-next-line no-param-reassign
        classMap.nonCategoryInput = true;

        let linkOpener = null;
        if (this.props.definition.kind === FieldKind.URL) {
            linkOpener = <a className="linkOpener" href={this.props.value} target="_blank" />;
        }

        if (this.props.definition.kind === FieldKind.LENGTH) {
            // eslint-disable-next-line no-param-reassign
            classMap.lengthInput = true;
            const time = new MillenniumTime(this.props.value);
            return (
                <div className="inputWrapper">
                    <input
                        type="text"
                        ref="textFocusField"
                        autoFocus={this.props.isFirstField}
                        placeholder={placeholderText}
                        value={time.format("H")}
                        className={_.classSet(classMap)}
                        onChange={this.onHoursChange}
                    />
                    :
                    <input
                        type="text"
                        placeholder={placeholderText}
                        value={time.format("mm")}
                        className={_.classSet(classMap)}
                        onChange={this.onMinutesChange}
                    />
                </div>
            );
        }

        return (
            <div className="inputWrapper">
                <input
                    type="text"
                    ref="textFocusField"
                    autoFocus={this.props.isFirstField}
                    placeholder={placeholderText}
                    value={this.props.value}
                    className={_.classSet(classMap)}
                    onChange={this.onChange}
                />
                {linkOpener}
            </div>
        );
    };
}

FieldInput.fieldKind = FieldKind;

module.exports = FieldInput;
