import {
    MillenniumDate,
    MillenniumWeek,
    MillenniumWeekday,
    SimpleDateFormat,
} from "millennium-time";
const Lang = require("../lib/Language");
const Header = require("./Header");
const Limits = require("./Limits");
const _ = require("underscore");

const DAYS_IN_FULL_WEEK = 7;
const DAYS_IN_SIX_DAY_WEEK = 6;
const DAYS_IN_WORK_WEEK = 5;
const SATURDAY = 6;

const DateHeader = function (visibleValues, firstVisibleValue, subheader) {
    Header.call(
        this,
        visibleValues || DAYS_IN_FULL_WEEK,
        firstVisibleValue,
        subheader,
        "DateHeader"
    );
    this.limits = Limits.getDefault();
    this.firstVisibleValue = 0;
    this.majorStepLimit = DAYS_IN_FULL_WEEK;
    this.daysPerWeek = DAYS_IN_FULL_WEEK;
    this.size = 18;
    this.opensOnToday = true;
};

DateHeader.parse = function (data, limits) {
    const daysPerWeek = data.daysPerWeek || DAYS_IN_FULL_WEEK;
    const header = new DateHeader(daysPerWeek);
    header.daysPerWeek = daysPerWeek;
    header.majorStepLimit = daysPerWeek;
    header.limits = limits;
    header.size = data.size;
    if (data.opensOnToday !== undefined && limits.isStartDayAbsolute) {
        header.opensOnToday = data.opensOnToday;
    } else {
        header.opensOnToday = true;
    }
    let today = MillenniumDate.today();
    if (!limits.isStartDayAbsolute) {
        if (data.visibleValues < data.daysPerWeek) {
            if (header.opensOnToday) {
                header._firstVisibleValue = header.getIndexOfDate(today);
            } else {
                header._firstVisibleValue = data.firstValue;
            }
        } else {
            const week = new MillenniumWeek(
                header.getStartDate().addDays(header.getConvertedIndex(data.firstValue)),
                Lang.firstDayOfWeek,
                Lang.daysInFirstWeek
            );
            const firstDateInWeek = week.getStartOfWeek().getMillenniumDate();
            header._firstVisibleValue = header.getIndexOfDate(firstDateInWeek);
        }
    } else {
        if (
            header.opensOnToday &&
            today.getDayNumber() >= limits.startDay &&
            today.getDayNumber() <= limits.startDay + limits.dayCount
        ) {
            while (!header.isWeekdayVisible(today)) {
                today = today.addDays(1);
            }
            // If today plus visible days streches beyond the calendar limit
            // find the appropriate first visible day taking skipped days into account
            const allVisible = header.getValues();
            const lastVisibleDay = new MillenniumDate(limits.startDay).addDays(limits.dayCount);
            const todayIndex = allVisible.findIndex(
                (date) => date.getDayNumber() === today.getDayNumber()
            );
            const lastDayIndex = allVisible.findIndex(
                (date) => date.getDayNumber() === lastVisibleDay.getDayNumber()
            );
            if (!todayIndex) {
                header._firstVisibleValue = data.firstValue;
            } else if (lastDayIndex === -1) {
                header._firstVisibleValue = header.getIndexOfDate(today);
            } else {
                if (lastDayIndex - todayIndex < data.visibleValues) {
                    const stepsBack = data.visibleValues - (lastDayIndex - todayIndex);
                    today = allVisible[Math.max(0, todayIndex - stepsBack)];
                }
                header._firstVisibleValue = header.getIndexOfDate(today);
            }
        } else {
            header._firstVisibleValue = data.firstValue;
        }
    }
    header.visibleValues = data.visibleValues;
    return header;
};

DateHeader.prototype = Object.create(Header.prototype);

Object.defineProperty(DateHeader.prototype, "firstVisibleValue", {
    get() {
        return this._firstVisibleValue;
    },
    set(value) {
        let val = value;
        if (!_.isNumber(val) || val % 1 !== 0) {
            throw new Error(Lang.get("nc_validation_error_not_an_integer", val));
        }

        if (val > this.length() - this.visibleValues) {
            val = this.length() - this.visibleValues;
        }
        if (val < 0) {
            val = 0;
        }

        this._firstVisibleValue = val;
    },
    enumerable: true,
});

DateHeader.prototype.getStartDate = function () {
    const start = this.limits.getStartDate();
    if (this.isWeekdayVisible(start)) {
        return start;
    }
    const TWO_DAYS = 2;
    return start.addDays(start.getDay() === SATURDAY ? TWO_DAYS : 1);
};

DateHeader.prototype.getEndDate = function () {
    return this.limits.getEndDate();
};

DateHeader.prototype.containsDate = function (date) {
    const firstDate = this.getStartDate().getDayNumber();
    const endDate = this.getEndDate().getDayNumber();

    if (date.getDayNumber() > endDate || date.getDayNumber() < firstDate) {
        return false;
    }
    return this.isWeekdayVisible(date);
};

DateHeader.prototype.getIndexOfDate = function (date, onlyVisible) {
    if (!this.isWeekdayVisible(date)) {
        throw new Error(
            `${date.toString()} is a weekday (${MillenniumWeekday.WEEKDAYS[
                date.getDay()
            ].getRepresentation()}) not visible in this header.`
        );
    }

    if (this.daysPerWeek === DAYS_IN_FULL_WEEK) {
        const start = onlyVisible ? this.valueAt(0) : this.getStartDate();
        return date.getDayNumber() - start.getDayNumber();
    }
    // When taking hidden weekends into account, we are only interested in
    // the change of work weeks, no matter the localized week format.
    const ISO_8601_FIRST_DAY_IN_WEEK = MillenniumWeekday.MONDAY;
    const ISO_8601_DAYS_IN_FIRST_WEEK = 4;

    let startDate = this.getStartDate();
    if (onlyVisible) {
        startDate = startDate.addWeeks(Math.floor(this.firstVisibleValue / this.daysPerWeek));
        const weekBeforeRemainder = startDate.getWeek(
            true,
            ISO_8601_FIRST_DAY_IN_WEEK,
            ISO_8601_DAYS_IN_FIRST_WEEK
        );
        startDate = startDate.addDays(this.firstVisibleValue % this.daysPerWeek);
        const newStartWeek = startDate.getWeek(
            true,
            ISO_8601_FIRST_DAY_IN_WEEK,
            ISO_8601_DAYS_IN_FIRST_WEEK
        );
        if (!this.isWeekdayVisible(startDate) || newStartWeek !== weekBeforeRemainder) {
            startDate = startDate.addDays(DAYS_IN_FULL_WEEK - this.daysPerWeek);
        }
    }
    const week = startDate.getMillenniumWeek(Lang.firstDayOfWeek, Lang.daysInFirstWeek);
    const weekStartOffset = week.dayOfWeek2WeekdayNumber(startDate.getDay());
    startDate = startDate.addDays(-weekStartOffset); // First day of week

    const diffDays = date.getDayNumber() - startDate.getDayNumber();
    return (
        Math.floor(diffDays / DAYS_IN_FULL_WEEK) * this.daysPerWeek +
        (diffDays % DAYS_IN_FULL_WEEK) -
        weekStartOffset
    );
};

DateHeader.prototype.getConvertedIndex = function (index, visibleDays) {
    const start = this.getStartDate();
    const end = this.getEndDate();
    const firstVisibleDate = this.valueAt(index, false);
    const allValues = _.range(start.getDayNumber(), end.getDayNumber() + 1).map(
        (num) => new MillenniumDate(num)
    );
    const weekdayValues = allValues.filter((date) => this.isWeekdayVisible(date, visibleDays));

    if (visibleDays < DAYS_IN_FULL_WEEK) {
        return weekdayValues.findIndex(
            (date) => date.getDayNumber() === firstVisibleDate.getDayNumber()
        );
    }

    return allValues.findIndex((date) => date.getDayNumber() === firstVisibleDate.getDayNumber());
};

DateHeader.prototype.indexOf = function (entry, onlyVisible) {
    return this.getIndexOfDate(entry.startTimes[0].getMillenniumDate(), onlyVisible);
};

DateHeader.prototype.lastIndexOf = function (entry, onlyVisible) {
    return this.getIndexOfDate(entry.endTimes[0].getMillenniumDate(), onlyVisible) + 1;
};

DateHeader.prototype.valueAt = function (index, onlyVisibleBool) {
    const onlyVisible = onlyVisibleBool !== undefined ? onlyVisibleBool : true;
    if (index < 0 || (onlyVisible && index >= this.visibleValues)) {
        throw new Error(`Index out of bounds in DateHeader.valueAt(${index})`);
    }

    let position = index;
    if (onlyVisible) {
        position = index + this.firstVisibleValue;
    }

    if (this.daysPerWeek === DAYS_IN_FULL_WEEK) {
        return this.getStartDate().addDays(position);
    }

    const startDate = this.getStartDate();
    const week = startDate.getMillenniumWeek(Lang.firstDayOfWeek, Lang.daysInFirstWeek);
    const weekStartOffset = week.dayOfWeek2WeekdayNumber(startDate.getDay());
    position = position + weekStartOffset;

    return startDate
        .addWeeks(Math.floor(position / this.daysPerWeek))
        .addDays(position % this.daysPerWeek)
        .addDays(-weekStartOffset);
};

DateHeader.prototype.increaseMajorStep = function () {
    const week = new MillenniumWeek(
        this.valueAt(0).addDays(DAYS_IN_FULL_WEEK),
        Lang.firstDayOfWeek,
        Lang.daysInFirstWeek
    );
    let firstDateInWeek = week.getStartOfWeek().getMillenniumDate();
    if (firstDateInWeek.getDayNumber() > this.getEndDate().getDayNumber()) {
        firstDateInWeek = new MillenniumDate(
            this.getEndDate().getDayNumber() - (this.visibleValues - 1)
        );
    }

    // If the date lands on a weekday not visible, see if stepping forward a day or two helps (i.e. so that we get to Monday if we land on a hidden weekend)
    if (this.daysPerWeek !== DAYS_IN_FULL_WEEK && !this.isWeekdayVisible(firstDateInWeek)) {
        // eslint-disable-next-line no-console
        console.log(firstDateInWeek.toString());
        firstDateInWeek = firstDateInWeek.addDays(1);
        if (!this.isWeekdayVisible(firstDateInWeek)) {
            firstDateInWeek = firstDateInWeek.addDays(1);
            if (!this.isWeekdayVisible(firstDateInWeek)) {
                return this;
            }
        }
    }

    try {
        const newFirst = this.getIndexOfDate(firstDateInWeek);
        return this.immutableSet({
            firstVisibleValue: newFirst,
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return this;
    }
};

DateHeader.prototype.decreaseMajorStep = function () {
    const firstDate = this.valueAt(0);
    let week; // If the first visible day is the first day of the week, step back a week. Otherwise, step back to the first day of that week.
    if (this.isFirstDayOfWeek(firstDate)) {
        week = new MillenniumWeek(
            firstDate.addDays(-this.daysPerWeek),
            Lang.firstDayOfWeek,
            Lang.daysInFirstWeek
        );
    } else {
        week = new MillenniumWeek(firstDate, Lang.firstDayOfWeek, Lang.daysInFirstWeek);
    }
    let firstDateInWeek = week.getStartOfWeek().getMillenniumDate();

    if (firstDateInWeek.getDayNumber() < this.getStartDate().getDayNumber()) {
        firstDateInWeek = new MillenniumDate(this.getStartDate().getDayNumber());
    }

    // If the date lands on a weekday not visible, see if stepping forward a day or two helps (i.e. so that we get to Monday if we land on a hidden weekend)
    if (this.daysPerWeek !== DAYS_IN_FULL_WEEK && !this.isWeekdayVisible(firstDateInWeek)) {
        // eslint-disable-next-line no-console
        console.log(firstDateInWeek.toString());
        firstDateInWeek = firstDateInWeek.addDays(1);
        if (!this.isWeekdayVisible(firstDateInWeek)) {
            firstDateInWeek = firstDateInWeek.addDays(1);
            if (!this.isWeekdayVisible(firstDateInWeek)) {
                return this;
            }
        }
    }

    try {
        const newFirst = this.getIndexOfDate(firstDateInWeek);
        return this.immutableSet({
            firstVisibleValue: newFirst,
        });
    } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
        return this;
    }
};

DateHeader.prototype.getLabel = function (date, size) {
    switch (size) {
        case Header.Label.XS:
            return SimpleDateFormat.format(date, Lang.getDateFormat("date_f_e"));
        case Header.Label.S:
            return SimpleDateFormat.format(date, Lang.getDateFormat("date_f_e_d"));
        case Header.Label.M:
            return SimpleDateFormat.format(date, Lang.getDateFormat("date_f_ee_d"));
        case Header.Label.L:
            return SimpleDateFormat.format(date, Lang.getDateFormat("date_f_eee_m_d"));
        default:
            return SimpleDateFormat.format(date, Lang.getDateFormat("date_f_eee_yyyy_mmm_d"));
    }
};

DateHeader.prototype.isCurrent = function (date, currentDateTime) {
    if (!currentDateTime) {
        return false;
    }
    return currentDateTime.getMillenniumDate().equals(date);
};

const addWeekendsToVisibleValues = function (visibleValues) {
    if (visibleValues === DAYS_IN_FULL_WEEK) {
        return (
            Math.floor(visibleValues / DAYS_IN_WORK_WEEK) * DAYS_IN_FULL_WEEK +
            (visibleValues % DAYS_IN_WORK_WEEK)
        );
    }
    if (visibleValues === DAYS_IN_SIX_DAY_WEEK) {
        return (
            Math.floor(visibleValues / DAYS_IN_WORK_WEEK) * DAYS_IN_SIX_DAY_WEEK +
            (visibleValues % DAYS_IN_WORK_WEEK)
        );
    }
    return visibleValues;
};

const removeWeekendsFromVisibleValues = function (visibleValues) {
    if (visibleValues === DAYS_IN_WORK_WEEK) {
        return (
            Math.floor(visibleValues / DAYS_IN_FULL_WEEK) * DAYS_IN_WORK_WEEK +
            (visibleValues % DAYS_IN_FULL_WEEK)
        );
    }
    if (visibleValues === DAYS_IN_SIX_DAY_WEEK) {
        return (
            Math.floor(visibleValues / DAYS_IN_FULL_WEEK) * DAYS_IN_SIX_DAY_WEEK +
            (visibleValues % DAYS_IN_FULL_WEEK)
        );
    }
    return visibleValues;
};

DateHeader.prototype.setWeekendVisible = function (visibleDays) {
    if (this.daysPerWeek === visibleDays) {
        return this;
    }

    const updatedHeader = this.immutableSet({
        daysPerWeek: visibleDays,
    });
    if (visibleDays === DAYS_IN_WORK_WEEK) {
        // Remove weekends
        return updatedHeader.immutableSet({
            firstVisibleValue: this.getConvertedIndex(this.firstVisibleValue, visibleDays),
            visibleValues: removeWeekendsFromVisibleValues(this.visibleValues),
            majorStepLimit: DAYS_IN_WORK_WEEK,
        });
    }
    if (visibleDays === DAYS_IN_SIX_DAY_WEEK) {
        return updatedHeader.immutableSet({
            firstVisibleValue: this.getConvertedIndex(this.firstVisibleValue, visibleDays),
            visibleValues: removeWeekendsFromVisibleValues(this.visibleValues),
            majorStepLimit: DAYS_IN_SIX_DAY_WEEK,
        });
    }
    return updatedHeader.immutableSet({
        firstVisibleValue: this.getConvertedIndex(this.firstVisibleValue, visibleDays),
        visibleValues: addWeekendsToVisibleValues(this.visibleValues),
        majorStepLimit: DAYS_IN_FULL_WEEK,
    });
};

DateHeader.prototype.isFirstDayOfWeek = function (date) {
    return date.getDay() === Lang.firstDayOfWeek.getAbsoluteWeekdayNumber();
};

DateHeader.prototype.isWeekdayVisible = function (date, visibleDays = this.daysPerWeek) {
    if (visibleDays === DAYS_IN_WORK_WEEK) {
        return !date.isWeekend(Lang.firstDayOfWeek);
    }
    if (visibleDays === DAYS_IN_SIX_DAY_WEEK) {
        const weekday = MillenniumWeekday.WEEKDAYS[date.getDay()];
        return weekday !== MillenniumWeekday.getLocalizedWeekdayList(Lang.firstDayOfWeek)[6];
    }
    return true; // We're viewing all week days
};

DateHeader.prototype.isWeekday = function (date) {
    return !date.isWeekend(Lang.firstDayOfWeek);
};

DateHeader.prototype.getValues = function () {
    const dates = [];
    let i = this.getStartDate().getDayNumber();

    while (i <= this.getEndDate().getDayNumber()) {
        const date = new MillenniumDate(i);
        if (this.isWeekdayVisible(date)) {
            dates.push(date);
        }
        i++;
    }

    return dates;
};

DateHeader.prototype.getVisibleValues = function () {
    const dates = [];
    let i = this.valueAt(0).getDayNumber();

    while (dates.length < this.visibleValues) {
        const date = new MillenniumDate(i);
        if (this.isWeekdayVisible(date)) {
            dates.push(date);
        }
        i++;
    }

    return dates;
};

const getCustomWeek = (week, customWeekNames) => {
    const name = _.find(
        customWeekNames,
        (customWeek) => customWeek.dayNumber === week.date.dayNumber
    );
    if (name) {
        return name;
    }
    return null;
};

DateHeader.prototype.getInfo = function (value, size, customWeekNames = []) {
    if (value.getDay() !== Lang.firstDayOfWeek.getAbsoluteWeekdayNumber()) {
        return null;
    }

    if (customWeekNames.length > 0) {
        const week = value.getMillenniumWeek(Lang.firstDayOfWeek, Lang.daysInFirstWeek);
        const customWeek = getCustomWeek(week, customWeekNames);
        if (customWeek) {
            if (size > Header.Label.L) {
                return customWeek.getLongestName();
            }
            return customWeek.getShortestName();
        }
    }

    const weekFormat = Lang.getDateFormat("date_f_v_yyyy_ww");
    if (size > Header.Label.L && weekFormat.indexOf("d") === -1) {
        return SimpleDateFormat.format(
            value,
            `${weekFormat} (${Lang.getDateFormat("date_f_m_d")})`
        );
    }
    return SimpleDateFormat.format(value, weekFormat);
};

DateHeader.prototype.getCellsPerInfoCell = function () {
    return this.daysPerWeek;
};

DateHeader.prototype.isNewSection = function (index) {
    return this.valueAt(index).getDay() === Lang.firstDayOfWeek.getAbsoluteWeekdayNumber();
};

DateHeader.prototype.getSections = function () {
    const result = [];
    this.getVisibleValues().forEach((value, index) => {
        if (this.isNewSection(index)) {
            result.push(index);
        }
    });
    return result;
};

DateHeader.prototype.setLimits = function (limits) {
    const header = Header.prototype.setLimits.call(this, limits);
    let firstVisibleValue =
        this.firstVisibleValue +
        (this.limits.getStartDate().getDayNumber() - limits.getStartDate().getDayNumber());
    if (
        limits.getStartDate().getDayNumber() + firstVisibleValue >
        limits.getEndDate().getDayNumber()
    ) {
        firstVisibleValue =
            limits.getEndDate().getDayNumber() -
            limits.getStartDate().getDayNumber() -
            this.visibleValues;
    }
    if (firstVisibleValue < 0) {
        firstVisibleValue = 0;
    }
    return header.immutableSet({ firstVisibleValue });
};

DateHeader.prototype.getSettings = function () {
    const settings = Header.prototype.getSettings.call(this);
    const self = this;

    if (this.limits.isStartDayAbsolute) {
        settings.items.push({
            id: "opensOnToday",
            label: Lang.get("nc_date_header_opens_on_today"),
            type: "boolean",
            get: () => self.opensOnToday,
            set: (opensOnToday) => self.immutableSet({ opensOnToday }),
        });
    }

    const firstVisibleValue = settings.find("firstVisibleValue");
    firstVisibleValue.isDisabled = () => self.opensOnToday;
    firstVisibleValue.label = Lang.get("cal_reservation_list_column_start_date");
    firstVisibleValue.type = "date";
    firstVisibleValue.get = function () {
        return self.valueAt(0);
    };
    firstVisibleValue.set = function (date) {
        return self.immutableSet({ firstVisibleValue: self.getIndexOfDate(date) });
    };

    const visibleValues = settings.find("visibleValues");
    visibleValues.label = Lang.get("cal_res_side_view_visible_days");

    const daysPerWeek = {};
    daysPerWeek.id = "function";
    daysPerWeek.label = Lang.get("cal_header_weekdays");
    daysPerWeek.type = "array";
    daysPerWeek.limit = 1;
    const weekdays = MillenniumWeekday.getLocalizedWeekdayList(Lang.firstDayOfWeek);
    const labels = Lang.getShortWeekdayLabels();
    daysPerWeek.get = function () {
        return [
            {
                label: Lang.get(
                    "nc_date_header_weekdays_x_to_y",
                    labels[weekdays[0].getDay()],
                    labels[weekdays[4].getDay()]
                ),
                value: DAYS_IN_WORK_WEEK,
                selected: self.daysPerWeek === DAYS_IN_WORK_WEEK,
            },
            {
                label: Lang.get(
                    "nc_date_header_weekdays_x_to_y",
                    labels[weekdays[0].getDay()],
                    labels[weekdays[5].getDay()]
                ),
                value: DAYS_IN_SIX_DAY_WEEK,
                selected: self.daysPerWeek === DAYS_IN_SIX_DAY_WEEK,
            },
            {
                label: Lang.get(
                    "nc_date_header_weekdays_x_to_y",
                    labels[weekdays[0].getDay()],
                    labels[weekdays[6].getDay()]
                ),
                value: DAYS_IN_FULL_WEEK,
                selected: self.daysPerWeek === DAYS_IN_FULL_WEEK,
            },
        ];
    };
    daysPerWeek.set = function (value) {
        return self.setWeekendVisible(value);
    };
    settings.items.push(daysPerWeek);

    return settings;
};

DateHeader.prototype.toJSON = function () {
    const json = Header.prototype.toJSON.call(this);
    let firstVisibleValue = this.firstVisibleValue;
    if (this.daysPerWeek < DAYS_IN_FULL_WEEK) {
        firstVisibleValue = this.getConvertedIndex(firstVisibleValue, this.daysPerWeek);
    }
    return _.extend(json, {
        dayProvider: true,
        firstValue: firstVisibleValue,
        daysPerWeek: this.daysPerWeek,
        kind: "date",
        size: this.size,
        opensOnToday: this.opensOnToday,
    });
};

module.exports = DateHeader;
