const Model = require("./Model");
const TemplateKind = require("./TemplateKind");
const Selection = require("./Selection");
import { MillenniumDateTime } from "millennium-time";
const Limits = require("./Limits");
const Settings = require("./Settings");

const DEFAULT_LIST_ID = 6;

const StaticReservations = function (
    limits = Limits.getDefaultList(),
    selection = null,
    reservationIds = [],
    name = null
) {
    Model.call(this, "StaticReservations");
    this.templateKind = TemplateKind.RESERVATION;
    this.privateSelected = true;
    this.selection = selection || new Selection();
    this.limits = limits;
    this.typeFilter = [];
    this.isTimePeriod = false;
    this.listId = DEFAULT_LIST_ID;
    this.reservationIds = reservationIds;
    this.name = name;
};

StaticReservations.prototype = Object.create(Model.prototype);

StaticReservations.prototype.getSettings = function () {
    const settings = [];
    return new Settings(settings);
};

StaticReservations.prototype.getMacroFunctions = function () {
    const macros = [];
    return macros;
};

StaticReservations.prototype.setLimits = function (limits) {
    if (!(limits instanceof Limits)) {
        // eslint-disable-next-line no-param-reassign
        limits = new Limits(limits);
    }

    return this.immutableSet({
        limits,
    });
};

StaticReservations.prototype.setIsTimePeriod = function (isTimePeriod) {
    return this.immutableSet({ isTimePeriod });
};

StaticReservations.prototype.updateTimeRange = function (newBeginTime, newEndTime) {
    let limits = new Limits(this.limits);
    limits = limits.setStartDayFromDate(new MillenniumDateTime(newBeginTime).getMillenniumDate());
    limits = limits.setDayCountFromDate(new MillenniumDateTime(newEndTime).getMillenniumDate());
    return this.immutableSet({
        limits,
    });
};

// What about the search settings object and such? How to even get hold of it from here?
StaticReservations.prototype.toJSON = function () {
    return {
        limits: this.limits,
        privateSelectedList: this.privateSelected,
        templateKind: this.templateKind.mode,
        typeFilter: this.typeFilter,
        reservationIds: this.reservationIds,
        function: this.getMacroFunctions(),
        isList: true,
        listId: this.listId,
    };
};

module.exports = StaticReservations;
