const React = require("react");
const createReactClass = require("create-react-class");
const PropTypes = require("prop-types");
const MassMoveReservationsDialog = require("./MassMoveReservationsDialog");
const MassChangeObjectsDialog = require("./MassChangeObjectsDialog");
const MassAssignObjectsDialog = require("./MassAssignObjectsDialog");
const OBMC = require("../lib/ReservationConstants").OBJECT_MASS_CHANGE;
const TemplateKind = require("../models/TemplateKind");
const TC = require("../lib/TimeConstants");
const API = require("../lib/TimeEditAPI");
const Language = require("../lib/Language");
const MassChangeResultList = require("./MassChangeResultList");
const AutoScheduler = require("../lib/AutoScheduler");
import Selection from "../models/Selection";
import { MillenniumDateTime } from "millennium-time";
const _ = require("underscore");

const STATE = {
    DEFAULT: 0,
    RESULT_FAILURES: 1,
    RESULT_SUCCESSES: 2,
    MOVE_WITH_GROUPS: 3,
};

const HUNDRED_PERCENT = 100;
const NINETYNINE_PERCENT = 99;

const isResultState = (state) =>
    _.some([STATE.RESULT_FAILURES, STATE.RESULT_SUCCESSES], (st) => st === state);

const filterToObject = (
    reservationIds,
    removeObjectId,
    addObjectId,
    addTypeId,
    addIfMissingTypeOnly,
    callback
) => {
    if (!addObjectId && !removeObjectId) {
        callback(reservationIds);
        return;
    }
    // removeObjectIds, addObjectIds, addIfMissingTypeOnly
    API.getReservationsWithObjects(
        reservationIds,
        removeObjectId ? removeObjectId : [],
        addObjectId ? [addObjectId] : [],
        addTypeId,
        addIfMissingTypeOnly,
        (result) => {
            callback(result.map((res) => res.id));
        }
    );
};

const assignToAllReservations = (
    reservationId,
    rest,
    baseFluffy,
    searcher,
    allObjectIds,
    useRelatedObjects,
    reservationLayer,
    skipLastReserved,
    callback,
    successes = [],
    failures = [],
    messages = []
) => {
    AutoScheduler.assignObjectToReservation(
        reservationId,
        allObjectIds,
        baseFluffy,
        searcher,
        useRelatedObjects,
        reservationLayer,
        skipLastReserved,
        (result) => {
            if (rest.length > 0) {
                const next = rest.shift();
                assignToAllReservations(
                    next,
                    rest,
                    baseFluffy,
                    searcher,
                    allObjectIds,
                    useRelatedObjects,
                    reservationLayer,
                    skipLastReserved,
                    callback,
                    successes.concat(result[0]),
                    failures.concat(result[1]),
                    messages.concat(result[2])
                );
            } else {
                callback([
                    successes.concat(result[0]),
                    failures.concat(result[1]),
                    messages.concat(result[2]),
                ]);
            }
        }
    );
};

const MassChangeController = createReactClass({
    contextTypes: {
        user: PropTypes.object,
        fireEvent: PropTypes.func,
        update: PropTypes.func,
        presentModal: PropTypes.func,
    },

    propTypes: {
        changeType: PropTypes.oneOf(_.values(OBMC)),
        reservations: PropTypes.array, // Reservations are expected to include begin and end times, a reservation ID isn't enough
        finishedCallback: PropTypes.func,
        setActiveLayer: PropTypes.func,
    },

    getInitialState() {
        return {
            changesMade: false,
            reservations: this.props.reservations,
            currentState: STATE.DEFAULT,
            changeData: null,
            resultReservations: [],
            successes: [],
            unchanged: [],
            messages: [],
            progress: 0,
            isWorking: false,
            expandedLists: [],
            firstBegin: _.min(
                this.props.reservations.map((info) => {
                    if (info.begin) {
                        return new MillenniumDateTime(info.begin).getMillenniumDate();
                    }
                    return null;
                })
            ),
            lastEnd: _.max(
                this.props.reservations.map((info) => {
                    if (info.end) {
                        return new MillenniumDateTime(info.end).getMillenniumDate();
                    }
                    return null;
                })
            ),
        };
    },

    componentDidMount() {
        this.gotoStart(this.props.reservations, false);
    },

    gotoState(nextState) {
        this.setState({ currentState: nextState });
    },

    toggleList(list) {
        let expandedLists = [].concat(this.state.expandedLists);
        if (expandedLists.indexOf(list) === -1) {
            expandedLists.push(list);
        } else {
            expandedLists = _.filter(expandedLists, (ls) => ls !== list);
        }
        this.setState({ expandedLists });
    },

    // On first run, do getReservationsWithTime with the group flag to false, then ask if the selection should be expanded.

    gotoStart(selection, expandSelection) {
        if (
            this.props.changeType === OBMC.MOVE ||
            this.props.changeType === OBMC.COPY_AVAILABILITY
        ) {
            API.getReservationsWithTime(
                selection.map((res) => res.id),
                expandSelection,
                (success, reservationIds, timeslots, firstBegin, lastEnd) => {
                    if (success) {
                        const reservations = reservationIds.map((res, index) => ({
                            id: res.id,
                            begin: new MillenniumDateTime(timeslots[index].begin),
                            end: new MillenniumDateTime(timeslots[index].end),
                        }));
                        const begin = new MillenniumDateTime(firstBegin).getMillenniumDate();
                        const end = new MillenniumDateTime(lastEnd).getMillenniumDate();
                        this.setState({
                            reservations,
                            currentState: STATE.DEFAULT,
                            title: null,
                            firstBegin: begin,
                            lastEnd: end,
                            changeData: null,
                        });
                    } else {
                        this.setState({
                            currentState: STATE.MOVE_WITH_GROUPS,
                            title: Language.get("nc_selection_contains_groups"),
                        });
                    }
                }
            );
        } else {
            const newChangeData = this.state.changeData
                ? {
                      selectedType: this.state.changeData.selectedType,
                      dropDownType: this.state.changeData.dropDownType,
                      destinationDropDownType: this.state.changeData.destinationDropDownType,
                      destinationType: this.state.changeData.destinationType,
                      destinationTypes: this.state.changeData.destinationTypes,
                      objectSearch: this.state.changeData.objectSearch,
                  }
                : null;
            this.setState({
                reservations: selection,
                currentState: STATE.DEFAULT,
                title: null,
                changeData: newChangeData,
            });
        }
    },

    gotoResultPresentation(successes, unchanged, reservations, messages, moveErrorCodes) {
        // eslint-disable-next-line no-undef
        /*mixpanel.track("BE Show results", {
            Successes: successes.length,
            Failures: reservations.length,
            "In draft": this.props.activeLayer !== 0,
        });*/
        let currentState = STATE.RESULT_FAILURES;
        let title = Language.get("admin_search_result");
        if (moveErrorCodes && moveErrorCodes.length > 0) {
            currentState = STATE.MOVE_WITH_GROUPS;
            // eslint-disable-next-line no-param-reassign
            moveErrorCodes = [];
            title = Language.get("nc_selection_contains_groups");
        } else if (reservations.length === 0 && successes.length > 0) {
            currentState = STATE.RESULT_SUCCESSES;
        }
        // eslint-disable-next-line no-console
        console.log(successes, messages, reservations);
        this.setState({
            isWorking: false,
            successes,
            currentState,
            expandedLists: [currentState],
            resultReservations: reservations,
            unchanged,
            messages,
            moveErrorCodes,
            title,
        });
    },

    performMassObjectChange(selectedIds, fullReservations = null, addGroupReservations = false) {
        const changeType = this.props.changeType;
        const changeData = this.state.changeData;
        if (!changeData) {
            return;
        }

        const finishChange = (result) => {
            // result[0] - lyckade, [1] - misslyckade, [2] - felmeddelanden, [3] felkoder från tidsändring
            const notChanged = _.filter(
                selectedIds,
                (id) =>
                    !_.contains(
                        result[0].map((res) => res.id),
                        id
                    ) &&
                    !_.contains(
                        result[1].map((res) => res.id),
                        id
                    )
            ).map((id) => ({ id }));
            this.gotoResultPresentation(result[0], notChanged, result[1], result[2], result[3]);
        };

        this.setState({ isWorking: true, progress: 0, changesMade: true });

        const result = [[], [], [], []]; // Successes, failures, error messages, and error code for movement in time
        let counter = 0;

        const splitByDates = (reservations, batchSize, movingBackward = false) => {
            const workingList = [].concat(reservations);
            const results = [];
            let first = 0;
            if (!movingBackward) {
                workingList.reverse();
            }
            while (first < workingList.length) {
                const nextBatch = [];
                const lastDate = new MillenniumDateTime(
                    workingList[first].begin
                ).getMillenniumDate();
                let sameDate = true;
                while (sameDate) {
                    const next = workingList[first];
                    const nextDate = new MillenniumDateTime(next.begin).getMillenniumDate();
                    if (nextDate.getDayNumber() === lastDate.getDayNumber()) {
                        nextBatch.push(next.id);
                        first++;
                        if (first === workingList.length) {
                            sameDate = false;
                        }
                    } else {
                        sameDate = false;
                    }
                }
                results.push(nextBatch);
            }
            return results;
        };

        const performBatchedUpdates = (ids, apiCall, movingBackward = false) => {
            const batches =
                fullReservations !== null
                    ? splitByDates(fullReservations, API.MASS_BATCH_SIZE, movingBackward)
                    : _.splitArray(ids, API.MASS_BATCH_SIZE);
            const calls = batches.map((batch) => {
                const num = ids.length;
                return (done) => {
                    apiCall(
                        batch,
                        (batchResult) => {
                            let progress =
                                (counter / num) * HUNDRED_PERCENT < HUNDRED_PERCENT
                                    ? Math.floor((counter / num) * HUNDRED_PERCENT)
                                    : NINETYNINE_PERCENT;
                            if (isNaN(progress) || progress === undefined) {
                                progress = 0;
                            }
                            counter += batch.length;
                            this.setState({
                                progress,
                            });
                            result[0] = result[0].concat(batchResult[0]);
                            result[1] = result[1].concat(batchResult[1]);
                            result[2] = result[2].concat(batchResult[2]);
                            if (batchResult[4]) {
                                result[3] = result[3].concat(batchResult[4]);
                            }
                            done();
                        },
                        // eslint-disable-next-line no-unused-vars
                        (errorType, failedMessage) => {
                            let progress =
                                (counter / num) * HUNDRED_PERCENT < HUNDRED_PERCENT
                                    ? Math.floor((counter / num) * HUNDRED_PERCENT)
                                    : NINETYNINE_PERCENT;
                            if (isNaN(progress) || progress === undefined) {
                                progress = 0;
                            }
                            counter += batch.length;
                            this.setState({
                                progress,
                            });
                            result[1] = ids;
                            result[2] = ids.map((id) =>
                                Language.get("nc_mass_change_took_too_long", id)
                            );
                            done();
                        }
                    );
                };
            });

            _.runSync(calls, () => {
                finishChange(result);
            });
        };
        // eslint-disable-next-line no-undef
        /*mixpanel.track("BE Perform change", {
            "Number of reservations": selectedIds.length,
            "Change type": Object.keys(OBMC)[changeType],
            "In draft": this.props.activeLayer !== 0,
        });*/
        if (changeType === OBMC.COPY_AVAILABILITY) {
            let numSeconds = changeData.daysToMove * TC.SECONDS_PER_DAY;
            let timeMod =
                changeData.hours * TC.SECONDS_PER_HOUR + changeData.minutes * TC.SECONDS_PER_MINUTE;
            if (!changeData.hoursAndMinutesAreForward) {
                timeMod = -timeMod;
            }
            numSeconds += timeMod;
            const moveIds = [].concat(selectedIds);
            if (numSeconds > 0) {
                moveIds.reverse();
            }
            const doubleReserveAll = changeData.doubleReserveAll || false;
            performBatchedUpdates(
                moveIds,
                (batch, callback, errorCallback) => {
                    API.copyReservationsInTime(
                        batch,
                        numSeconds,
                        addGroupReservations,
                        doubleReserveAll,
                        callback,
                        errorCallback
                    );
                },
                numSeconds < 0
            );
        }
        if (changeType === OBMC.MOVE) {
            let numSeconds = changeData.daysToMove * TC.SECONDS_PER_DAY;
            let timeMod =
                changeData.hours * TC.SECONDS_PER_HOUR + changeData.minutes * TC.SECONDS_PER_MINUTE;
            if (!changeData.hoursAndMinutesAreForward) {
                timeMod = -timeMod;
            }
            numSeconds += timeMod;
            const moveIds = [].concat(selectedIds);
            if (numSeconds > 0) {
                moveIds.reverse();
            }
            const doubleReserveAll = changeData.doubleReserveAll || false;
            const durationSeconds =
                changeData.durationHours !== 0 || changeData.durationMinutes !== 0
                    ? changeData.durationMinutes * TC.SECONDS_PER_MINUTE +
                      changeData.durationHours * TC.SECONDS_PER_HOUR
                    : 0;
            const isDurationRelative = changeData.isDurationRelative;
            performBatchedUpdates(
                moveIds,
                (batch, callback, errorCallback) => {
                    API.moveReservationsInTime(
                        batch,
                        numSeconds,
                        addGroupReservations,
                        doubleReserveAll,
                        durationSeconds,
                        isDurationRelative,
                        callback,
                        errorCallback
                    );
                },
                numSeconds < 0
            );
        } else if (changeType === OBMC.ADD) {
            performBatchedUpdates(selectedIds, (batch, callback, errorCallback) => {
                API.replaceObjectsOnReservations(
                    batch,
                    changeData.selectedObjects[0].id,
                    changeData.selectedObjects[0].typeId,
                    null,
                    undefined, // oldTypeId
                    true,
                    changeData.addIfMissingTypeOnly,
                    true,
                    changeData.allowDoubleBooking,
                    callback,
                    errorCallback
                );
            });
        } else if (changeType === OBMC.REMOVE) {
            performBatchedUpdates(selectedIds, (batch, callback, errorCallback) => {
                API.replaceObjectsOnReservations(
                    batch,
                    null,
                    changeData.selectedType.id,
                    changeData.toReplace,
                    changeData.selectedType.id, // oldTypeId
                    true,
                    false,
                    true,
                    false,
                    callback,
                    errorCallback
                );
            });
        } else if (changeType === OBMC.REPLACE) {
            performBatchedUpdates(selectedIds, (batch, callback, errorCallback) => {
                API.replaceObjectsOnReservations(
                    batch,
                    changeData.replacement,
                    changeData.destinationType.id,
                    changeData.toReplace,
                    changeData.selectedType.id,
                    true,
                    false,
                    true,
                    changeData.allowDoubleBooking,
                    callback,
                    errorCallback
                );
            });
        } else if (changeType === OBMC.ASSIGN) {
            const searcher = changeData.objectSearch;
            new Selection()
                .freeze()
                .createFluffy(0, 0, TemplateKind.RESERVATION, (newSelection) => {
                    const baseFluffy = newSelection.fluffy;
                    searcher.getAll((allObjects) => {
                        // TODO Don't perform assignment if allObjects is empty
                        const allObjectIds = allObjects.map((object) => object.id);

                        const isRecentlyUsedChecked = this.context.user.isRecentlyUsedChecked(
                            changeData.selectedType.id
                        );
                        let skipLastReserved = undefined;
                        if (isRecentlyUsedChecked === true) {
                            skipLastReserved = false;
                        }
                        if (isRecentlyUsedChecked === false) {
                            skipLastReserved = true;
                        }
                        skipLastReserved = false;

                        // eslint-disable-next-line no-unused-vars
                        performBatchedUpdates(selectedIds, (batch, callback, errorCallback) => {
                            const first = batch.shift();
                            assignToAllReservations(
                                first,
                                batch,
                                baseFluffy,
                                searcher,
                                allObjectIds,
                                changeData.useRelatedObjects,
                                this.props.activeLayer,
                                skipLastReserved,
                                (res) => {
                                    callback(res);
                                }
                            );
                        });
                    });
                });
        }
    },

    onSelectionChanged(newData) {
        this.setState({ changeData: newData });
    },

    onFinished() {
        this.props.finishedCallback(this.state.changesMade);
    },

    reportProgress(newProgress) {
        this.setState(newProgress);
    },

    getCopyAvailabilityDescription(
        daysToMove,
        hours,
        minutes,
        hoursAndMinutesAreForward,
        numReservations
    ) {
        const days = Math.abs(daysToMove);
        const result = [];
        if (days > 0) {
            const dayDirection =
                daysToMove < 0
                    ? Language.get("nc_mass_move_backward")
                    : Language.get("nc_mass_move_forward");
            result.push(Language.get("nc_mass_copy_x_days_direction", days, dayDirection));
        }
        if (hours > 0 || minutes > 0) {
            if (days > 0) {
                result.push(Language.get("nc_mass_move_and"));
            }
            const timeDirection = hoursAndMinutesAreForward
                ? Language.get("nc_mass_move_forward")
                : Language.get("nc_mass_move_backward");
            // eslint-disable-next-line no-magic-numbers
            result.push(`${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${timeDirection}`);
        }

        return Language.get(
            "nc_mass_move_x_reservations_will_be_copied_y",
            numReservations,
            result.join(" ")
        );
    },

    getMoveDescription(daysToMove, hours, minutes, hoursAndMinutesAreForward, numReservations) {
        const days = Math.abs(daysToMove);
        const result = [];
        if (days > 0) {
            const dayDirection =
                daysToMove < 0
                    ? Language.get("nc_mass_move_backward")
                    : Language.get("nc_mass_move_forward");
            result.push(Language.get("nc_mass_move_x_days_direction", days, dayDirection));
        }
        if (hours > 0 || minutes > 0) {
            if (days > 0) {
                result.push(Language.get("nc_mass_move_and"));
            }
            const timeDirection = hoursAndMinutesAreForward
                ? Language.get("nc_mass_move_forward")
                : Language.get("nc_mass_move_backward");
            // eslint-disable-next-line no-magic-numbers
            result.push(`${hours}:${minutes < 10 ? `0${minutes}` : minutes} ${timeDirection}`);
        }

        return Language.get(
            "nc_mass_move_x_reservations_will_be_moved_y",
            numReservations,
            result.join(" ")
        );
    },

    getConfirmDescription(changeType, changeData, numReservations) {
        if (changeType === OBMC.ADD) {
            return Language.get(
                "nc_mass_change_add_x_to_y_reservations.",
                changeData.selectedObjects[0].name,
                numReservations
            );
        }
        if (changeType === OBMC.REMOVE) {
            if (
                Array.isArray(changeData.toReplace) &&
                changeData.toReplace.length === changeData.selectedObjects.length
            ) {
                return Language.get(
                    "nc_mass_change_remove_all_of_x_from_y_reservations.",
                    changeData.selectedType.name,
                    numReservations
                );
            } else if (Array.isArray(changeData.toReplace)) {
                return Language.get(
                    "nc_mass_change_remove_x_from_y_reservations.",
                    Language.get("nc_x_objects", changeData.toReplace.length),
                    numReservations
                );
            }
            return Language.get(
                "nc_mass_change_remove_x_from_y_reservations.",
                changeData.labelReplaceText,
                numReservations
            );
        }
        if (changeType === OBMC.REPLACE) {
            return Language.get(
                "nc_mass_change_replace_x_with_y_on_z_reservations.",
                changeData.labelReplaceText,
                changeData.labelReplaceWithText,
                numReservations
            );
        }
        if (changeType === OBMC.MOVE) {
            return this.getMoveDescription(
                changeData.daysToMove,
                changeData.hours,
                changeData.minutes,
                changeData.hoursAndMinutesAreForward,
                numReservations
            );
        }
        if (changeType === OBMC.COPY_AVAILABILITY) {
            return this.getCopyAvailabilityDescription(
                changeData.daysToMove,
                changeData.hours,
                changeData.minutes,
                changeData.hoursAndMinutesAreForward,
                numReservations
            );
        }
        if (changeType === OBMC.ASSIGN) {
            return Language.get(
                "nc_objects_of_type_x_will_be_assigned_to_y_reservations.",
                changeData.selectedType.name,
                numReservations
            );
        }
        return "";
    },

    getLabel(changeType, labels) {
        return Language.get(labels[changeType]);
    },

    getConfirmTitle(changeType) {
        return this.getLabel(changeType, [
            "nc_confirm_mass_add",
            "nc_confirm_mass_remove",
            "nc_confirm_mass_replace",
            "nc_confirm_mass_move",
            "nc_confirm_mass_assign",
            "cal_reservation_action_button_copy",
        ]);
    },

    getAgainLabel(changeType) {
        return this.getLabel(changeType, [
            "nc_mass_add_again",
            "nc_mass_remove_again",
            "nc_mass_replace_again",
            "nc_mass_move_again",
            "nc_mass_assign_again",
            "nc_mass_copy_again",
        ]);
    },

    getFailedLabel(changeType) {
        return this.getLabel(changeType, [
            "nc_mass_add_failed_again",
            "nc_mass_remove_failed_again",
            "nc_mass_replace_failed_again",
            "nc_mass_move_failed_again",
            "nc_mass_assign_failed_again",
            "nc_mass_copy_failed_again",
        ]);
    },

    getButtonTitleForChangeType(changeType) {
        return this.getLabel(changeType, [
            "nc_mass_change_add_object",
            "nc_mass_change_remove_object",
            "nc_mass_change_replace_object",
            this.props.isCopy ? "nc_mass_change_move_copies" : "cal_res_below_move",
            "nc_mass_change_assign_object",
            "cal_reservation_action_button_copy",
        ]);
    },

    showNoReservationsAffectedMessage() {
        this.context.presentModal(
            <div>{Language.get("nc_mass_change_no_reservations_affected_description")}</div>,
            null,
            Language.get("nc_mass_change_no_reservations_affected_headline")
        );
    },

    renderMoveWithGroups() {
        return (
            <div className="massChangeResultsWrapper">
                <div>{Language.get("nc_mass_move_contains_groups")}</div>
            </div>
        );
    },

    showConfirmDialog(selectedIds, reservations) {
        if (selectedIds.length === 0) {
            this.showNoReservationsAffectedMessage();
        } else {
            const displayKey = "massChangeModalDialogConfirmChange";
            API.getPreferences(`dismissedModalDialogs.${displayKey}`, (value) => {
                if (!value) {
                    const buttons = [];
                    buttons.push({
                        title: Language.get("dialog_cancel"),
                        remember: false,
                    });
                    buttons.push({
                        cb: () => this.performMassObjectChange(selectedIds, reservations),
                        title: this.getButtonTitleForChangeType(this.props.changeType),
                        remember: true,
                    });

                    const content = (
                        <div>
                            {this.getConfirmDescription(
                                this.props.changeType,
                                this.state.changeData,
                                selectedIds.length
                            )}
                            <br />
                            {Language.get("nc_mass_change_this_could_take_a_while")}
                        </div>
                    );
                    const title = this.getConfirmTitle(this.props.changeType);
                    this.context.presentModal(content, displayKey, title, buttons);
                } else {
                    this.performMassObjectChange(selectedIds, reservations);
                }
            });
        }
    },

    confirm() {
        const removeObjectId = this.state.changeData.toReplace; // If adding, this will be undefined which is handled by filterToObject
        let addObjectId = this.state.changeData.replacement;
        let addTypeId = null;
        if (this.props.changeType === OBMC.ADD) {
            addObjectId = this.state.changeData.selectedObjects[0].id;
            addTypeId = this.state.changeData.selectedObjects[0].typeId;
        }
        if (this.props.changeType === OBMC.MOVE) {
            this.showConfirmDialog(
                this.state.reservations.map((res) => res.id),
                this.state.reservations
            );
        } else if (this.props.changeType === OBMC.COPY_AVAILABILITY) {
            this.showConfirmDialog(
                this.state.reservations.map((res) => res.id),
                this.state.reservations
            );
        } else {
            filterToObject(
                this.state.reservations.map((res) => res.id),
                removeObjectId,
                addObjectId,
                addTypeId,
                this.state.changeData.addIfMissingTypeOnly,
                (reservationIds) => {
                    this.showConfirmDialog(reservationIds);
                }
            );
        }
    },

    replaceAgain(useFailures) {
        let reservations = useFailures ? this.state.resultReservations : this.props.reservations;
        if (
            (!useFailures && this.props.changeType === OBMC.MOVE) ||
            this.props.changeType === OBMC.COPY_AVAILABILITY
        ) {
            reservations = this.state.reservations; // The ones in props no longer have correct times
        }
        if (useFailures) {
            // eslint-disable-next-line no-undef
            /*mixpanel.track("BE Done", {
                Done: "Replace failed",
                "Number of reservations": reservations.length,
                "Change type": Object.keys(OBMC)[this.props.changeType],
                "In draft": this.props.activeLayer !== 0,
            });*/
        } else {
            // eslint-disable-next-line no-undef
            /*mixpanel.track("BE Done", {
                Done: "Start new",
                "Number of reservations": reservations.length,
                "Change type": Object.keys(OBMC)[this.props.changeType],
                "In draft": this.props.activeLayer !== 0,
            });*/
        }
        this.gotoStart(reservations, false);
    },

    isEverythingRequiredSelected(changeType, changeData) {
        if (!changeData) {
            return false;
        }
        if (changeType === OBMC.REPLACE) {
            return changeData.toReplace && changeData.replacement;
        }
        if (changeType === OBMC.REMOVE) {
            return changeData.toReplace && changeData.selectedObjects;
        }
        if (changeType === OBMC.ADD) {
            return !_.isNullish(changeData.selectedObjects);
        }
        if (changeType === OBMC.ASSIGN) {
            return !_.isNullish(changeData.selectedType);
        }
        return true;
    },

    renderButtons(disabled) {
        if (this.state.currentState === STATE.MOVE_WITH_GROUPS) {
            return (
                <div>
                    <button
                        className="save xWide"
                        disabled={disabled}
                        onClick={() => {
                            // eslint-disable-next-line no-undef
                            /*mixpanel.track("BE Done", {
                                Done: "Cancel",
                                "Number of reservations": this.state.reservations.length,
                                "Change type": Object.keys(OBMC)[this.props.changeType],
                                "In draft": this.props.activeLayer !== 0,
                            });*/
                            this.onFinished();
                        }}
                    >
                        {Language.get("dialog_cancel")}
                    </button>
                    <button
                        className="save xWide"
                        disabled={disabled}
                        onClick={() => {
                            this.gotoStart(this.props.reservations, true);
                        }}
                    >
                        {Language.get("nc_include_groups")}
                    </button>
                </div>
            );
        }
        if (isResultState(this.state.currentState)) {
            let replaceFailedButton = null;
            if (this.state.resultReservations.length > 0) {
                replaceFailedButton = (
                    <button
                        disabled={disabled}
                        key="replaceFailedAgain"
                        className="save xWide"
                        onClick={() => this.replaceAgain(true)}
                    >
                        {this.getFailedLabel(this.props.changeType)}
                    </button>
                );
            }
            return (
                <div>
                    <button
                        disabled={disabled}
                        key="replaceAgain"
                        className="save xWide"
                        onClick={() => this.replaceAgain(false)}
                    >
                        {this.getAgainLabel(this.props.changeType)}
                    </button>
                    {replaceFailedButton}
                    <button
                        disabled={disabled}
                        key="save"
                        className="save xWide"
                        onClick={() => {
                            // eslint-disable-next-line no-undef
                            /*mixpanel.track("BE Done", {
                                Done: "Done",
                                "Number of reservations": this.state.reservations.length,
                                "Change type": Object.keys(OBMC)[this.props.changeType],
                                "In draft": this.props.activeLayer !== 0,
                            });*/
                            this.onFinished();
                        }}
                    >
                        {Language.get("nc_dialog_done")}
                    </button>
                </div>
            );
        }
        return (
            <div>
                <button
                    disabled={disabled}
                    className="save leftAligned xWide"
                    onClick={() => {
                        // eslint-disable-next-line no-undef
                        /*mixpanel.track("BE Done", {
                            Done: "Cancel",
                            "Number of reservations": this.state.reservations.length,
                            "Change type": Object.keys(OBMC)[this.props.changeType],
                            "In draft": this.props.activeLayer !== 0,
                        });*/
                        this.onFinished();
                    }}
                >
                    {Language.get("dialog_cancel")}
                </button>
                <button
                    disabled={
                        disabled ||
                        !this.isEverythingRequiredSelected(
                            this.props.changeType,
                            this.state.changeData
                        )
                    }
                    className="save xWide"
                    onClick={this.confirm}
                >
                    {this.getButtonTitleForChangeType(this.props.changeType)}
                </button>
            </div>
        );
    },

    renderResultList(expandedLists, listState, reservations) {
        const ID_OFFSET = 1000;
        if (reservations.length === 0) {
            return null;
        }
        let columns = [...this.props.columns];
        let defaultSelectedColumns = [...this.props.defaultSelectedColumns];
        const extraColumns = [];
        if (this.state.changeData.destinationType) {
            extraColumns.push({
                name: this.state.changeData.destinationType.name,
                id: ID_OFFSET + this.state.changeData.destinationType.id,
                sortable: true,
            });
        }
        if (
            this.state.changeData.selectedType &&
            !_.some(
                extraColumns,
                (col) => col.id === ID_OFFSET + this.state.changeData.selectedType.id
            )
        ) {
            extraColumns.push({
                name: this.state.changeData.selectedType.name,
                id: ID_OFFSET + this.state.changeData.selectedType.id,
                sortable: true,
            });
        }
        if (extraColumns.length > 0) {
            columns = columns.concat(extraColumns);
            defaultSelectedColumns = defaultSelectedColumns.concat(
                extraColumns.map((col) => col.id)
            );
        }
        return (
            <MassChangeResultList
                isFailureList={listState === STATE.RESULT_FAILURES}
                onHeaderClick={this.toggleList}
                isExpanded={expandedLists.indexOf(listState) !== -1}
                listState={listState}
                defaultSelectedColumns={defaultSelectedColumns}
                columns={columns}
                reservations={reservations}
                messages={this.state.messages}
            />
        );
    },

    renderResultLists(expandedLists, successes, failures) {
        return (
            <div className="massChangeResultsWrapper">
                {this.renderResultList(expandedLists, STATE.RESULT_SUCCESSES, successes)}
                {this.renderResultList(expandedLists, STATE.RESULT_FAILURES, failures)}
            </div>
        );
    },

    _renderView() {
        if (isResultState(this.state.currentState)) {
            return this.renderResultLists(
                this.state.expandedLists,
                this.state.successes,
                this.state.resultReservations
            );
        }
        if (this.state.currentState === STATE.MOVE_WITH_GROUPS) {
            return this.renderMoveWithGroups();
        }
        const ids = this.state.reservations.map((res) => res.id);
        if (this.props.changeType === OBMC.MOVE) {
            return (
                <MassMoveReservationsDialog
                    onSelectionChanged={this.onSelectionChanged}
                    isMassChangeCopy={this.props.isCopy}
                    reservationIds={ids}
                    calendar={this.props.calendar}
                    firstDate={this.state.firstBegin}
                />
            );
        }
        if (this.props.changeType === OBMC.COPY_AVAILABILITY) {
            return (
                <MassMoveReservationsDialog
                    onSelectionChanged={this.onSelectionChanged}
                    isMassChangeCopy={true}
                    reservationIds={ids}
                    calendar={this.props.calendar}
                    firstDate={this.state.firstBegin}
                />
            );
        }
        if (this.props.changeType === OBMC.ASSIGN) {
            return (
                <MassAssignObjectsDialog
                    startTypeId={this.props.startTypeId}
                    changeType={this.props.changeType}
                    changeData={this.state.changeData}
                    onSelectionChanged={this.onSelectionChanged}
                    reservationIds={ids}
                    selection={this.props.selection}
                    user={this.context.user}
                />
            );
        }
        return (
            <MassChangeObjectsDialog
                startTypeId={this.props.startTypeId}
                changeType={this.props.changeType}
                changeData={this.state.changeData}
                onSelectionChanged={this.onSelectionChanged}
                reservationIds={ids}
                user={this.context.user}
            />
        );
    },

    render() {
        const loadIndicator = this.state.isWorking ? (
            <div className="overlay">
                <div className="inner">
                    {`${this.state.progress}%`}
                    <div className="spinner" />
                </div>
            </div>
        ) : null;
        const buttons = (
            <div className="buttons btnGroup horizontal">
                {this.renderButtons(this.state.isWorking)}
            </div>
        );
        return (
            <div className="massChangeController">
                <h2>
                    {this.state.title ||
                        Language.get(
                            "nc_mass_change_x_reservations",
                            this.state.reservations.length
                        )}
                </h2>
                {loadIndicator}
                {this._renderView()}
                {buttons}
            </div>
        );
    },
});

module.exports = MassChangeController;
