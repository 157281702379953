const PropTypes = require("prop-types");
const React = require("react");
const Accordion = require("./Accordion");
const IntegerInput = require("./IntegerInput");
const ArrayInput = require("./ArrayInput");
const DateInput = require("./DateInput");
const Language = require("../lib/Language");
const _ = require("underscore");
const OrganizationList = require("./ObjectListWithDropdown");
const Mousetrap = require("mousetrap");
const TC = require("../lib/TimeConstants");

import { MillenniumDate, MillenniumTime, SimpleDateFormat } from "millennium-time";
import { renderLine, presentObjects, getUserTime, getOrgName, STATUS } from "../lib/OrderUtils";

class OrderEditor extends React.Component {
    static propTypes = {
        orders: PropTypes.array.isRequired,
        users: PropTypes.array.isRequired,
        periods: PropTypes.array.isRequired,
        types: PropTypes.array.isRequired,
    };

    state = {
        orders: this.props.orders,
        accordionSections: this.props.expandedSections || [0],
    };

    componentDidMount() {
        this.bindSave();
        this._previousEscBindings = Mousetrap.unbindWithHelp("esc", true)[0];
        Mousetrap.bindWithHelp("esc", this.props.onClose);
    }

    componentWillUnmount() {
        Mousetrap.unbindWithHelp("mod+s");

        if (this._previousSaveBinding) {
            Mousetrap.bindWithHelp(
                "mod+s",
                this._previousSaveBinding,
                undefined,
                Language.get("dialog_save")
            );
            this._previousSaveBinding = null;
        }

        Mousetrap.unbindWithHelp("esc");
        Mousetrap.bindWithHelp("esc", this._previousEscBindings);
    }

    bindSave = () => {
        this._previousSaveBinding = Mousetrap.unbindWithHelp("mod+s", true)[0];
        Mousetrap.bindWithHelp(
            "mod+s",
            () => {
                this.onSave();
                return false;
            },
            undefined,
            Language.get("dialog_save")
        );
    };

    checkboxEditor(value, onChange) {
        return <input type="checkbox" value={value} checked={value} onChange={onChange} />;
    }

    textEditor(value, onChange, isTextArea = false) {
        if (isTextArea) {
            return <textarea rows={5} value={value} onChange={onChange} />;
        }
        return <input type="text" value={value} onChange={onChange} />;
    }

    updateOrderRow(orderRow) {
        const updatedOrder = _.clone(this.state.orders[0]);
        updatedOrder.rows = updatedOrder.rows.filter((row) => row.id !== orderRow.id);
        updatedOrder.rows.push(orderRow);
        this.setState({ orders: [updatedOrder] });
    }

    onCompletedChanged(orderRow, event) {
        const newRow = _.clone(orderRow);
        newRow.completed = event.target.checked;
        this.updateOrderRow(newRow);
    }

    onCountChanged(orderRow, value) {
        const newRow = _.clone(orderRow);
        newRow.order_count = value;
        this.updateOrderRow(newRow);
    }

    onOrderedHoursChanged(orderRow, value) {
        const newRow = _.clone(orderRow);
        newRow.order_length =
            value * TC.SECONDS_PER_HOUR + (orderRow.order_length % TC.SECONDS_PER_HOUR);
        this.updateOrderRow(newRow);
    }

    onOrderedMinutesChanged(orderRow, value) {
        const newRow = _.clone(orderRow);
        newRow.order_length =
            value * TC.MINUTES_PER_HOUR +
            (orderRow.order_length - (orderRow.order_length % TC.SECONDS_PER_HOUR));
        this.updateOrderRow(newRow);
    }

    onOrderBeginChanged(value) {
        const updatedOrder = _.clone(this.state.orders[0]);
        updatedOrder.begin = value.getDayNumber();
        this.setState({ orders: [updatedOrder] });
    }

    onOrderEndChanged(value) {
        const updatedOrder = _.clone(this.state.orders[0]);
        updatedOrder.end = value.getDayNumber();
        this.setState({ orders: [updatedOrder] });
    }

    onOrderStatusChanged(value) {
        const updatedOrder = _.clone(this.state.orders[0]);
        updatedOrder.status.status = value;
        this.setState({ orders: [updatedOrder] });
    }

    onOrderRowStatusChanged(orderRow, value) {
        const newRow = _.clone(orderRow);
        newRow.status.status = value;
        this.updateOrderRow(newRow);
    }

    onOrderRowInfoChanged(orderRow, event) {
        const newRow = _.clone(orderRow);
        newRow.description = event.target.value;
        this.updateOrderRow(newRow);
    }

    onOrderRowCommentChanged(orderRow, event) {
        const newRow = _.clone(orderRow);
        newRow.comment = event.target.value;
        this.updateOrderRow(newRow);
    }

    onOrderPeriodChanged(value) {
        const updatedOrder = _.clone(this.state.orders[0]);
        if (!updatedOrder.period) {
            updatedOrder.period = { class: "periodid", id: value };
        } else {
            updatedOrder.period.id = value;
        }
        this.setState({ orders: [updatedOrder] });
    }

    onOrderTitleChanged(event) {
        const updatedOrder = _.clone(this.state.orders[0]);
        updatedOrder.title = event.target.value;
        this.setState({ orders: [updatedOrder] });
    }

    onOrderDescriptionChanged(event) {
        const updatedOrder = _.clone(this.state.orders[0]);
        updatedOrder.description = event.target.value;
        this.setState({ orders: [updatedOrder] });
    }

    getDefinition(id, definitions) {
        return _.find(definitions, (def) => def.id === id) || null;
    }

    onOrganizationsChanged = (selectedOrganizations) => {
        const updatedOrder = _.clone(this.state.orders[0]);
        updatedOrder.orgs = selectedOrganizations.map((org) => ({
            class: "organizationid",
            id: org.id,
        }));
        this.setState({ orders: [updatedOrder] });
    };

    onOrganizationSelected = (event) => {
        const updatedOrder = _.clone(this.state.orders[0]);
        if (!updatedOrder.orgs) {
            updatedOrder.orgs = [];
        }
        updatedOrder.orgs.push({ class: "organizationid", id: parseInt(event.target.value, 10) });
        this.setState({ orders: [updatedOrder] });
    };

    renderOrganizationSection(organizations, selectedOrganizations) {
        const selectedIds = selectedOrganizations.map((org) => org.id);
        return (
            <OrganizationList
                items={selectedOrganizations.map((org) =>
                    this.getDefinition(org.id, organizations)
                )}
                editable={true}
                propertyName={null}
                dropdownOptions={{
                    options: organizations.filter((org) => selectedIds.indexOf(org.id) === -1),
                    headlineValue: Language.get("nc_option_select"),
                }}
                label={Language.get("dynamic_object_info_orgs")}
                onItemsChanged={this.onOrganizationsChanged}
                onOptionSelected={this.onOrganizationSelected}
                editFunction={_.noop}
                searchObject={null}
                updateSearchObject={_.noop}
                getAvailabilityObjects={_.noop}
                totalNumber={selectedOrganizations.length}
                presentMembershipPeriod={""}
            />
        );
    }

    _renderOrder(order, index) {
        const types = this.props.types;
        const organizations = this.props.organizations;
        const users = this.props.users;
        let disableDateFields = false;
        if (order.period && order.period.id !== 0) {
            disableDateFields = true;
        }
        return (
            <div key={index}>
                <table>
                    <tbody>
                        {renderLine("cal_order_side_id", order.id, "id")}
                        {renderLine("cal_order_side_extid", order.extid, "extid")}
                        {renderLine(
                            "cal_order_side_created",
                            getUserTime(order.created, order.created_by.id, users),
                            "createdBy"
                        )}
                        {renderLine(
                            "cal_order_side_modified",
                            getUserTime(order.modified, order.modified_by.id, users),
                            "modifiedBy"
                        )}
                        {renderLine(
                            "cal_order_side_description",
                            this.textEditor(
                                order.description || "",
                                this.onOrderDescriptionChanged.bind(this)
                            ),
                            "description"
                        )}
                        {renderLine(
                            "dynamic_object_info_orgs",
                            this.renderOrganizationSection(organizations, order.orgs || []),
                            "organizations"
                        )}
                        {renderLine(
                            "cal_order_side_organizations",
                            (order.orgs || [])
                                .map((org) => getOrgName(org.id, organizations))
                                .join(", "),
                            "orgs"
                        )}
                        {renderLine(
                            "cal_order_side_period",
                            <ArrayInput
                                limit={1}
                                onUpdate={this.onOrderPeriodChanged.bind(this)}
                                defaultValue={[
                                    {
                                        id: 0,
                                        value: 0,
                                        label: "-",
                                        selected: !order.period || order.period.id === 0,
                                    },
                                ].concat(
                                    this.props.periods.map((period) => ({
                                        id: period.id,
                                        value: period.id,
                                        label: period.name,
                                        selected:
                                            period.id === (order.period ? order.period.id : -1),
                                    }))
                                )}
                            />,
                            "period"
                        )}
                        {renderLine(
                            "cal_order_side_start",
                            <DateInput
                                defaultValue={new MillenniumDate(order.begin)}
                                disabled={disableDateFields}
                                onUpdate={this.onOrderBeginChanged.bind(this)}
                            />,
                            "begin"
                        )}
                        {renderLine(
                            "cal_order_side_end",
                            <DateInput
                                defaultValue={new MillenniumDate(order.end)}
                                disabled={disableDateFields}
                                onUpdate={this.onOrderEndChanged.bind(this)}
                            />,
                            "end"
                        )}
                        {renderLine(
                            "cal_order_side_status",
                            //getOrderStatus(order.status.status),
                            <ArrayInput
                                limit={1}
                                onUpdate={this.onOrderStatusChanged.bind(this)}
                                defaultValue={[
                                    {
                                        id: 0,
                                        value: 0,
                                        label: "-",
                                        selected: order.status.status === 0,
                                    },
                                    {
                                        id: 1,
                                        value: 1,
                                        label: Language.get("order_status_active"),
                                        selected: order.status.status === 1,
                                    },
                                    {
                                        id: 2,
                                        value: 2,
                                        label: Language.get("order_status_passive"),
                                        // eslint-disable-next-line no-magic-numbers
                                        selected: order.status.status === 2,
                                    },
                                ]}
                            />,
                            "status"
                        )}
                        {renderLine(
                            "cal_order_side_title",
                            this.textEditor(order.title || "", this.onOrderTitleChanged.bind(this)),
                            "title"
                        )}
                        {renderLine(
                            "cal_order_side_mandatory_object",
                            "",
                            "requiredObject",
                            "tableSubheader"
                        )}
                        {presentObjects([order.required_object], types)}
                        {renderLine(
                            "cal_order_side_customer_object",
                            "",
                            "customerObject",
                            "tableSubheader"
                        )}
                        {presentObjects([order.customer_object], types)}
                        {renderLine(
                            "cal_order_side_owner_object",
                            "",
                            "ownerObject",
                            "tableSubheader"
                        )}
                        {presentObjects([order.owner_object], types)}
                    </tbody>
                </table>
            </div>
        );
    }

    /*
{renderLine(
                            "cal_order_side_ordered_time",
                            orderRow.order_length
                                ? SimpleDateFormat.format(
                                      new MillenniumTime(orderRow.order_length),
                                      Language.getDateFormat("date_f_hh_mm")
                                  )
                                : 0,
                            "orderedTime"
                        )}
*/

    _renderOrderRow(orderRow, types, periods, index) {
        return (
            <div key={index}>
                <table>
                    <tbody>
                        {renderLine(
                            "cal_order_side_ordered_count",
                            <IntegerInput
                                onUpdate={this.onCountChanged.bind(this, orderRow)}
                                defaultValue={orderRow.order_count || 0}
                            />,
                            "orderedCount"
                        )}
                        {renderLine(
                            "cal_order_side_ordered_time",
                            <span>
                                <IntegerInput
                                    classNames="timeField"
                                    onUpdate={this.onOrderedHoursChanged.bind(this, orderRow)}
                                    defaultValue={
                                        orderRow.order_length
                                            ? Math.floor(
                                                  orderRow.order_length / TC.SECONDS_PER_HOUR
                                              )
                                            : 0
                                    }
                                />{" "}
                                :{" "}
                                <IntegerInput
                                    classNames="timeField"
                                    onUpdate={this.onOrderedMinutesChanged.bind(this, orderRow)}
                                    defaultValue={
                                        orderRow.order_length
                                            ? (orderRow.order_length % TC.SECONDS_PER_HOUR) /
                                              TC.MINUTES_PER_HOUR
                                            : 0
                                    }
                                />
                            </span>,
                            "orderedTime"
                        )}
                        {renderLine(
                            "cal_order_side_delivered_count",
                            orderRow.delivered_count,
                            "deliveredCount"
                        )}
                        {renderLine(
                            "cal_order_side_delivered_time",
                            orderRow.delivered_length
                                ? SimpleDateFormat.format(
                                      new MillenniumTime(orderRow.delivered_length),
                                      Language.getDateFormat("date_f_hh_mm")
                                  )
                                : 0,
                            "deliveredTime"
                        )}
                        {renderLine(
                            "cal_order_side_expander_order_row_info",
                            this.textEditor(
                                orderRow.description || "",
                                this.onOrderRowInfoChanged.bind(this, orderRow)
                            ),
                            "info"
                        )}
                        {renderLine(
                            "cal_order_side_row_description",
                            this.textEditor(
                                orderRow.comment || "",
                                this.onOrderRowCommentChanged.bind(this, orderRow),
                                true
                            ),
                            "comment"
                        )}
                        {renderLine(
                            "cal_order_side_row_status",
                            <ArrayInput
                                limit={1}
                                onUpdate={this.onOrderRowStatusChanged.bind(this, orderRow)}
                                defaultValue={[
                                    {
                                        id: 0,
                                        value: 0,
                                        label: "-",
                                        selected: orderRow.status.status === STATUS.NONE,
                                    },
                                    {
                                        id: 1,
                                        value: 1,
                                        label: Language.get("order_status_row_partial"),
                                        selected: orderRow.status.status === STATUS.PARTIAL,
                                    },
                                    {
                                        id: 2,
                                        value: 2,
                                        label: Language.get("order_status_row_fulfilled"),
                                        selected: orderRow.status.status === STATUS.FULFILLED,
                                    },
                                    {
                                        id: 3,
                                        value: 3,
                                        label: Language.get("order_status_row_exceeded"),
                                        selected: orderRow.status.status === STATUS.EXCEEDED,
                                    },
                                    {
                                        id: 4,
                                        value: 4,
                                        label: Language.get("order_status_row_unlimited"),
                                        selected: orderRow.status.status === STATUS.UNLIMITED,
                                    },
                                    {
                                        id: 5,
                                        value: 5,
                                        label: Language.get("order_status_row_passive"),
                                        selected: orderRow.status.status === STATUS.PASSIVE,
                                    },
                                ]}
                            />,
                            "status"
                        )}
                        {renderLine(
                            "order_status_completed",
                            this.checkboxEditor(
                                orderRow.completed,
                                this.onCompletedChanged.bind(this, orderRow)
                            ),
                            "completed"
                        )}
                        {renderLine(
                            "cal_order_side_order_objects",
                            "",
                            "orderObjects",
                            "tableSubheader"
                        )}
                        {presentObjects(orderRow.objects, types)}
                        {renderLine(
                            "cal_order_side_extra_objects",
                            "",
                            "extraObjects",
                            "tableSubheader"
                        )}
                        {presentObjects(orderRow.extra_objects, types)}
                    </tbody>
                </table>
            </div>
        );
    }

    onSave() {
        this.props.onSave(this.state.orders);
    }

    onCancel() {
        this.props.onCancel(this.props.orders);
    }

    render() {
        const types = this.props.types;
        const periods = this.props.periods;
        const rowSections = [];
        const sections = this.state.orders.map((order) => {
            order.rows.forEach((or, index) => {
                rowSections.push({
                    title: `${Language.get("cal_order_side_expander_order_row")} ${or.id}`,
                    content: this._renderOrderRow(or, types, periods, index),
                });
            });
            return {
                title: `${Language.get("cal_order_side_expander_order")} ${
                    order.title || order.id
                }`,
                content: this._renderOrder(order),
            };
        });
        const allSections = sections.concat(rowSections);
        return (
            <div className="reservationPane">
                <div style={{ display: "block" }}>
                    <div className="dismiss" onClick={this.props.onClose} />
                    <h2>{Language.get("cal_res_side_tab_order_info")}</h2>
                </div>
                <Accordion
                    sections={allSections}
                    initialSection={this.state.accordionSections}
                    onContentChange={this.props.onAccordionChange}
                />
                <div className="btnGroup">
                    <button className="ok" onClick={this.onSave.bind(this)}>
                        {Language.get("dialog_save")}
                    </button>
                    <button className="remove" onClick={this.onCancel.bind(this)}>
                        {Language.get("dialog_cancel")}
                    </button>
                </div>
            </div>
        );
    }
}

module.exports = OrderEditor;
