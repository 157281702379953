const PropTypes = require("prop-types");
const React = require("react");
const DateSelect = require("./DateSelect");
import { MillenniumDate } from "millennium-time";
const Language = require("../lib/Language");
const Mousetrap = require("mousetrap");
const TimeConstants = require("../lib/TimeConstants");

class ToolButtons extends React.Component {
    static contextTypes = {
        update: PropTypes.func,
    };

    state = {
        cursorTime: null,
    };

    _isMounted = false;

    componentDidMount() {
        this._isMounted = true;
        this.registerKeyboardShortcuts();
        if (this.props.registerTimeListener) {
            this.props.registerTimeListener(this.onCursorTimeChanged);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    registerKeyboardShortcuts = () => {
        if (this.isDateSelectEnabled()) {
            Mousetrap.bindWithHelp(
                "mod+g",
                this.showCal,
                undefined,
                Language.get("nc_help_toggle_calendar.")
            );
        }
    };

    showCal = (event) => {
        if (event) {
            event.preventDefault();
        }
        this.toggleCalendar();
    };

    previous = () => {
        this.moveUnits(-1);
    };

    next = () => {
        this.moveUnits(1);
    };

    gotoDateTime = (date) => {
        const dt = date[0] || date;
        this.toggleCalendar();
        this.context.update(this.props.calendar, this.props.calendar.gotoDateTime(dt));
    };

    moveUnits = (numUnits) => {
        const header = this.props.calendar.getActiveHeader(this.props.isXAxis);
        if (numUnits === 1) {
            this.context.update(header, header.increaseFirstVisibleValue());
        } else if (numUnits === -1) {
            this.context.update(header, header.decreaseFirstVisibleValue());
        }
    };

    toggleCommandBar = () => {
        this.props.toggleCommandBar();
    };

    toggleConflictMode = () => {
        this.props.toggleConflictMode();
    };

    toggleCalendar = () => {
        if (this.props.showDateSelect) {
            this.props.setDateSelectorVisibility(false);
        } else {
            this.props.setDateSelectorVisibility(true);
        }
    };

    getHeader = () => this.props.calendar.getActiveHeader(this.props.isXAxis);

    onCursorTimeChanged = (newTime) => {
        if (this._isMounted) {
            this.setState({ cursorTime: newTime });
        }
    };

    canScrollToPrevious = () => {
        const header = this.getHeader();
        if (!header) {
            return false;
        }
        const prevHeader = header.decreaseFirstVisibleValue();
        return header.firstVisibleValue !== prevHeader.firstVisibleValue;
    };

    canScrollToNext = () => {
        const header = this.getHeader();
        if (!header) {
            return false;
        }
        const nextHeader = header.increaseFirstVisibleValue();
        return header.firstVisibleValue !== nextHeader.firstVisibleValue;
    };

    isDateSelectEnabled = () => this.props.showDateSelectButton && !this.props.calendar.isCluster();

    render() {
        const classNames = ["navButtonContainer"];
        let styleObject: React.CSSProperties = {};
        if (this.props.position) {
            styleObject = {
                top: this.props.position.y,
                left: this.props.position.x,
            };
        } else {
            classNames.push(this.props.className);
        }

        classNames.push(this.props.isXAxis === true ? "xAxis" : "yAxis");
        return (
            <div style={styleObject} className={classNames.join(" ")}>
                {this._renderDateSelect()}
                <div className="inner">
                    {this._renderSelectionSize()}
                    {this._renderTimeText(this.state)}
                    {this._renderConflictModeButton()}
                    <button
                        className="previous"
                        disabled={!this.canScrollToPrevious()}
                        onClick={this.previous}
                    />
                    <button
                        className="next"
                        disabled={!this.canScrollToNext()}
                        onClick={this.next}
                    />
                    {this._renderDateSelectButton()}
                    {this._renderCommandBarButton()}
                </div>
            </div>
        );
    }

    _renderSelectionSize = () => {
        // eslint-disable-next-line no-magic-numbers
        if (!this.props.selectionSize || this.props.selectionSize < 2) {
            return null;
        }
        return (
            <div className="sumDisplay" title={this.props.selectionSize}>
                {this.props.selectionSize}
            </div>
        );
    };

    _renderTimeText = (state) => {
        if (!state.cursorTime) {
            return null;
        }

        const makeTwoDigit = (value) => {
            // eslint-disable-next-line no-magic-numbers
            if (value < 10) {
                return `0${value}`;
            }
            return value;
        };
        const minutes = makeTwoDigit(state.cursorTime.getMinutes());
        const hours = makeTwoDigit(state.cursorTime.getHours());
        const timeText = `${hours}:${minutes}`;
        return (
            <span className="timeDisplay" title={timeText}>
                {timeText}
            </span>
        );
    };

    _renderDateSelect = () => {
        const dateHeader = this.props.calendar.getHeaderTypeMap().date;
        const hideWeekends = dateHeader && dateHeader.daysPerWeek !== TimeConstants.DAYS_PER_WEEK;

        if (!this.props.showDateSelect) {
            return null;
        }

        return (
            <DateSelect
                onChange={this.gotoDateTime}
                onClose={this.toggleCalendar}
                startDate={this.props.calendar.limits.getStartDate()}
                endDate={this.props.calendar.limits.getEndDate()}
                now={this.props.calendar.getFirstVisibleDate()}
                highlightDates={[MillenniumDate.today()]}
                hideWeekends={hideWeekends}
            />
        );
    };

    _renderDateSelectButton = () => {
        if (!this.props.showDateSelectButton) {
            return null;
        }
        return (
            <button
                className="calendarButton"
                disabled={!this.isDateSelectEnabled()}
                title={Language.get("menu_calendar")}
                onClick={this.toggleCalendar}
            />
        );
    };

    _renderCommandBarButton = () => {
        if (!this.props.toggleCommandBar) {
            return null;
        }

        return (
            <button
                className="showCommandBar"
                title={Language.get("nc_calendar_show_command_bar")}
                onClick={this.toggleCommandBar}
            />
        );
    };

    _renderConflictModeButton = () => {
        if (!this.props.showConflictModeButton) {
            return null;
        }

        const classNames = ["conflictModeButton"];
        if (this.props.isConflictMode) classNames.push("active");

        return (
            <button
                className={classNames.join(" ")}
                title={Language.get("nc_calendar_toggle_conflict_mode")}
                onClick={this.toggleConflictMode}
            />
        );
    };
}

module.exports = ToolButtons;
