const Model = require("./Model");
const API = require("../lib/TimeEditAPI");
const Menu = require("./Menu");
const _ = require("underscore");
const SummaryWindow = require("../components/SummaryWindow");

const DEFAULT_FONT_SIZE = 10;
const WEIGHT = 0.3;

const getActualTimezone = function (timezone, serverTimezones) {
    console.log(timezone, serverTimezones);
    let actualTimezone = serverTimezones.find((tz) => tz.shortName === timezone);
    if (!actualTimezone) {
        return serverTimezones[0];
    }
    return actualTimezone;
};

const User = function (user, serverTimezones) {
    Model.call(this, "User");
    // User info
    this.login = user.login;
    this.firstName = user.firstName || "";
    this.lastName = user.lastName || "";
    this.email = user.email || "";

    // Settings
    this.theme = "";
    this.language = user.language || "";
    this.dateFormat = user.dateFormat || "";
    this.reservationStatus = user.reservationStatus;
    this.isExternalUser = user.isExternalUser || false;
    this.emailStatus = user.emailStatus;
    this.userId = user.userId || null;
    this.isAdmin = user.isAdmin || false;
    this.isSuper = user.isSuper || false;
    this.timezone = user.timezone || null;
    this.actualTimezone = getActualTimezone(this.timezone, serverTimezones);
    this.displayTimezones = user.displayTimezones || [];

    this.showConfirmEntryRemovalWarning =
        user.showConfirmEntryRemovalWarning !== undefined
            ? user.showConfirmEntryRemovalWarning
            : true;
    this.mute = user.mute || false;
    this.allowSingleClickReservation = user.allowSingleClickReservation || false;
    // Temporary activation of single click mode, should never be saved
    this.allowTemporarySingleClickReservation = user.allowTemporarySingleClickReservation || false;
    this.jumpToNextType = user.jumpToNextType !== undefined ? user.jumpToNextType : true;
    this.menuPosition = user.menuPosition !== undefined ? user.menuPosition : Menu.POSITION.RIGHT;
    this.belowbarWeights = user.belowbarWeights || User.DEFAULT_WEIGHTS;
    this.preferredInfoSection = user.preferredInfoSection || User.PreferredSection.RESERVATION_INFO;
    if (!Array.isArray(this.preferredInfoSection)) {
        this.preferredInfoSection = [this.preferredInfoSection];
    }
    this.showLists = user.showLists !== undefined ? user.showLists : true;
    this.useInfoPopover = false;
    this.hasChosenReservationInfoPosition = true;
    this.colorFieldId = 79;
    this.reservationSummaryUnit = SummaryWindow.HOURS;
    this.fontSize = user.fontSize || DEFAULT_FONT_SIZE;
    this.showExtraInfo = user.showExtraInfo || false;
    this.tooltipInSidebar = user.tooltipInSidebar || false;
    this.useCustomWeekNames = user.useCustomWeekNames || false; // TODO The server will have a default value, should be used instead of false, right?
    this.fieldsInSelection = user.fieldsInSelection || false;
    this.fieldsNextToSelection = user.fieldsNextToSelection || false;
    this.viewerPageCurrent = user.viewerPageCurrent || "";
    this.biggerFont = user.biggerFont || false;
    this.enableMultiSelect = user.enableMultiSelect || false;
    this.createGroupsForReservationTracks =
        user.createGroupsForReservationTracks !== undefined
            ? user.createGroupsForReservationTracks
            : true;
    this.promptForGroupName =
        user.promptForGroupName !== undefined ? user.promptForGroupName : true;
    this.recentlyUsedState = user.recentlyUsedState || {};
};

User.DEFAULT_WEIGHTS = [WEIGHT, WEIGHT];
User.PreferredSection = {
    RESERVATION_INFO: "reservation",
    OBJECT_INFO: "object",
    HISTORY: "history",
};

User.prototype = Object.create(Model.prototype);

User.prototype.allowsSingleClickReservation = function () {
    return this.allowSingleClickReservation || this.allowTemporarySingleClickReservation;
};

User.prototype.setBelowbarWeights = function (weights) {
    API.setPreferences(
        "belowbarWeights",
        [JSON.stringify({ objectWeight: weights[0], typeWeight: weights[1] })],
        _.noop
    );
    return this.immutableSet({ belowbarWeights: weights });
};

User.prototype.setPreferredInfoSection = function (section = []) {
    API.setPreferences("preferredInfoSection", [JSON.stringify(section)], _.noop);
    return this.immutableSet({ preferredInfoSection: section });
};

User.prototype.setMenuPosition = function (position) {
    API.setPreferences("menuPosition", [position], _.noop);
    return this.immutableSet({ menuPosition: position });
};

User.prototype.setReservationSummaryUnit = function (unit) {
    API.setPreferences("reservationSummaryUnit", [unit], _.noop);
    return this.immutableSet({ reservationSummaryUnit: unit });
};

User.prototype.setFontSize = function (newSize) {
    return this.immutableSet({ fontSize: newSize });
};

User.prototype.getColorFieldId = function () {
    return this.colorFieldId;
};

// Returns true, false or undefined. Undefined means "use default behavior".
User.prototype.isRecentlyUsedChecked = function (typeId) {
    return this.recentlyUsedState[typeId];
};

User.prototype.setRecentlyUsedChecked = function (typeId, checked) {
    const newState = _.extend({}, this.recentlyUsedState, { [typeId]: checked });
    API.setPreferences("recentlyUsedState", [JSON.stringify(newState)], _.noop);
    return this.immutableSet({ recentlyUsedState: newState });
};

User.prototype.clearRecentlyUsedChecks = function () {
    API.setPreferences("recentlyUsedState", [JSON.stringify({})], _.noop);
    return this.immutableSet({ recentlyUsedState: {} });
};

User.prototype.save = function (cb) {
    _.runAsync(
        [
            (done) =>
                API.setPreferences(
                    "allowSingleClickReservation",
                    [this.allowSingleClickReservation],
                    done
                ),
            (done) => API.setPreferences("jumpToNextType", [this.jumpToNextType], done),
            (done) => API.setPreferences("mute", [this.mute], done),
            (done) =>
                API.setPreferences(
                    "dismissedModalDialogs.confirm_entry_removal",
                    [this.showConfirmEntryRemovalWarning],
                    done
                ),
            (done) => API.setPreferences("colorFieldId", [this.colorFieldId], done),
            (done) => API.setPreferences("fontSize", [this.fontSize], done),
            (done) => API.setPreferences("showExtraInfo", [this.showExtraInfo], done),
            (done) => API.setPreferences("showLists", [this.showLists], done),
            (done) => API.setPreferences("tooltipInSidebar", [this.tooltipInSidebar], done),
            (done) => API.setPreferences("fieldsInSelection", [this.fieldsInSelection], done),
            (done) => API.setPreferences("viewerPageCurrent", [this.viewerPageCurrent], done),
            (done) => API.setPreferences("biggerFont", [this.biggerFont], done),
            (done) => API.setPreferences("enableMultiSelect", [this.enableMultiSelect], done),
            (done) =>
                API.setPreferences("fieldsNextToSelection", [this.fieldsNextToSelection], done),
            (done) =>
                API.setPreferences(
                    "createGroupsForReservationTracks",
                    [this.createGroupsForReservationTracks],
                    done
                ),
            (done) => API.setPreferences("promptForGroupName", [this.promptForGroupName], done),
        ],
        () => API.setCurrentUserPrefs(this, cb)
    );
};

User.getCurrent = function (cb) {
    let user;
    const otherPrefs = {};
    _.runAsync(
        [
            (done) =>
                API.getTimezones((timezones) => {
                    API.getCurrentUserPrefs((userData) => {
                        user = new User(userData, timezones);
                        API.getAuthenticatedUserInfo((userInformation) => {
                            let userInfo = userInformation;
                            userInfo = userInfo.parameters[0];
                            user.userId = userInfo.id;
                            user.isAdmin = userInfo.client_admin || false;
                            user.isSuper = userInfo.client_super || false;
                            user.server = userInfo.server || null;
                            user.database = userInfo.database || null;
                            done();
                        });
                    });
                }),
            (done) =>
                API.getOtherUserPrefs((morePrefs) => {
                    Object.keys(morePrefs).forEach((key) => {
                        otherPrefs[key] = morePrefs[key];
                    });
                    done();
                }),
        ],
        () => cb(_.extend(user, otherPrefs))
    );
};

module.exports = User;
