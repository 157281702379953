const Model = require("./Model");
const TemplateKind = require("./TemplateKind");
const Language = require("../lib/Language");
const Selection = require("./Selection");
import { MillenniumDateTime } from "millennium-time";
const Limits = require("./Limits");
const Settings = require("./Settings");

const DEFAULT_LIST_ID = 3;

const WaitingListSearch = function (limits = Limits.getDefaultList()) {
    Model.call(this, "WaitingListSearch");
    this.templateKind = TemplateKind.RESERVATION;
    this.privateSelected = true;
    this.selection = new Selection();
    this.limits = limits;
    this.listId = DEFAULT_LIST_ID;
};

WaitingListSearch.prototype = Object.create(Model.prototype);

WaitingListSearch.prototype.getSettings = function (publicSelection) {
    const self = this;
    const settingsArray = [
        {
            id: "isPrivate",
            label: Language.get("cal_header_topleft_ignore_selected"),
            type: "boolean",
            get() {
                return self.privateSelected;
            },
            set(isPrivate) {
                if (isPrivate === self.privateSelected) {
                    return self;
                }

                const selection = isPrivate ? publicSelection.mutableCopy() : null;

                return self.immutableSet({
                    privateSelected: isPrivate,
                    selection,
                });
            },
        },
    ];
    return new Settings(settingsArray);
};

WaitingListSearch.prototype.getMacroFunctions = function () {
    const macros = [];
    return macros;
};

WaitingListSearch.prototype.setLimits = function (limits) {
    let newLimits = limits;
    if (!(limits instanceof Limits)) {
        newLimits = new Limits(limits);
    }

    return this.immutableSet({
        limits: newLimits,
    });
};

WaitingListSearch.prototype.updateTimeRange = function (newBeginTime, newEndTime) {
    let limits = new Limits(this.limits);
    limits = limits.setStartDayFromDate(new MillenniumDateTime(newBeginTime).getMillenniumDate());
    limits = limits.setDayCountFromDate(new MillenniumDateTime(newEndTime).getMillenniumDate());
    return this.immutableSet({
        limits,
    });
};

// What about the search settings object and such? How to even get hold of it from here?
WaitingListSearch.prototype.toJSON = function () {
    return {
        limits: this.limits,
        privateSelectedList: this.privateSelected,
        function: this.getMacroFunctions(),
        templateKind: this.templateKind.mode,
        isList: false,
        isWaitingList: true,
        listId: this.listId,
    };
};

module.exports = WaitingListSearch;
