const PropTypes = require("prop-types");
const React = require("react");
const createReactClass = require("create-react-class");
const ReactDOM = require("react-dom");
const Mousetrap = require("mousetrap");
import { MillenniumDate, MillenniumDateTime, SimpleDateFormat } from "millennium-time";
const Language = require("../lib/Language");
const API = require("../lib/TimeEditAPI");
const _ = require("underscore");
const Log = require("../lib/Log");
const Reservation = require("../models/Reservation");
const Macros = require("../models/Macros");

const CommandBar = createReactClass({
    displayName: "CommandBar",

    contextTypes: {
        fireEvent: PropTypes.func,
        update: PropTypes.func,
    },

    componentDidMount() {
        this._isMounted = true;
        ReactDOM.findDOMNode(this.refs.commandInput).focus();
        Mousetrap.bindWithHelp(
            "enter",
            this.execute,
            undefined,
            Language.get("nc_command_bar_execute_command")
        );
        if (this.props.close) {
            this._escBinding = Mousetrap.unbindWithHelp("esc", true)[0];
            Mousetrap.bindWithHelp("esc", this.props.close);
        }
    },

    componentWillUnmount() {
        this._isMounted = false;
        Mousetrap.unbindWithHelp("enter");
        if (this._escBinding) {
            Mousetrap.bindWithHelp("esc", this._escBinding);
        }
    },

    getInitialState() {
        return {
            validInput: false,
            parseMessage: Language.get("nc_command_bar_help_text."),
            command: "",
            helpVisible: false,
        };
    },

    _defaultParseResult: {
        validInput: false,
        parseMessage: "",
        executor: null,
    },

    parseToday(input, callback) {
        if (this.isToday(input) && this.props.activeCalendar) {
            return callback({
                validInput: true,
                parseMessage: Language.get("nc_calendar_go_to_today."),
                executor: function () {
                    this.context.update(
                        this.props.activeCalendar,
                        this.props.activeCalendar.gotoDateTime(MillenniumDate.today())
                    );
                }.bind(this),
            });
        }
        return callback(this._defaultParseResult);
    },

    parseReservationInput(input, callback) {
        if (this.isReservationInput(input) && this.props.getFluffy) {
            const reservationCommand = input.split(" ");
            let reservationDate = reservationCommand[1];
            // eslint-disable-next-line no-magic-numbers
            if (reservationDate.length === 6) {
                reservationDate = `20${reservationDate}`;
            }
            const times = reservationCommand[2].split("-");
            const currentFluffy = this.props.getFluffy();
            return callback({
                validInput: true,
                parseMessage: `Boka ${reservationDate} från ${times[0]} till ${times[1]}.`,
                executor() {
                    const fluffy = currentFluffy.toJson();
                    const beginTime = MillenniumDateTime.fromYyyyMMddHHmmssString(
                        `${reservationDate + times[0]}0000`
                    );
                    const endTime = MillenniumDateTime.fromYyyyMMddHHmmssString(
                        `${reservationDate + times[1]}0000`
                    );
                    fluffy.begin_time = [beginTime.getMts()];
                    fluffy.end_time = [endTime.getMts()];
                    API.reserveMcFluffy(fluffy, [], function (result) {
                        const res = result.parameters[0];
                        if (res.details) {
                            Log.warning(res.details);
                        } else {
                            Reservation.get([res.reference], (reservations) => {
                                this.context.update(
                                    this.props.activeCalendar,
                                    this.props.activeCalendar.gotoReservation(reservations[0])
                                );
                                this.props.selection.setReservation(
                                    res.reference,
                                    (newSelection) => {
                                        this.context.update(this.props.selection, newSelection);
                                    }
                                );
                            });
                        }
                    });
                },
            });
        }
        return callback(this._defaultParseResult);
    },

    parseColumns(input, callback) {
        const firstToken = input[0];
        if (firstToken === "c") {
            // Set visible cells on (outermost) column header
            const cCells = parseInt(input.substr(1), 10);
            if (!isNaN(cCells)) {
                callback({
                    validInput: true,
                    parseMessage: Language.get("nc_calendar_show_x_columns.", cCells),
                    executor: function () {
                        this.context.update(
                            this.props.activeCalendar.xHeader,
                            this.props.activeCalendar.xHeader.setVisibleValues(cCells)
                        );
                    }.bind(this),
                });
                return;
            }
        }
        callback(this._defaultParseResult);
    },

    parseFindUsers(input, callback) {
        if (input.toLowerCase().indexOf("findusers") === 0) {
            const inputSplit = input.toLowerCase().split(" ");
            const searchTerm = inputSplit[1];
            const authServer = inputSplit.length > 1 ? inputSplit[2] : null;
            callback({
                validInput: true,
                parseMessage: `Sök användare vars användarnamn börjar på "${searchTerm}"`,
                executor() {
                    API.findUsers({ loginName: searchTerm, authServer }, (result) => {
                        // eslint-disable-next-line no-console
                        console.log(`${result.parameters[1]} users found`);
                        // eslint-disable-next-line no-console
                        console.log(result.parameters[0].map((user) => `${user.id} ${user.extid}`));
                    });
                },
            });
            return;
        }
        callback(this._defaultParseResult);
    },

    parseGetUsers(ipt, callback) {
        let input = ipt.toLowerCase();
        if (input.indexOf("getusers") === 0) {
            input = input.replace(",", " ");
            input = input.replace("  ", " ");
            const inputSplit = input.split(" ");
            const ids = inputSplit.splice(1).map((id) => parseInt(id, 10));
            if (ids.length > 0) {
                callback({
                    validInput: true,
                    parseMessage: "Hämta detaljer om användare",
                    executor() {
                        API.exportUsers(ids, (result) => {
                            // eslint-disable-next-line no-console
                            console.log(result);
                        });
                    },
                });
            }
        }
        callback(this._defaultParseResult);
    },

    parseRows(input, callback) {
        const firstToken = input[0];
        if (firstToken === "r") {
            // Set visible cells on (outermost) row header
            const rCells = parseInt(input.substr(1), 10);
            if (!isNaN(rCells)) {
                return callback({
                    validInput: true,
                    parseMessage: Language.get("nc_calendar_show_x_rows.", rCells),
                    executor: function () {
                        this.context.update(
                            this.props.activeCalendar.yHeader,
                            this.props.activeCalendar.yHeader.setVisibleValues(rCells)
                        );
                    }.bind(this),
                });
            }
        }
        return callback(this._defaultParseResult);
    },

    parseCalendarMovement(ipt, callback) {
        const input = ipt.toLowerCase().trim();
        const regex = /^(\+|-)(\d+)([^\d]+)?$/;

        if (!regex.test(input)) {
            callback(this._defaultParseResult);
            return;
        }

        const matches = input.match(regex);
        const operator = matches[1];
        let steps = parseInt(matches[2], 10);
        if (operator === "-") {
            steps = 0 - steps;
        }

        const getUnitData = (unit) => {
            if (unit === Language.get("nc_one_letter_week")) {
                return {
                    modify: (date) => date.addWeeks(steps),
                    message: "nc_move_calendar_x_weeks.",
                };
            }

            if (unit === Language.get("nc_one_letter_month")) {
                return {
                    modify: (date) => date.addMonths(steps),
                    message: "nc_move_calendar_x_months.",
                };
            }

            if (unit === Language.get("nc_one_letter_year")) {
                return {
                    modify: (date) => date.addYears(steps),
                    message: "nc_move_calendar_x_years.",
                };
            }

            return {
                modify: (date) => date.addDays(steps),
                message: "nc_move_calendar_x_days.",
            };
        };

        const unit = getUnitData(matches[3]);
        const currentDate = this.props.activeCalendar.getFirstVisibleDate();

        callback({
            validInput: true,
            parseMessage: Language.get(unit.message, steps),
            executor: () => {
                this.context.update(
                    this.props.activeCalendar,
                    this.props.activeCalendar.gotoDateTime(unit.modify(currentDate))
                );
            },
        });
    },

    parseGoToReservation(input, callback) {
        const firstToken = input[0];
        if (firstToken === "#") {
            const reservationId = parseInt(input.substr(1), 10);
            if (!isNaN(reservationId)) {
                return callback({
                    validInput: true,
                    parseMessage: Language.get("nc_calendar_go_to_reservation."),
                    executor: function () {
                        Reservation.get([reservationId], (reservations) => {
                            if (reservations.length === 0) {
                                Log.error(Language.get("cal_table_res_not_found"));
                                return;
                            }
                            this.context.fireEvent(
                                `reservationList${this.props.id}`,
                                Macros.Event.SELECT_RESERVATION,
                                reservations[0]
                            );
                            this.props.onInfoOpen([reservationId], false);
                        });
                    }.bind(this),
                });
            }
        }
        return callback(this._defaultParseResult);
    },

    parsegotoDateTime(input, callback) {
        this.getDateFromString(input, (date) => {
            if (date !== null && date.getDayNumber() > 0) {
                return callback({
                    validInput: true,
                    parseMessage: Language.get(
                        "nc_calendar_go_to_date.",
                        SimpleDateFormat.format(date, Language.getDateFormat("date_f_yyyy_mm_dd"))
                    ),
                    executor: function () {
                        this.context.update(
                            this.props.activeCalendar,
                            this.props.activeCalendar.gotoDateTime(date)
                        );
                    }.bind(this),
                });
            }
            return callback(this._defaultParseResult);
        });
    },

    // Order matters, when parsing the first matching command will be used.
    getCommands() {
        const date = MillenniumDate.today();
        const commands = [
            {
                example: ".",
                description: Language.get("nc_calendar_go_to_today."),
                parse: this.parseToday,
            },
            {
                example: "c5",
                // eslint-disable-next-line no-magic-numbers
                description: Language.get("nc_calendar_show_x_columns.", 5),
                parse: this.parseColumns,
            },
            {
                example: "r5",
                // eslint-disable-next-line no-magic-numbers
                description: Language.get("nc_calendar_show_x_rows.", 5),
                parse: this.parseRows,
            },
            {
                example: `+5${Language.get("nc_one_letter_day")}, -1${Language.get(
                    "nc_one_letter_week"
                )}, +3${Language.get("nc_one_letter_month")}, -1${Language.get(
                    "nc_one_letter_year"
                )}`,
                description: Language.get("nc_command_bar_calendar_movement_description."),
                parse: this.parseCalendarMovement,
            },
            {
                example: "#67793",
                description: Language.get("nc_calendar_go_to_reservation."),
                parse: this.parseGoToReservation,
            },
        ];
        if (process.env.NODE_ENV === "development") {
            commands.push({
                example: "b or r 20150501 10-12",
                description: "Boka aktuell valdalista 2015-05-01 10:00 till 12:00.",
                parse: this.parseReservationInput,
            });
            commands.push({
                example: "findusers adm",
                description:
                    "Sök användare vars användarnamn börjar med 'adm' (resultat i konsollen)",
                parse: this.parseFindUsers,
            });
            commands.push({
                example: "getUsers 1, 5528",
                description: "Hämta detaljer om användare med ID 1 och 5526 (resultat i konsollen)",
                parse: this.parseGetUsers,
            });
        }
        commands.push({
            example: SimpleDateFormat.format(date, Language.getDateFormat("date_f_yy_mm_dd")),
            description: Language.get(
                "nc_calendar_go_to_date.",
                SimpleDateFormat.format(date, Language.getDateFormat("date_f_yyyy_mm_dd"))
            ),
            parse: this.parsegotoDateTime,
        });
        commands.push({
            example: SimpleDateFormat.format(date, Language.getDateFormat("date_f_yyyy_ww")),
            description: Language.get(
                "nc_calendar_go_to_week.",
                SimpleDateFormat.format(date, "ww"),
                SimpleDateFormat.format(date, "yyyy")
            ),
            parse: this.parsegotoDateTime,
        });

        return commands;
    },

    _parseTimer: null,

    setCommandInput(event) {
        const text = event.target.value;
        if (text === "<") {
            this.props.close();
        } else {
            this.setState({ command: text });
        }
        clearTimeout(this._parseTimer);
        // eslint-disable-next-line no-magic-numbers
        this._parseTimer = setTimeout(this.parse, 1000);
    },

    isToday(input) {
        return input === "." || input === "t" || input === "n";
    },

    getDateFromString(input, callback) {
        if (!input || input.length === 0) {
            callback(null);
            return;
        }
        API.dayNumberFromString(input, (result) => {
            callback(new MillenniumDate(result[0]));
        });
    },

    // eslint-disable-next-line no-unused-vars
    isReservationInput(input) {
        return false;
    },

    parseCommand(input, command, commands, callback) {
        // eslint-disable-next-line consistent-return
        command.parse(input, (result) => {
            if (result.validInput) {
                if (this._isMounted) {
                    this.setState({
                        validInput: result.validInput,
                        parseMessage: result.parseMessage,
                    });
                }
                return callback({
                    valid: result.validInput,
                    parseMessage: result.parseMessage,
                    executor: result.executor,
                });
            }
            if (commands.length > 0) {
                // eslint-disable-next-line no-param-reassign
                command = commands.pop();
                this.parseCommand(input, command, commands, callback);
            } else {
                if (!result.validInput && result.parseMessage === "") {
                    // eslint-disable-next-line no-param-reassign
                    result.parseMessage = Language.get(
                        "nc_command_bar_parser_did_not_understand_input."
                    );
                }
                if (this._isMounted) {
                    this.setState({
                        validInput: result.validInput,
                        parseMessage: result.parseMessage,
                    });
                }
                return callback({
                    valid: result.validInput,
                    parseMessage: result.parseMessage,
                    executor: result.executor,
                });
            }
        });
    },

    parse(callback = _.noop) {
        let input = this.state.command || "";
        input = input.trim();
        const commands = this.getCommands().reverse();
        const command = commands.pop();
        this.parseCommand(input, command, commands, callback);
    },

    execute() {
        this.parse((result) => {
            if (result.valid) {
                result.executor();
                if (this.props.close) {
                    if (this._escBinding) {
                        Mousetrap.bindWithHelp("esc", this._escBinding);
                        this._escBinding = null;
                    }
                    this.props.close();
                }
            }
        });
    },

    toggleHelp() {
        this.setState({ helpVisible: !this.state.helpVisible });
    },

    backgroundClick(event) {
        if (event.target === document.getElementById("commandBarClickCatcher")) {
            this.props.close();
        }
    },

    render() {
        let help = null;
        if (this.state.helpVisible) {
            help = (
                <table className="helpSection">
                    <tbody>
                        {this.getCommands().map((command) => (
                            <tr key={command.example}>
                                <td>{command.example}</td>
                                <td>{command.description} </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            );
        }
        return (
            <div
                id="commandBarClickCatcher"
                className="contextMenuClickCatcher"
                onClick={this.backgroundClick}
            >
                <div className="commandBar entryFadeIn">
                    <div className="inner" style={this.props.style}>
                        <table>
                            <tbody>
                                <tr>
                                    <td className="commandText">
                                        <input
                                            ref="commandInput"
                                            className="commandInput"
                                            type="text"
                                            onChange={this.setCommandInput}
                                            value={this.state.command}
                                        />
                                    </td>
                                    <td>
                                        <button
                                            className="helpButton"
                                            title="?"
                                            onClick={this.toggleHelp}
                                        />
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div>{this.state.parseMessage}</div>
                        {help}
                    </div>
                </div>
            </div>
        );
    },
});

module.exports = CommandBar;
