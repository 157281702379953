const API = require("../lib/TimeEditAPI");
const PropTypes = require("prop-types");
const React = require("react");
import { MillenniumDateTime, MillenniumTime, SimpleDateFormat } from "millennium-time";
const Accordion = require("./Accordion");
const Reservation = require("../models/Reservation");
const ConfirmButton = require("./ConfirmButton");
const _ = require("underscore");
const Language = require("../lib/Language");
const ReservationStatus = require("../lib/ReservationStatus");
const ReservationFieldEditor = require("./ReservationFieldEditor");
const FieldInput = require("./FieldInput");
const TagInput = require("./TagInput");
const User = require("../models/User");
const Log = require("../lib/Log");
const Mousetrap = require("mousetrap");
const ReservationConstants = require("../lib/ReservationConstants");
const TC = require("../lib/TimeConstants");
const Macros = require("../models/Macros");

const isOnDifferentLayer = (activeLayer, reservationLayer = 0) => activeLayer !== reservationLayer;

class ReservationInfo extends React.Component {
    static propTypes = {
        reservationIds: PropTypes.array.isRequired,
        onDelete: PropTypes.func.isRequired,
    };

    static contextTypes = {
        update: PropTypes.func,
        fireEvent: PropTypes.func,
        customWeekNames: PropTypes.array,
    };

    static defaultProps = {
        reservationIds: [],
        isEditMode: false,
    };

    state = {
        reservations: [],
        history: [],
        historyIndex: null,
        users: [],
        isCancellation: false,
        isCancellable: false,
        editedIds: this.props.isEditMode ? this.props.reservationIds : [],
        editableFields: [],
        fieldDefs: [],
        types: [],
        organizations: [],
    };

    componentDidMount() {
        this._isStateAvailable = true;
        if (this.props.dynamic !== true) {
            this._prevModE = Mousetrap.unbindWithHelp("mod+e", true);
            this._prevModI = Mousetrap.unbindWithHelp("mod+i", true);
            Mousetrap.bindWithHelp(
                "mod+e",
                () => this.enableEdit(),
                undefined,
                Language.get("nc_edit_reservation_info.")
            );
            Mousetrap.bindWithHelp(
                "mod+i",
                this.cancelEdit,
                undefined,
                Language.get("nc_cancel_edit.")
            );
        }

        const results = {};
        const calls = [
            (done) => {
                API.getOrganizations({}, (result) => {
                    results.organizations = result.parameters[0];
                    done();
                });
            },
            (done) => {
                API.findTypes((types) => {
                    results.types = types;
                    done();
                });
            },
            (done) => {
                API.findFields((fields) => {
                    API.getFieldDefs(
                        fields.map((field) => field.id),
                        (defs) => {
                            results.fields = fields;
                            results.fieldDefs = defs;
                            done();
                        },
                        false
                    );
                }, false);
            },
        ];

        _.runSync(calls, () => {
            this.setStateAsync(results);
        });

        this.load();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isEditMode && !this.props.isEditMode) {
            this.cancelEdit();
        }
        if (!prevProps.isEditMode && this.props.isEditMode && this.state.editedIds.length === 0) {
            this.enableEdit(this.props.reservationIds);
        }

        if (this.props.reservationIds === prevProps.reservationIds) {
            // Not deep equal, the ids will be the same but the array new if the reservation(s) have changed.
            return;
        }

        if (
            this.props.isEditMode &&
            prevProps.isEditMode &&
            !_.isEqual(this.props.reservationIds, this.state.editedIds)
        ) {
            this.enableEdit(this.props.reservationIds);
        }

        // eslint-disable-next-line react/no-did-update-set-state
        this.setState({ historyIndex: null, history: [] });

        this.load(this.props);
    }

    setStateAsync = (state) => {
        if (this._isStateAvailable) {
            return this.setState(state);
        }
        return this;
    };

    load = (props = this.props, callback = _.noop) => {
        if (this.props.setTitle) {
            this.props.setTitle(null);
        }

        if (props.reservationIds.length === 0) {
            return;
        }

        let userIds = [];
        let reservationList;
        _.runSync(
            [
                (done) => {
                    Reservation.export(
                        props.reservationIds,
                        this.getUser().showExtraInfo,
                        (foundReservations) => {
                            const reservations = foundReservations.filter((res) => res !== null);
                            if (reservations.length === 0) {
                                Reservation.getCancelled(props.reservationIds, (res) => {
                                    if (res.length === 0) {
                                        Log.warning(
                                            Language.get(
                                                "nc_error_no_reservation_found_with_id",
                                                props.reservationIds
                                            )
                                        );
                                        reservationList = [];
                                    } else {
                                        reservationList = res;
                                        userIds = userIds.concat(
                                            _.flatten(
                                                res.map((reservation) => [
                                                    reservation.createdby || 0,
                                                    reservation.modifiedby || 0,
                                                    reservation.cancelledby || 0,
                                                ])
                                            )
                                        );
                                        this.setStateAsync({
                                            reservations: res,
                                            isCancellation: true,
                                            isCancellable: false,
                                        });

                                        if (props.reservationIds.length === 1) {
                                            this.props.setTitle(
                                                `${Language.get(
                                                    "nc_reservation_info_cancellation"
                                                )} #${props.reservationIds[0]}`
                                            );
                                        } else {
                                            this.props.setTitle(
                                                Language.get("reservation_list_pref_name_cancel")
                                            );
                                        }
                                    }
                                    done();
                                });
                                return;
                            }

                            API.okToCancelReservations(
                                { reservationIds: props.reservationIds },
                                (res) => {
                                    this.setStateAsync({
                                        isCancellable: _.every(
                                            res.parameters[0],
                                            (status) => !status.details
                                        ),
                                    });
                                }
                            );

                            reservationList = reservations;
                            userIds = userIds.concat(
                                _.flatten(
                                    reservations.map((reservation) => [
                                        reservation.createdby ? reservation.createdby.id : 0,
                                        reservation.modifiedby ? reservation.modifiedby.id : 0,
                                    ])
                                )
                            );

                            const exceptionObjects = _.flatten(
                                reservations.map((res) => {
                                    if (res.memberExceptions) {
                                        return res.memberExceptions.map((me) => me.object.id);
                                    }
                                    return [];
                                })
                            );
                            API.getObjectNames(exceptionObjects, false, (objectNames) => {
                                this.setStateAsync({ exceptionObjectNames: objectNames });
                            });

                            this.setStateAsync({ reservations, isCancellation: false });
                            done();
                        }
                    );
                },
                (done) => {
                    if (props.reservationIds.length > 1) {
                        done();
                    } else {
                        Reservation.getHistory(props.reservationIds, (result) => {
                            const history = _.sortBy(result[0], (item) => -item.modified);
                            userIds = userIds.concat(_.pluck(history, "modifiedby"));

                            this.setStateAsync({ history });
                            done();
                        });
                    }
                },
            ],
            () => {
                this.getUserInfo(_.uniq(userIds), (users) => {
                    this.setStateAsync({ users });
                });

                API.getModifiableReservationFields(
                    _.pluck(reservationList, "id"),
                    (editableFields, hasSingleValueList) => {
                        const multipleValueFields = _.pluck(
                            editableFields.filter((field, index) => !hasSingleValueList[index]),
                            "id"
                        );
                        this.setStateAsync({ editableFields, multipleValueFields });
                        callback();
                    }
                );

                if (reservationList.length && reservationList[0].objects) {
                    // Info reservations have no objects
                    const reservationTypes = _.uniq(
                        reservationList[0].objects.map((obj) => obj.type.id)
                    );
                    const missingTypes = _.difference(
                        reservationTypes,
                        _.pluck(this.state.types, "id")
                    );
                    if (missingTypes.length > 0) {
                        API.getTypes(missingTypes, true, (types) => {
                            this.setStateAsync({ types: this.state.types.concat(types) });
                        });
                    }
                }
            }
        );
    };

    componentWillUnmount() {
        this._isStateAvailable = false;

        if (this.props.dynamic !== true) {
            Mousetrap.unbindWithHelp("mod+e");
            Mousetrap.unbindWithHelp("mod+i");

            if (this._prevModE) {
                Mousetrap.bindWithHelp(
                    "mod+e",
                    this._prevModE[0],
                    undefined,
                    Language.get("nc_edit_reservation_info.")
                );
            }
            if (this._prevModI) {
                Mousetrap.bindWithHelp(
                    "mod+i",
                    this._prevModI[0],
                    undefined,
                    Language.get("nc_cal_show_reservation_info.")
                );
            }
        }
    }

    getUser = () => this.props.user;

    update = (...params) => {
        // Docked panel has context, works. Undocked panel has props, doesn't work. So why does passing context.update as props fail?
        const fn = this.context.update || this.props.update;
        fn.apply(undefined, params);
    };

    getUserInfo = (users, callback = _.noop) => {
        API.getUsers(users, (foundUsers) => callback(foundUsers));
    };

    getFieldDef = (fieldId) =>
        _.find(this.state.fieldDefs, (field) => field.id === fieldId) || null;

    getNameFromId = (id, items) => {
        const item = _.find(items, (itm) => itm.id === id);
        return item ? item.name : id;
    };

    showHistory = (historyIndex) => {
        this.setState({ historyIndex });
    };

    clearDisplayedHistory = () => {
        this.setState({ historyIndex: null });
    };

    restoreHistory = (historyEvent, isCancellation, event) => {
        event.preventDefault();
        event.stopPropagation();

        const confirmString = isCancellation
            ? "nc_restore_cancellation_history_confirm"
            : "nc_restore_history_confirm";

        if (
            // eslint-disable-next-line no-alert
            !window.confirm(
                Language.get(
                    confirmString,
                    SimpleDateFormat.format(
                        new MillenniumDateTime(historyEvent.modified),
                        Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm")
                    )
                )
            )
        ) {
            return;
        }

        const resultString = isCancellation
            ? "nc_restore_cancellation_complete"
            : "nc_restore_reservation_complete";

        API.restoreReservationsFromHistory(
            [historyEvent.id],
            [historyEvent.modified],
            [historyEvent.modifiedby],
            false,
            (result) => {
                if (result[0].result) {
                    Log.error(result[0].details);
                } else {
                    Log.info(Language.get(resultString));
                    if (isCancellation) {
                        this.props.onEntryInfoOpen([result[0].reference]);
                        this.props.onUpdate([result[0].reference]);
                    } else {
                        this.props.onUpdate();
                        this.load();
                    }
                }
            }
        );
    };

    restoreCancellation = (ids, modified, event) => {
        event.preventDefault();
        event.stopPropagation();

        if (
            // eslint-disable-next-line no-alert
            !window.confirm(
                Language.get(
                    "nc_restore_cancellation_history_confirm",
                    SimpleDateFormat.format(
                        new MillenniumDateTime(modified),
                        Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm")
                    )
                )
            )
        ) {
            return;
        }

        API.restoreReservations(ids, true, (response) => {
            const errors = response.parameters[0]
                .map((status, i) => _.extend(status, { originalId: ids[i] }))
                .filter((status) => Boolean(status.details));

            const successes = response.parameters[0].filter((status) => !Boolean(status.details));

            if (errors.length === ids.length) {
                Log.error(errors[0].details);
            } else {
                this.context.fireEvent(
                    `reservationInfo`,
                    Macros.Event.RESERVATION_RESTORED,
                    successes[0].reference
                );
                Log.info(Language.get("nc_restore_cancellation_complete"));
                this.props.onEntryInfoOpen(successes.map((su) => su.reference));
                this.props.onUpdate(successes.map((su) => su.reference));
            }
        });
    };

    isLocked = () =>
        this.state.reservations.some(
            (reservation) => reservation.lock && reservation.lock === "soft"
        );

    enableEdit = (reservationIds = this.props.reservationIds) => {
        if (!this._isStateAvailable) {
            return;
        }
        const editedIds = _.asArray(reservationIds);
        if (this.isLocked()) {
            return;
        }

        if (
            !_.every(
                this.state.reservations,
                (res) => !isOnDifferentLayer(this.props.activeLayer, res.layer)
            )
        ) {
            return;
        }
        this.setState({ editedIds }, () => {
            if (!this.props.isEditMode) {
                this.props.onReservationEditChange(true);
            }
        });
    };

    cancelEdit = () => {
        if (!this._isStateAvailable) {
            return;
        }
        this.setState({ editedIds: [] }, () => {
            if (this.props.isEditMode) {
                this.props.onReservationEditChange(false);
            }
        });
        this.load();
    };

    getTime = (time) => {
        const formatLong = Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm");
        return SimpleDateFormat.format(new MillenniumDateTime(time), formatLong);
    };

    getShortTime = (time) => {
        const formatLong = Language.getDateFormat("date_f_yy_mm_dd_hh_mm");
        return SimpleDateFormat.format(new MillenniumDateTime(time), formatLong);
    };

    getUserLabel = (userId, users) => {
        const user = _.find(users, (usr) => usr.id === userId);
        if (!user) {
            return userId;
        }

        if (user.fname && user.lname) {
            const tooltip = user.extid ? user.extid : user.id;
            return <span alt={tooltip} title={tooltip}>{`${user.fname} ${user.lname}`}</span>;
        }
        return user.extid ? user.extid : user.id;
    };

    getUserTime = (time, userId, users) => {
        const userLabel = this.getUserLabel(userId, users);
        if (!userLabel) {
            return <span>{this.getTime(time)}</span>;
        }

        return (
            <span>
                {this.getTime(time)}
                <br />({this.getUserLabel(userId, users)})
            </span>
        );
    };

    getPreferredSections = () => {
        const sections = [];
        if (this.shouldRenderClusterTimeSection()) {
            sections.push(0);
        }

        const objectSection =
            this.state.reservations.length + (this.shouldRenderClusterTimeSection() ? 1 : 0);
        const infoSection = this.shouldRenderClusterTimeSection() ? 1 : 0;
        const historySection = objectSection + 1;
        const preferred = this.getUser().preferredInfoSection || [];
        if (preferred.indexOf(User.PreferredSection.OBJECT_INFO) !== -1) {
            sections.push(objectSection);
        }
        preferred.forEach((pref) => {
            if (pref.indexOf(User.PreferredSection.RESERVATION_INFO) !== -1) {
                if (pref === User.PreferredSection.RESERVATION_INFO) {
                    sections.push(infoSection); // Old case, "reservation" saved without index number.
                } else {
                    const infoIndex =
                        parseInt(
                            pref.substring(User.PreferredSection.RESERVATION_INFO.length),
                            10
                        ) + infoSection;
                    if (infoIndex < objectSection) {
                        sections.push(infoIndex);
                    }
                }
            }
        });
        if (preferred.indexOf(User.PreferredSection.HISTORY) !== -1) {
            sections.push(historySection);
        }

        return sections;
    };

    getClusterProperties = () => {
        if (this.hasUnrelatedReservations()) {
            return {
                time: false,
                date: false,
                week: false,
                weekday: false,
            };
        }
        const reservations = this.state.reservations.map((res) => ({
            begin: new MillenniumDateTime(res.begin || 0),
            end: new MillenniumDateTime(res.end || 0),
        }));

        const equalityFns = {
            time: (a, b) => a.getMillenniumTime().equals(b.getMillenniumTime()),
            date: (a, b) => a.getMillenniumDate().equals(b.getMillenniumDate()),
            week: (a, b) =>
                a.getWeek(true, Language.firstDayOfWeek, Language.daysInFirstWeek) ===
                b.getWeek(true, Language.firstDayOfWeek, Language.daysInFirstWeek),
            weekday: (a, b) => a.getDay() === b.getDay(),
        };

        const first = reservations[0];
        return Object.keys(equalityFns).reduce((result, prop) => {
            const eq = equalityFns[prop];
            // eslint-disable-next-line no-param-reassign
            result[prop] = _.every(
                reservations,
                (res) => eq(res.begin, first.begin) && eq(res.end, first.end)
            );
            return result;
        }, {});
    };

    getClusterSection = () => {
        const clusterProps = this.getClusterProperties();
        const begin = new MillenniumDateTime(this.state.reservations[0].begin);
        const end = new MillenniumDateTime(this.state.reservations[0].end);
        const toString = (format, isWeek = false) => {
            let a = SimpleDateFormat.format(begin, format);
            let b = SimpleDateFormat.format(end, format);
            const aName = Language.getCustomWeek(begin, this.context.customWeekNames);
            const bName = Language.getCustomWeek(end, this.context.customWeekNames);
            if (isWeek && this.context.customWeekNames.length > 0) {
                a = aName ? aName.getShortestName() : a;
                b = bName ? bName.getShortestName() : b;
            }
            if (a === b) {
                return a;
            }
            return `${a} - ${b}`;
        };

        const rows = [];
        if (clusterProps.time) {
            const bp = SimpleDateFormat.format(begin, Language.getDateFormat("date_f_hh_mm"));
            const ep = SimpleDateFormat.format(end, Language.getDateFormat("date_f_hh_mm_end"));
            rows.push(
                <tr key="time">
                    <th>{Language.get("cal_res_side_reservation_time")}</th>
                    <td>
                        {bp} - {ep}
                    </td>
                </tr>
            );
        }
        if (clusterProps.weekday && !clusterProps.date) {
            rows.push(
                <tr key="weekday">
                    <th>{Language.get("period_kind_week_day")}</th>
                    <td>{toString("EEEE")}</td>
                </tr>
            );
        }
        if (clusterProps.week && !clusterProps.date) {
            rows.push(
                <tr key="week">
                    <th>{Language.get("period_kind_week")}</th>
                    <td>{toString(Language.getDateFormat("date_i_yyyy_ww"), true)}</td>
                </tr>
            );
        }
        if (clusterProps.date) {
            rows.push(
                <tr key="date">
                    <th>{Language.get("period_kind_date")}</th>
                    <td>{toString(Language.getDateFormat("date_f_yyyy_mm_dd"))}</td>
                </tr>
            );
        }

        return {
            title: Language.get("cal_res_side_reservation_time"),

            content: (
                <table>
                    <tbody>{rows}</tbody>
                </table>
            ),
        };
    };

    onAccordionChange = (sections) => {
        const user = this.getUser();
        let objectSection = this.state.reservations.length;
        if (this.shouldRenderClusterTimeSection()) {
            objectSection = objectSection + 1;
        }
        const infoSection = this.shouldRenderClusterTimeSection() ? 1 : 0;
        const lastInfoSection = infoSection + this.state.reservations.length;
        let isInfoSectionOpen = false;
        const infoSections = [];
        _.range(infoSection, lastInfoSection).forEach((sectionIndex) => {
            if (_.contains(sections, sectionIndex)) {
                isInfoSectionOpen = true;
                infoSections.push(sectionIndex);
            }
        });
        const historySection = objectSection + 1;

        const isObjectSectionOpen = _.contains(sections, objectSection);
        const isHistorySectionOpen = _.contains(sections, historySection);

        const preferredSections = [];
        if (isInfoSectionOpen) {
            infoSections.forEach((index) =>
                preferredSections.push(
                    `${User.PreferredSection.RESERVATION_INFO}${index - infoSection}`
                )
            );
        }
        if (isObjectSectionOpen) {
            preferredSections.push(User.PreferredSection.OBJECT_INFO);
        }
        if (isHistorySectionOpen) {
            preferredSections.push(User.PreferredSection.HISTORY);
        }
        this.update(user, user.setPreferredInfoSection(preferredSections));

        if (this.props.onContentChange) {
            this.props.onContentChange();
        }
    };

    hasMultipleReservations = () => this.state.reservations.length > 1;

    hasUnrelatedReservations = () => this.hasMultipleReservations() && !this.props.isEntryInfo;

    shouldRenderClusterTimeSection = () =>
        this.hasMultipleReservations() && !this.hasUnrelatedReservations();

    getDisplayReservation = () => {
        if (this.state.historyIndex === null) {
            return this.state.reservations[0];
        }

        const applyHistory = (reservation, historyItem) => {
            const historyObjects = historyItem.objects
                ? [].concat(historyItem.objects)
                : reservation.objects;
            return _.extend({}, reservation, {
                begin: historyItem.begin,
                end: historyItem.end,
                status: [].concat(historyItem.status),
                modified: historyItem.modified,
                modifiedby: historyItem.modifiedby,
                objects: historyObjects,
                reservation_kinds: historyObjects.map(
                    () => ReservationConstants.RESERVATION_KIND.UNDEFINED
                ),
                fields: [],
            });
        };

        return this.state.history
            .filter((historyItem, index) => index <= this.state.historyIndex)
            .reduce(applyHistory, this.state.reservations[0]);
    };

    render() {
        if (
            !this.state ||
            this.state.reservations.length === 0 ||
            !this.state.fields ||
            !this.state.types
        ) {
            return null;
        }

        if (this.props.isEditMode) {
            return this._renderEditForm();
        }

        let sections = [];

        const displayReservation = this.getDisplayReservation();
        const clusterProps = this.getClusterProperties();
        // eslint-disable-next-line no-unused-vars
        this.state.reservations.forEach((reservation, index) => {
            if (displayReservation.id === reservation.id) {
                // eslint-disable-next-line no-param-reassign
                reservation = displayReservation;
            }
            let title = this.hasMultipleReservations()
                ? Language.get("cal_func_res_reservation")
                : Language.get("cal_res_side_reservation_info");
            if (this.state.isCancellation) {
                title = Language.get("nc_reservation_info_cancellation");
            }
            if (this.hasMultipleReservations()) {
                if (clusterProps.week && !clusterProps.date) {
                    title = `${title} (${SimpleDateFormat.format(
                        new MillenniumDateTime(reservation.begin),
                        "EEEE"
                    )})`;
                } else if (clusterProps.weekday && !clusterProps.date) {
                    let customName = null;
                    if (this.context.customWeekNames.length > 0) {
                        const customWeek = Language.getCustomWeek(
                            new MillenniumDateTime(reservation.begin),
                            this.context.customWeekNames
                        );
                        if (customWeek !== null) {
                            customName = customWeek.getLongestName();
                        }
                    }
                    if (customName) {
                        title = `${title} (${customName})`;
                    } else {
                        title = `${title} (${SimpleDateFormat.format(
                            new MillenniumDateTime(reservation.begin),
                            Language.getDateFormat("date_f_yyyy_ww")
                        )})`;
                    }
                } else {
                    title = `${title} #${reservation.id}`;
                }
            }

            if (displayReservation.objects && this.hasUnrelatedReservations()) {
                sections.push({
                    title,
                    content: this._renderReservationInfoAndObjects(reservation),
                });
            } else {
                sections.push({
                    title,
                    content: this._renderReservationInfo(reservation),
                    isMarked:
                        reservation.memberExceptions && reservation.memberExceptions.length > 0,
                });
            }
        });

        if (displayReservation.objects && !this.hasUnrelatedReservations()) {
            sections.push({
                title: Language.get("cal_res_side_reservation_objects"),
                content: this._renderObjects(displayReservation),
            });
        }

        if (this.state.history.length > 0) {
            const getClassName = (index) =>
                _.classSet({
                    selected: this.state.historyIndex === index,
                });

            const restoreButton = (historyEvent) => {
                if (isOnDifferentLayer(this.props.activeLayer, displayReservation.layer)) {
                    return null;
                }
                return (
                    <button
                        className="restoreReservationButton"
                        title={
                            this.state.isCancellation
                                ? Language.get("nc_restore_cancellation")
                                : Language.get("nc_restore_reservation")
                        }
                        onClick={this.restoreHistory.bind(
                            this,
                            historyEvent,
                            this.state.isCancellation
                        )}
                    >
                        &#xf0e2;
                    </button>
                );
            };

            const restoreCancellationButton = (reservationIds, modified) => {
                if (_.any(reservationIds, (id) => isOnDifferentLayer(this.props.activeLayer, id))) {
                    return null;
                }
                return (
                    <button
                        className="restoreReservationButton"
                        title={Language.get("nc_restore_cancellation")}
                        onClick={this.restoreCancellation.bind(this, reservationIds, modified)}
                    >
                        &#xf0e2;
                    </button>
                );
            };

            sections.push({
                title: Language.get("cal_res_side_reservation_history"),

                content: (
                    <table className="reservationHistory">
                        <tbody>
                            <tr className={getClassName(null)} onClick={this.clearDisplayedHistory}>
                                <th>{this.getShortTime(this.state.reservations[0].modified)}</th>
                                <td>
                                    {this.getUserLabel(
                                        this.state.reservations[0].modifiedby
                                            ? this.state.reservations[0].modifiedby.id ||
                                                  this.state.reservations[0].modifiedby
                                            : null,
                                        this.state.users
                                    )}
                                    {this.state.isCancellation
                                        ? restoreCancellationButton(
                                              [this.state.reservations[0].id],
                                              this.state.reservations[0].modified
                                          )
                                        : null}
                                </td>
                            </tr>
                            {this.state.history.map((historyEvent, index) => (
                                <tr
                                    key={index}
                                    className={getClassName(index)}
                                    onClick={this.showHistory.bind(this, index)}
                                >
                                    <th>{this.getShortTime(historyEvent.modified)}</th>
                                    <td>
                                        {this.getUserLabel(
                                            historyEvent.modifiedby,
                                            this.state.users
                                        )}
                                        {restoreButton(historyEvent)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                ),
            });
        }

        if (this.shouldRenderClusterTimeSection()) {
            sections = [this.getClusterSection()].concat(sections);
        }

        const buttons =
            this.props.dynamic === true ||
            _.every(this.state.reservations, (res) =>
                isOnDifferentLayer(this.props.activeLayer, res.layer)
            ) ? null : (
                <div className="btnGroup horizontal">
                    <ConfirmButton
                        className="remove"
                        onClick={this.props.onDelete}
                        confirmMessage={Language.get("nc_confirm_entry_removal_title")}
                        disabled={!this.state.isCancellable}
                    >
                        {this.hasMultipleReservations()
                            ? Language.get("nc_cal_func_res_delete_all")
                            : Language.get("cal_func_res_delete")}
                    </ConfirmButton>
                    <button
                        className="ok"
                        onClick={() => this.enableEdit()}
                        disabled={!this.isMultipleEditable() || this.isLocked()}
                    >
                        {this.hasMultipleReservations()
                            ? Language.get("nc_cal_func_res_edit_all")
                            : Language.get("nc_cal_func_res_edit")}
                    </button>
                </div>
            );

        const tags = (
            <table>
                <tbody>
                    <tr>
                        <th colSpan="2">{Language.get("nc_tags_title")}</th>
                    </tr>
                    <tr>
                        <td colSpan="2">
                            <TagInput
                                reservationIds={this.state.reservations.map((res) => res.id)}
                                tags={_.uniq(
                                    _.flatten(
                                        this.state.reservations.map((res) => res.tags)
                                    ).filter((val) => val !== undefined)
                                )}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        );

        return (
            <div className="reservationInfo">
                <Accordion
                    sections={sections}
                    onContentChange={this.onAccordionChange}
                    initialSection={this.getPreferredSections()}
                />
                {tags}
                {buttons}
            </div>
        );
    }

    _renderReservationInfoAndObjects = (reservation) => (
        <div>
            {this._renderReservationInfo(reservation)}
            {this._renderObjects(reservation)}
        </div>
    );

    _renderObjects = (reservation) => {
        const typeGroups = _.groupBy(reservation.objects, (object) => object.type.id);
        const typeSort = (typeId) => {
            const typeOrderReservation = this.hasUnrelatedReservations()
                ? reservation
                : this.state.reservations[0];
            const index = typeOrderReservation.objects.findIndex(
                (object) => object.type.id === parseInt(typeId, 10)
            );
            return index > -1 ? index : reservation.objects.length + typeId;
        };
        const typeIds = _.sortBy(Object.keys(typeGroups), typeSort);

        const kindInfo = (object) => {
            if (!reservation.reservation_kinds) {
                return null;
            }
            const knd = reservation.reservation_kinds[reservation.objects.indexOf(object)];
            return (
                <td title={ReservationConstants.toKindLabel(knd)}>
                    <b>{ReservationConstants.toShortKindLabel(knd)}</b>
                </td>
            );
        };

        return (
            <table>
                <tbody>
                    {_.flatten(
                        typeIds.map((typeId) =>
                            typeGroups[typeId].map((object, index) => {
                                const typeText =
                                    index === 0
                                        ? this.getNameFromId(object.type.id, this.state.types)
                                        : "";

                                return (
                                    <tr key={`${typeId}-${index}`}>
                                        <th>{typeText}</th>
                                        <td
                                            className="clickable"
                                            onClick={() => {
                                                if (this.props.onObjectInfo) {
                                                    this.props.onObjectInfo(object.id, false, true);
                                                }
                                            }}
                                        >
                                            {object.fields && object.fields[0].values
                                                ? object.fields[0].values[0]
                                                : "-"}{" "}
                                        </td>
                                        {kindInfo(object)}
                                    </tr>
                                );
                            })
                        )
                    )}
                </tbody>
            </table>
        );
    };

    isMultipleEditable = () => this.state.editableFields.length > 0;

    _renderEditForm = () => {
        const allOrgs = this.state.reservations.map((res) => (res.orgs || []).map((org) => org.id));
        const allEqual = _.every(allOrgs, (orgList) => _.isEqual(orgList, allOrgs[0]));
        return (
            <ReservationFieldEditor
                reservationIds={this.state.editedIds}
                fieldDefs={this.state.fieldDefs}
                onUpdate={(ids) => {
                    API.findFields((fields) => {
                        API.getFieldDefs(
                            fields.map((field) => field.id),
                            (defs) => {
                                this.setState({
                                    fields,
                                    fieldDefs: defs,
                                });
                            },
                            false
                        );
                    }, false);
                    this.props.onUpdate(ids);
                }}
                onClose={this.cancelEdit}
                organizations={
                    allEqual
                        ? this.state.reservations[0].orgs || []
                        : _.uniq(
                              _.flatten(this.state.reservations.map((res) => res.orgs || [])),
                              (org) => org.id
                          )
                }
                organizationsEnabled={allEqual}
            />
        );
    };

    isURL = (field) => {
        const def = this.getFieldDef(field.id);
        if (!def) {
            return false;
        }
        return def.kind === FieldInput.fieldKind.URL;
    };

    isComment = (field) => {
        const def = this.getFieldDef(field.id);
        if (!def) {
            return false;
        }
        return def.kind === FieldInput.fieldKind.COMMENT;
    };

    isBoolean = (field) => {
        const def = this.getFieldDef(field.id);
        if (!def) {
            return false;
        }
        return def.kind === FieldInput.fieldKind.BOOLEAN;
    };

    isLength = (field) => {
        const def = this.getFieldDef(field.id);
        if (!def) {
            return false;
        }
        return def.kind === FieldInput.fieldKind.LENGTH;
    };

    isMultiple = (field) => {
        const def = this.getFieldDef(field.id);
        if (!def) {
            return false;
        }
        return Boolean(def.multiple);
    };

    getOrgName(id, definitions) {
        const org = _.find(definitions, (def) => def.id === id) || null;
        if (org) {
            return org.extid;
        }
        return String(id);
    }

    getMemberObjectName(id, objectNames) {
        const member = _.find(objectNames, (on) => on.id === id) || null;
        if (member) {
            return member.fields[0].values.join(", ");
        }
        return String(id);
    }

    renderDuration(length) {
        if (length === 0 || isNaN(length)) {
            return "0:00";
        }
        const hours = Math.floor(length / TC.SECONDS_PER_HOUR);
        const minutes = Math.floor((length % TC.SECONDS_PER_HOUR) / TC.SECONDS_PER_MINUTE);
        // eslint-disable-next-line no-magic-numbers
        return `${hours}:${minutes < 10 ? `0${minutes}` : minutes}`;
    }

    _renderReservationInfo = (reservation) => {
        const startTime = reservation.begin ? new MillenniumDateTime(reservation.begin) : null;
        const endTime = reservation.end ? new MillenniumDateTime(reservation.end) : null;

        let reservationFields = null;
        if (reservation.fields) {
            reservationFields = reservation.fields.map(function (field, index) {
                const className = index === 0 ? "separator" : "";
                let fieldLine = field.values ? field.values : [];
                if (this.isURL(field)) {
                    // eslint-disable-next-line no-shadow
                    fieldLine = fieldLine.map((url, index) => (
                        <a
                            onClick={_.stopPropagation}
                            href={url.indexOf("://") > -1 ? url : `http://${url}`}
                            className="externalLink"
                            target="_blank"
                            key={index}
                        >
                            {url}
                        </a>
                    ));
                }
                const isComment = this.isComment(field);
                if (isComment) {
                    fieldLine = fieldLine.map((fl) => (
                        <textarea rows="5" className="fieldLine readonly" readOnly value={fl} />
                    ));
                }
                if (this.isBoolean(field)) {
                    if (fieldLine.length === 0) {
                        fieldLine = ["0"];
                    }
                    fieldLine = fieldLine.map((fl, idx) => (
                        <span
                            key={idx}
                            className={fl === "0" ? "chkField unchecked" : "chkField checked"}
                        />
                    ));
                }
                if (this.isLength(field)) {
                    fieldLine = fieldLine.map((fl, idx) => (
                        <span key={idx}>
                            {new MillenniumTime(parseInt(fl, 10)).format("HH:mm")}
                        </span>
                    ));
                }
                if (isComment) {
                    return (
                        <tr
                            key={index}
                            className={className}
                            onClick={this.enableEdit.bind(this, reservation.id)}
                        >
                            <th colSpan="2">
                                {this.getNameFromId(field.id, this.state.fields)}
                                {fieldLine.map((fl, idx) => (
                                    <p className="fieldLine" key={idx}>
                                        {fl}
                                    </p>
                                ))}
                            </th>
                        </tr>
                    );
                }
                return (
                    <tr
                        key={index}
                        className={className}
                        onClick={this.enableEdit.bind(this, reservation.id)}
                    >
                        <th>{this.getNameFromId(field.id, this.state.fields)}</th>
                        <td>
                            {this.isMultiple(field) ? (
                                fieldLine.map((item) => (
                                    <span className="multipleField">{item}</span>
                                ))
                            ) : (
                                <span className="singleField">{fieldLine}</span>
                            )}
                        </td>
                    </tr>
                );
            }, this);
        }

        let timeRows = this._renderTimeRows(
            startTime,
            endTime,
            reservation.timezones ? reservation.timezones[0].name : null
        );
        if (reservation.timezones && this.state.historyIndex === null) {
            const rowData = { startTimes: [], endTimes: [], names: [] };
            reservation.timezones.forEach((tz) => {
                rowData.startTimes.push(new MillenniumDateTime(tz.begin));
                rowData.endTimes.push(new MillenniumDateTime(tz.end));
                rowData.names.push(tz.name);
            });
            timeRows = this._renderMultiTimeRows(
                rowData.startTimes,
                rowData.endTimes,
                rowData.names
            );
        }
        const length = (
            <tr>
                <th>{Language.get("cal_res_side_reservation_length")}</th>
                <td>{this.renderDuration(reservation.length)}</td>
            </tr>
        );

        const timezone =
            reservation.timezone && reservation.timezones ? (
                <tr>
                    <th>{Language.get("cal_res_side_user_time_zone")}</th>
                    <td>{reservation.timezone}</td>
                </tr>
            ) : null;

        const template = reservation.template ? (
            <tr>
                <th>{Language.get("cal_res_side_reservation_template")}</th>
                <td>{reservation.template.name}</td>
            </tr>
        ) : null;

        const jobId = reservation.autoEngineId ? (
            <tr>
                <th>{Language.get("nc_auto_pilot_id")}</th>
                <td style={{ overflow: "hidden" }} title={reservation.autoEngineId}>
                    {reservation.autoEngineId}
                </td>
            </tr>
        ) : null;

        const locks =
            reservation.lock && reservation.lock === "soft" ? (
                <tr key="locks">
                    <th>{Language.get("nc_reservation_locked")}</th>
                    <td className="wrapText">{reservation.lockedDescription || ""}</td>
                </tr>
            ) : null;

        const groupId = reservation.group ? (
            <tr>
                <th>{Language.get("nc_reservation_group_list_title")}</th>
                <td
                    style={{ overflow: "hidden" }}
                    title={`${reservation.group.extid} (${reservation.group.id})`}
                >
                    {reservation.group.name
                        ? reservation.group.name
                        : reservation.group.extid
                        ? reservation.group.extid
                        : reservation.group.id}
                    {this.getUser().showExtraInfo
                        ? ` (${
                              reservation.group.extid
                                  ? reservation.group.extid
                                  : reservation.group.id
                          })`
                        : null}
                </td>
            </tr>
        ) : null;

        const organizationInfo = (
            <tr>
                <th>{Language.get("dynamic_object_info_orgs")}</th>
                <td>
                    {(reservation.orgs || [])
                        .map((org) => this.getOrgName(org.id, this.state.organizations))
                        .join(", ")}
                </td>
            </tr>
        );

        let extid = null;
        if (reservation.extid && this.getUser().showExtraInfo) {
            extid = (
                <tr>
                    <th>{Language.get("cal_order_side_extid")}</th>
                    <td>{reservation.extid}</td>
                </tr>
            );
        }

        let exceptions = [];
        if (reservation.memberExceptions && reservation.memberExceptions.length > 0) {
            exceptions.push(
                <tr className="separator" key="exceptionHeadline">
                    <th>{Language.get("nc_reservation_exception_title")}</th>
                    <td />
                </tr>
            );
            exceptions = exceptions.concat(
                reservation.memberExceptions.map((me) => (
                    <tr key={me.object.id}>
                        <td>{this.getNameFromId(me.type.id, this.state.types)}</td>
                        <td>
                            {this.getMemberObjectName(
                                me.object.id,
                                this.state.exceptionObjectNames
                            )}
                        </td>
                    </tr>
                ))
            );
        }

        return (
            <div>
                <table>
                    <tbody>
                        <tr>
                            <th>{Language.get("cal_res_side_reservation_id")}</th>
                            <td>{reservation.id}</td>
                        </tr>
                        {extid}
                        {length}
                        {timeRows}
                        {timezone}
                        <tr onClick={this.enableEdit.bind(this, reservation.id)}>
                            <th>{Language.get("cal_res_side_reservation_status")}</th>
                            <td>{this._renderStatus(reservation)}</td>
                        </tr>

                        <tr>
                            <th>{Language.get("cal_res_side_reservation_created")}</th>
                            <td>
                                {this.getUserTime(
                                    reservation.created,
                                    reservation.createdby
                                        ? reservation.createdby.id
                                            ? reservation.createdby.id
                                            : reservation.createdby
                                        : 0,
                                    this.state.users
                                )}
                            </td>
                        </tr>

                        <tr>
                            <th>{Language.get("cal_res_side_reservation_modified")}</th>
                            <td>
                                {this.getUserTime(
                                    reservation.modified,
                                    reservation.modifiedby
                                        ? reservation.modifiedby.id
                                            ? reservation.modifiedby.id
                                            : reservation.modifiedby
                                        : 0,
                                    this.state.users
                                )}
                            </td>
                        </tr>

                        {this.state.isCancellation ? (
                            <tr>
                                <th>{Language.get("cal_reservation_list_column_cancelled")}</th>
                                <td>
                                    {this.getUserTime(
                                        reservation.cancelled,
                                        reservation.cancelledby,
                                        this.state.users
                                    )}
                                </td>
                            </tr>
                        ) : null}
                        {groupId}
                        {jobId}
                        {template}
                        {organizationInfo}
                        {locks}
                        {reservationFields}
                        {exceptions}
                    </tbody>
                </table>
            </div>
        );
    };

    _renderStatus = (reservation) => {
        const statuses = (reservation.status || [])
            .map((status) => ReservationStatus.statusForId(status.status))
            .filter(
                (status) =>
                    status !== ReservationStatus.B_COMPLETE &&
                    status !== ReservationStatus.C_CONFIRMED &&
                    status !== ReservationStatus.D_TIME
            );

        if (statuses.length === 0) {
            return (
                <ul>
                    {this.state.isCancellation ? (
                        <li>{Language.get("nc_reservation_info_status_cancelled")}</li>
                    ) : null}
                    <li>{Language.get("dynamic_reserv_list_complete")}</li>
                </ul>
            );
        }

        return (
            <ul>
                {this.state.isCancellation ? (
                    <li>{Language.get("nc_reservation_info_status_cancelled")}</li>
                ) : null}
                {statuses.map((status, index) => (
                    <li key={index}>{status.getStatusName(false)}</li>
                ))}
            </ul>
        );
    };

    _renderMultiTimeRows = (startTimes, endTimes, names) => {
        const allOnSameDate = (st, et) => {
            const dates = st
                .map((start) => start.format("yyyy-MM-dd"))
                .concat(et.map((end) => end.format("JJJJ-NN-bb")));
            const date = dates[0];
            return _.every(dates, (dt) => dt === date);
        };
        if (allOnSameDate(startTimes, endTimes)) {
            const rows = [
                <tr key="dateTimeFirst">
                    <th>{Language.get("cal_res_side_reservation_date")}</th>
                    <td>
                        {SimpleDateFormat.format(
                            startTimes[0],
                            Language.getDateFormat("date_f_yyyy_mm_dd")
                        )}
                    </td>
                </tr>,
            ];
            // if (this.state.reservations.length === 1 || !clusterProps.time) { // Is this check needed?
            rows.push(
                startTimes.map((start, index) => {
                    if (index === 0) {
                        return (
                            <tr key={`dateTimeSecond${index}`}>
                                <th>{Language.get("cal_res_side_reservation_time")}</th>
                                <td>
                                    <b>
                                        {SimpleDateFormat.format(
                                            start,
                                            Language.getDateFormat("date_f_hh_mm")
                                        )}{" "}
                                        -{" "}
                                        {SimpleDateFormat.format(
                                            endTimes[index],
                                            Language.getDateFormat("date_f_hh_mm_end")
                                        )}{" "}
                                        ({names[index]})
                                    </b>
                                </td>
                            </tr>
                        );
                    }
                    return (
                        <tr key={`dateTimeSecond${index}`}>
                            <th />
                            <td>
                                {SimpleDateFormat.format(
                                    start,
                                    Language.getDateFormat("date_f_hh_mm")
                                )}{" "}
                                -{" "}
                                {SimpleDateFormat.format(
                                    endTimes[index],
                                    Language.getDateFormat("date_f_hh_mm_end")
                                )}{" "}
                                ({names[index]})
                            </td>
                        </tr>
                    );
                })
            );
            //}
            return rows;
        }
        const startLines = startTimes.map((start, index) => {
            if (index === 0) {
                return (
                    <tr key={`dateTimeFirst${index}`}>
                        <th>{Language.get("cal_res_side_reservation_begin")}</th>
                        <td>
                            <b>
                                {SimpleDateFormat.format(
                                    start,
                                    Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm")
                                )}{" "}
                                ({names[index]})
                            </b>
                        </td>
                    </tr>
                );
            }
            return (
                <tr key={`dateTimeFirst${index}`}>
                    <th />
                    <td>
                        {SimpleDateFormat.format(
                            start,
                            Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm")
                        )}{" "}
                        ({names[index]})
                    </td>
                </tr>
            );
        });
        const endLines = endTimes.map((end, index) => {
            if (index === 0) {
                return (
                    <tr key={`dateTimeSecond${index}`}>
                        <th>{Language.get("cal_res_side_reservation_end")}</th>
                        <td>
                            <b>
                                {SimpleDateFormat.format(
                                    end,
                                    Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm_end")
                                )}{" "}
                                ({names[index]})
                            </b>
                        </td>
                    </tr>
                );
            }
            return (
                <tr key={`dateTimeSecond${index}`}>
                    <th />
                    <td>
                        {SimpleDateFormat.format(
                            end,
                            Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm_end")
                        )}{" "}
                        ({names[index]})
                    </td>
                </tr>
            );
        });
        return startLines.concat(endLines);
    };

    _renderTimeRows = (start, end, tzName) => {
        const clusterProps = this.getClusterProperties();

        if (start === null || end === null) {
            return [
                <tr key="dateTimeFirst">
                    <th>{Language.get("cal_res_side_reservation_date")}</th>
                    <td>-</td>
                </tr>,
                <tr key="dateTimeSecond">
                    <th>{Language.get("cal_res_side_reservation_time")}</th>
                    <td>-</td>
                </tr>,
            ];
        }

        if (start.format("yyyy-MM-dd") === end.format("JJJJ-NN-bb")) {
            const rows = [
                <tr key="dateTimeFirst">
                    <th>{Language.get("cal_res_side_reservation_date")}</th>
                    <td>
                        {SimpleDateFormat.format(
                            start,
                            Language.getDateFormat("date_f_yyyy_mm_dd")
                        )}
                    </td>
                </tr>,
            ];
            if (this.state.reservations.length === 1 || !clusterProps.time) {
                rows.push(
                    <tr key="dateTimeSecond">
                        <th>{Language.get("cal_res_side_reservation_time")}</th>
                        <td>
                            {SimpleDateFormat.format(start, Language.getDateFormat("date_f_hh_mm"))}{" "}
                            -{" "}
                            {SimpleDateFormat.format(
                                end,
                                Language.getDateFormat("date_f_hh_mm_end")
                            )}{" "}
                            {tzName ? `(${tzName})` : null}
                        </td>
                    </tr>
                );
            }
            return rows;
        }

        return [
            <tr key="dateTimeFirst">
                <th>{Language.get("cal_res_side_reservation_begin")}</th>
                <td>
                    {SimpleDateFormat.format(
                        start,
                        Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm")
                    )}
                </td>
            </tr>,
            <tr key="dateTimeSecond">
                <th>{Language.get("cal_res_side_reservation_end")}</th>
                <td>
                    {SimpleDateFormat.format(
                        end,
                        Language.getDateFormat("date_f_yyyy_mm_dd_hh_mm_end")
                    )}
                </td>
            </tr>,
        ];
    };
}

module.exports = ReservationInfo;
