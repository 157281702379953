const React = require("react");
import {
    MillenniumDate,
    MillenniumWeek,
    MillenniumWeekday,
    SimpleDateFormat,
} from "millennium-time";
const Language = require("../lib/Language");
const Mousetrap = require("mousetrap");
const _ = require("underscore");
const TC = require("../lib/TimeConstants");

const LAST_MONTH = 11;
const NUM_WEEKS = 6;

// eslint-disable-next-line prefer-const
let styles;

class MassMoveDateSelect extends React.Component {
    static defaultProps = {
        allowDate: true,
        allowWeek: false,
        allowWeekday: false,
        allowMonth: false,
        values: [],
        startDate: null,
        endDate: null,
        highlightDates: [],
        selectedDate: null,
    };

    constructor(props) {
        super(props);
        const now = MillenniumDate.today();

        this.state = {
            year: now.getYear(),
            month: now.getMonth(),
            lastToggledDate: null,
        };
    }

    componentDidMount() {
        if (this.props.onClose) {
            this._prevEsc = Mousetrap.unbind("esc", true);
            Mousetrap.bind("esc", this.props.onClose);
        }

        if (this.props.now) {
            // eslint-disable-next-line react/no-did-mount-set-state
            this.setState({
                year: this.props.now.getYear(),
                month: this.props.now.getMonth(),
                highlightDates: this.props.highlightDates
                    ? this.props.highlightDates.map((date) => date.getDayNumber())
                    : [],
            });
        }
    }

    componentWillUnmount() {
        if (this._prevEsc) {
            Mousetrap.bind("esc", this._prevEsc[0]);
        }
    }

    getWeekdays = () => {
        const firstDayOfWeek = Language.firstDayOfWeek.absoluteWeekdayNumber;
        return _.range(0, TC.DAYS_PER_WEEK).map(
            (index) => MillenniumWeekday.WEEKDAYS[(index + firstDayOfWeek) % TC.DAYS_PER_WEEK],
            this
        );
    };

    isTypeAllowed = (type) => {
        const tp = type.substring(0, 1).toUpperCase() + type.substring(1).toLowerCase();
        if (this.props.hasOwnProperty(`allow${tp}`)) {
            return this.props[`allow${tp}`];
        }

        return true;
    };

    toggleSelection = (inDates, type, event) => {
        if (!this.isTypeAllowed(type)) {
            return;
        }

        let lastToggledDate = null;
        let dates = Array.isArray(inDates) ? inDates : [inDates];

        if (this.state.lastToggledDate && dates.length === 1 && event.shiftKey === true) {
            dates = _.range(0, dates[0].diff(this.state.lastToggledDate, "days") + 1).map(function (
                n
            ) {
                return this.state.lastToggledDate.addDays(n);
            },
            this);
        }

        const deselectDates = [];
        let selection = _.filter(this.props.values, (selectedDate) =>
            _.every(dates, (date) => {
                if (date.isSame(selectedDate)) {
                    deselectDates.push(date);
                    return false;
                }
                return true;
            })
        );

        if (deselectDates.length < dates.length) {
            selection = selection.concat(dates);
        }

        if (
            dates.length === 1 &&
            selection.length === this.props.values.length + 1 &&
            event.shiftKey === false
        ) {
            lastToggledDate = dates[0];
        }

        this.setState({
            lastToggledDate,
        });
        this.props.onChange(selection);
    };

    toggleCurrentMonth = (event) => {
        const lastDayOfMonth = MillenniumDate.lastDateOfMonth(
            this.state.year,
            this.state.month + 1
        );
        this.toggleSelection(
            _.range(0, lastDayOfMonth.getDate()).map(function (index) {
                return MillenniumDate.create(this.state.year, this.state.month + 1, index + 1);
            }, this),
            "month",
            event
        );
    };

    stepMonth = (inc) => {
        const increase = inc ? true : false;
        let year = this.state.year;
        let month = this.state.month + (increase ? 1 : -1);
        if (month < 0) {
            year = year - 1;
            month = LAST_MONTH;
        } else if (month > LAST_MONTH) {
            year = year + 1;
            month = 0;
        }

        this.setState({
            year,
            month,
        });
    };

    onYearChange = (event) => {
        const year = parseInt(event.target.value, 10);
        let month = this.state.month;

        const firstDateOfMonth = MillenniumDate.lastDateOfMonth(year, this.state.month + 1);
        const lastDateOfMonth = MillenniumDate.create(year, this.state.month + 1, 1);

        if (firstDateOfMonth.isAfter(this.props.endDate)) {
            month = this.props.endDate.getMonth();
        }
        if (lastDateOfMonth.isBefore(this.props.startDate)) {
            month = this.props.startDate.getMonth();
        }

        this.setState({ month, year });
    };

    isHighlighted = (date) =>
        _.some(this.props.highlightDates, (hD) => hD.getDayNumber() === date.getDayNumber());

    render() {
        const firstDayOfMonth = MillenniumDate.create(this.state.year, this.state.month + 1, 1);
        const week = new MillenniumWeek(
            firstDayOfMonth,
            Language.firstDayOfWeek,
            Language.daysInFirstWeek
        );
        const firstDateInWeek = week.getStartOfWeek().getMillenniumDate();
        // eslint-disable-next-line no-magic-numbers
        const weekStarts = _.range(0, 6).map((index) => firstDateInWeek.addWeeks(index));

        // Creating the date to format for headline mid-month since the first days of January often belong to a week starting the previous year
        const showBack = week.getStartDate().isAfter(this.props.startDate);
        // eslint-disable-next-line no-magic-numbers
        const showForward = weekStarts[5].addDays(6).isBefore(this.props.endDate);
        return (
            <table className={"massMoveDateSelect"} style={styles.noTextSelect}>
                <tbody>
                    <tr>
                        <th
                            colSpan="8"
                            onClick={this.toggleCurrentMonth}
                            style={{ textAlign: "center" }}
                        >
                            {showBack ? (
                                <span
                                    style={{
                                        float: "left",
                                        paddingLeft: "3px",
                                        fontFamily: "FontAwesome",
                                        cursor: "pointer",
                                    }}
                                    onClick={this.stepMonth.bind(this, false)}
                                >
                                    &#xf0d9;
                                </span>
                            ) : null}
                            {showForward ? (
                                <span
                                    style={{
                                        float: "right",
                                        paddingRight: "3px",
                                        fontFamily: "FontAwesome",
                                        cursor: "pointer",
                                    }}
                                    onClick={this.stepMonth.bind(this, true)}
                                >
                                    &#xf0da;
                                </span>
                            ) : null}
                            {MillenniumDate.create(this.state.year, this.state.month + 1, 1).format(
                                "MMMM"
                            )}
                            {this._renderYearSelect()}
                        </th>
                    </tr>

                    <tr>
                        <td>&nbsp;</td>
                        {this.getWeekdays().map(function (weekday, index) {
                            const weekdayDates = weekStarts.map((weekStart) =>
                                weekStart.addDays(index)
                            );
                            return (
                                <th
                                    key={weekday.absoluteWeekdayNumber}
                                    style={{ textAlign: "center", verticalAlign: "middle" }}
                                    onClick={this.toggleSelection.bind(
                                        this,
                                        weekdayDates,
                                        "weekday"
                                    )}
                                >
                                    {SimpleDateFormat.format(weekday, "EE")}
                                </th>
                            );
                        }, this)}
                    </tr>

                    {_.range(0, NUM_WEEKS).map(function (weekIndex) {
                        const start = weekStarts[weekIndex];
                        const dates = _.range(0, TC.DAYS_PER_WEEK).map((index) =>
                            start.addDays(index)
                        );

                        return (
                            <tr
                                key={start.getWeek(
                                    true,
                                    Language.firstDayOfWeek,
                                    Language.daysInFirstWeek
                                )}
                            >
                                <th
                                    style={{ textAlign: "center" }}
                                    onClick={this.toggleSelection.bind(this, dates, "week")}
                                >
                                    {start.getWeek(
                                        false,
                                        Language.firstDayOfWeek,
                                        Language.daysInFirstWeek
                                    )}
                                </th>
                                {dates.map(function (date) {
                                    const isDateSelected = _.some(
                                        this.props.values,
                                        (selectedDate) => date.isSame(selectedDate)
                                    );
                                    let style = _.extend({}, styles.cell);
                                    if (date.getMonth() !== this.state.month) {
                                        style = _.extend(style, styles.inactive);
                                    }
                                    let isDisabled = false;
                                    if (
                                        this.props.startDate &&
                                        date.isBefore(this.props.startDate)
                                    ) {
                                        isDisabled = true;
                                    }
                                    if (this.props.endDate && date.isAfter(this.props.endDate)) {
                                        isDisabled = true;
                                    }
                                    if (this.props.hideWeekends && date.isWeekend()) {
                                        isDisabled = true;
                                    }
                                    /*if (date.getDay() !== this.props.weekday) {
                                        isDisabled = true;
                                    }*/
                                    if (isDisabled) {
                                        style = _.extend(style, styles.disabled);
                                    }
                                    if (isDateSelected) {
                                        style = _.extend(style, styles.selected);
                                    }
                                    if (this.state.selectedDate === date.getDayNumber()) {
                                        style = _.extend(style, styles.highlighted);
                                    }
                                    if (this.isHighlighted(date)) {
                                        style = _.extend(style, styles.highlighted);
                                    }
                                    const passSelectedDate = function () {
                                        this.props.showSelectedDate(date);
                                        this.setState({ selectedDate: date.dayNumber });
                                    };
                                    return (
                                        <td
                                            style={style}
                                            key={date.getDayNumber()}
                                            onClick={
                                                isDisabled ? _.noop : passSelectedDate.bind(this)
                                            }
                                        >
                                            {date.getDate()}
                                        </td>
                                    );
                                }, this)}
                            </tr>
                        );
                    }, this)}
                </tbody>
            </table>
        );
    }

    _renderYearSelect = () => {
        const years = _.range(this.props.startDate.getYear(), this.props.endDate.getYear() + 1);
        if (years.length === 1) {
            return (
                <span style={{ marginLeft: "2px", lineHeight: "18px" }}>
                    {MillenniumDate.create(this.state.year, 1, 1).format("YYYY")}
                </span>
            );
        }

        return (
            <select
                value={this.state.year}
                onChange={this.onYearChange}
                style={{ marginLeft: "2px", lineHeight: "18px" }}
            >
                {years.map((year) => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
        );
    };
}

styles = {
    table: {
        tableLayout: "fixed",
    },
    cell: {
        textAlign: "center",
        width: "12.5%",
        cursor: "pointer",
        verticalAlign: "top",
    },
    highlighted: {
        fontWeight: "bold",
        backgroundColor: "#ccc",
    },
    inactive: {
        color: "#ccc",
    },
    disabled: {
        color: "#eee",
        textDecoration: "italic",
        cursor: "default",
    },
    selected: {
        background: "rgb(179, 224, 252)",
    },
    noTextSelect: {
        WebkitTouchCallout: "none",
        WebkitUserSelect: "none",
        KhtmlUserSelect: "none",
        MozUserSelect: "none",
        msUserSelect: "none",
        userSelect: "none",
    },
};

module.exports = MassMoveDateSelect;
