const Model = require("./Model");
const TemplateKind = require("./TemplateKind");
import { MillenniumDateTime } from "millennium-time";
const Limits = require("./Limits");
const Settings = require("./Settings");

const DEFAULT_LIST_ID = 10;

const MemberData = function (
    limits = Limits.getDefaultList(),
    selection = null,
    reservationIds = [],
    groupRequests = [],
    objects = [],
    callback = undefined
) {
    Model.call(this, "MemberData");
    this.templateKind = TemplateKind.RESERVATION;
    this.privateSelected = false;
    this.selection = selection || null;
    this.limits = limits;
    this.typeFilter = [];
    this.isTimePeriod = false;
    this.listId = DEFAULT_LIST_ID;
    this.reservationIds = reservationIds;
    this.groupRequests = groupRequests;
    this.objects = objects;
    this.callback = callback;
};

MemberData.prototype = Object.create(Model.prototype);

MemberData.prototype.getSettings = function () {
    const settings = [];
    return new Settings(settings);
};

MemberData.prototype.getMacroFunctions = function () {
    const macros = [];
    return macros;
};

MemberData.prototype.setLimits = function (limits) {
    if (!(limits instanceof Limits)) {
        // eslint-disable-next-line no-param-reassign
        limits = new Limits(limits);
    }

    return this.immutableSet({
        limits,
    });
};

MemberData.prototype.setIsTimePeriod = function (isTimePeriod) {
    return this.immutableSet({ isTimePeriod });
};

MemberData.prototype.updateTimeRange = function (newBeginTime, newEndTime) {
    let limits = new Limits(this.limits);
    limits = limits.setStartDayFromDate(new MillenniumDateTime(newBeginTime).getMillenniumDate());
    limits = limits.setDayCountFromDate(new MillenniumDateTime(newEndTime).getMillenniumDate());
    return this.immutableSet({
        limits,
    });
};

// What about the search settings object and such? How to even get hold of it from here?
MemberData.prototype.toJSON = function () {
    return {
        limits: this.limits,
        privateSelectedList: this.privateSelected,
        templateKind: this.templateKind.mode,
        typeFilter: this.typeFilter,
        reservationIds: this.reservationIds,
        function: this.getMacroFunctions(),
        isList: false,
        isPrefsComponent: false,
        isExamComponent: false,
        isGroupManager: true,
        listId: this.listId,
    };
};

module.exports = MemberData;
