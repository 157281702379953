import { MillenniumDateTime, MillenniumTime } from "millennium-time";
const _ = require("underscore");
const TimeEdit = require("../lib/TimeEdit");
import { EntryKind } from "../lib/EntryConstants";
import { TEntry, TEntryRules } from "../types/TEntry";

const ReservationConstants = require("../lib/ReservationConstants");
const TC = require("../lib/TimeConstants");

const EMPTY_PADDING = { padding: 0, paddingItems: [] };

const EMPTY_PADDINGS = { before: EMPTY_PADDING, after: EMPTY_PADDING };

export const Entry = function (this: TEntry, startTimes: string[] = [], endTimes: string[] = []) {
    this.startTimes = _.asArray(startTimes);
    this.endTimes = _.asArray(endTimes);
    this.text = "";
    this.layer = 0;
    this.reservationids = [];
    this.entrypropertyid = 0;
    this.position = -1;
    this.objects = [];
    this.kind = EntryKind.NONE;
    this.incomplete = false;
    this.occupied = false;
    this.physical = true;
    this.membership = false;
    this.status = 0;
    this.periods = {};
    this.isPartial = false;
    this.overlapCount = 1;
    this.grouped = false;
    this.groups = [];
    this.colorObjects = [];
    this.clusterId = 0;
    this.headerObjects = [];
    this.colors = [];
    this.conflictingObjects = [];
    this.padding = EMPTY_PADDINGS;
    this.isPadding = false;
};

Entry.prototype.getLength = function () {
    return this.endTimes[0].getMts() - this.startTimes[0].getMts() || 0;
};

Entry.prototype.setLength = function (seconds: number) {
    this.startTimes.forEach((start, index) => {
        this.endTimes[index] = start.addSeconds(seconds);
    });
};

Entry.prototype.isRequest = function () {
    return (
        this.status === ReservationConstants.STATUS.REQUESTED ||
        this.status === ReservationConstants.STATUS.REJECTED
    );
};

const getSlotsFor = function (absoluteWeekday: number, slots) {
    return _.filter(slots, (slot) => slot.week_days.indexOf(absoluteWeekday) !== -1);
};

const findBestSlot = function (startTime: number, endTime: number, fixedProperty, slots) {
    let filterFn = (slot) =>
        slot.start_time <= startTime && _.some(slot.end_times, (end) => startTime < end);
    if (fixedProperty === "start") {
        filterFn = (slot) =>
            slot.start_time === startTime && _.some(slot.end_times, (end) => startTime < end);
    }
    if (fixedProperty === "end") {
        filterFn = (slot) =>
            slot.start_time >= startTime && _.some(slot.end_times, (end) => startTime > end);
    }
    const applicableSlots = _.sortBy(_.filter(slots, filterFn), "start_time").reverse();
    return applicableSlots[0]; // || getDefaultSlot(slots, fixedProperty, startTime);
};

const findBestEndTime = function (
    startSeconds: number,
    endSeconds: number,
    endTimes: number[],
    fixedProperty: "start" | "end" | "length",
    findNextShorterSlot: boolean
) {
    // End time should also be after the found start time if end isn't fixed
    let closestTime = endTimes[0];
    if (fixedProperty === "end") {
        endTimes.forEach((et) => {
            if (et > closestTime && et <= startSeconds && et < endSeconds) {
                closestTime = et;
            }
        });
        const lastTime = endTimes[0];
        if (endSeconds < lastTime) {
            closestTime = lastTime;
        }
    } else {
        closestTime = findNextShorterSlot ? endTimes[0] : endTimes[endTimes.length - 1];
        endTimes.forEach((et) => {
            if (findNextShorterSlot === true) {
                if (et > closestTime && et <= endSeconds && et > startSeconds) {
                    closestTime = et;
                }
            } else {
                if (et < closestTime && et >= endSeconds && et > startSeconds) {
                    closestTime = et;
                }
            }
        });
        const lastTime = endTimes[endTimes.length - 1];
        if (endSeconds > lastTime) {
            closestTime = lastTime;
        }
    }
    return closestTime;
};

const applySlotRulesOnIndex = function (
    this: any,
    index: number,
    startSeconds: number,
    endSeconds: number,
    rules: TEntryRules,
    fixedProperty: "start" | "end" | "length",
    isCreate: boolean,
    usePreferred: boolean,
    fluffyLength: number,
    findNextShorterSlot: boolean,
    requestedDuration: number
) {
    const possibleSlots = getSlotsFor(
        this.startTimes[index].getMillenniumDate().getDay(),
        rules.time_slots.start_times
    );
    const bestSlot = findBestSlot(startSeconds, endSeconds, fixedProperty, possibleSlots);
    if (bestSlot) {
        let startTime = bestSlot.start_time;
        let endTime = endSeconds;
        if (fixedProperty === "length" && requestedDuration) {
            endTime = startTime + requestedDuration;
        }

        endTime = findBestEndTime(
            startTime,
            endTime,
            bestSlot.end_times || rules.time_slots.end_times,
            fixedProperty,
            findNextShorterSlot
        );
        if (
            usePreferred &&
            isCreate &&
            bestSlot.default_end_time &&
            bestSlot.default_end_time > endTime &&
            !requestedDuration
        ) {
            endTime = bestSlot.default_end_time;
        }
        if (startTime > endTime) {
            const tmpTime = startTime;
            startTime = endTime;
            endTime = tmpTime;
        }
        if (fixedProperty === "length") {
            let length =
                usePreferred && isCreate
                    ? fluffyLength
                    : this.endTimes[index].getMts() - this.startTimes[index].getMts();
            if (isCreate && endTime - startTime > length) {
                length = endTime - startTime;
            }
            this.startTimes[index] = new MillenniumDateTime(
                this.startTimes[index].getMillenniumDate(),
                new MillenniumTime(startTime)
            );
            this.endTimes[index] = new MillenniumDateTime(
                this.endTimes[index].getMillenniumDate(),
                new MillenniumTime(startTime + length)
            );
            return bestSlot;
        }
        if (isCreate || fixedProperty !== "start") {
            this.startTimes[index] = new MillenniumDateTime(
                this.startTimes[index].getMillenniumDate(),
                new MillenniumTime(startTime)
            );
        }
        if (isCreate || fixedProperty !== "end") {
            this.endTimes[index] = new MillenniumDateTime(
                this.endTimes[index].getMillenniumDate(),
                new MillenniumTime(endTime)
            );
        }
    }
    return bestSlot;
};

const applyRulesOnIndex = function (
    this: any,
    index: number,
    rules: TEntryRules,
    fluffyLength: number,
    usePreferred: boolean,
    useHighResolution: boolean,
    fixedProperty: "start" | "end" | "length",
    isCreate: boolean,
    findNextShorterSlot: boolean,
    requestedDuration: number
) {
    let startSeconds = this.startTimes[index].getMillenniumTime().getTimeNumber();
    let endSeconds = this.endTimes[index]
        ? this.endTimes[index].getMillenniumTime().getTimeNumber()
        : null;
    const length = this.endTimes[index]
        ? this.endTimes[index].getMts() - this.startTimes[index].getMts()
        : 0;
    if (rules.time_slots_only) {
        return applySlotRulesOnIndex.call(
            this,
            index,
            startSeconds,
            endSeconds,
            rules,
            fixedProperty,
            isCreate,
            usePreferred,
            fluffyLength,
            findNextShorterSlot,
            requestedDuration
        );
    }
    let diff;
    const step = useHighResolution && rules.minimum_step ? rules.minimum_step : rules.major_step;
    const startStep =
        useHighResolution && rules.minimum_step ? rules.minimum_step : rules.start_step;

    if (!fixedProperty && usePreferred) {
        // eslint-disable-next-line no-param-reassign
        fixedProperty = "start";

        diff = startSeconds % startStep;
        if (diff !== 0) {
            diff = 0 - diff;
            this.startTimes[index] = this.startTimes[index].addSeconds(diff);
            startSeconds = this.startTimes[index].getMillenniumTime().getTimeNumber();
        }
    }

    if (!fixedProperty) {
        diff = startSeconds % startStep;
        if (diff !== 0) {
            diff = 0 - diff;
            this.startTimes[index] = this.startTimes[index].addSeconds(diff);
            startSeconds = this.startTimes[index].getMillenniumTime().getTimeNumber();
        }
        if (this.endTimes[index].getMts() < this.startTimes[index].getMts() + step) {
            this.endTimes[index] = this.startTimes[index].addSeconds(step);
        }

        return null;
    }

    // Start step
    if (fixedProperty !== "end") {
        diff = startSeconds % startStep;
        if (diff !== 0) {
            if (startStep === TC.SECONDS_PER_DAY) {
                diff = 0 - diff;
            } else {
                // eslint-disable-next-line no-magic-numbers
                diff = diff < startStep / 2 ? 0 - diff : startStep - diff;
            }
            this.startTimes[index] = this.startTimes[index].addSeconds(diff);
            startSeconds = this.startTimes[index].getMillenniumTime().getTimeNumber();
        }
    }

    if (fixedProperty === "length") {
        let ln = length;
        if (ln === 0) {
            ln = fluffyLength > 0 ? fluffyLength : rules.pref_length;
        }
        this.endTimes[index] = this.startTimes[index].addSeconds(ln);
        return null;
    }

    diff = (endSeconds - startSeconds) % step;
    if (diff !== 0) {
        // eslint-disable-next-line no-magic-numbers
        diff = diff < step / 2 ? 0 - diff : step - diff;
        if (fixedProperty === "end") {
            this.startTimes[index] = this.startTimes[index].addSeconds(-diff);
            startSeconds = this.startTimes[index].getMillenniumTime().getTimeNumber();
        } else {
            this.endTimes[index] = this.endTimes[index].addSeconds(diff);
            endSeconds = this.endTimes[index].getMillenniumTime().getTimeNumber();
        }
    }
    // Minimum length
    if (usePreferred) {
        const prefLength = fluffyLength > 0 ? fluffyLength : rules.pref_length;
        if (fixedProperty === "end") {
            this.startTimes[index] = this.endTimes[index].addSeconds(-prefLength);
        } else {
            this.endTimes[index] = this.startTimes[index].addSeconds(prefLength);
        }
    } else if (
        this.endTimes[index] &&
        this.endTimes[index].getMts() - this.startTimes[index].getMts() < rules.min_length
    ) {
        if (fixedProperty === "end") {
            this.startTimes[index] = this.endTimes[index].addSeconds(-rules.min_length);
        } else {
            this.endTimes[index] = this.startTimes[index].addSeconds(rules.min_length);
        }
    }

    // Maximum length
    if (
        this.endTimes[index] &&
        this.endTimes[index].getMts() - this.startTimes[index].getMts() > rules.max_length
    ) {
        if (fixedProperty === "end") {
            this.startTimes[index] = this.endTimes[index].addSeconds(-rules.max_length);
        } else {
            this.endTimes[index] = this.startTimes[index].addSeconds(rules.max_length);
        }
    }

    startSeconds = this.startTimes[index].getMillenniumTime().getTimeNumber();

    // Start step
    if (fixedProperty === "end") {
        diff = startSeconds % startStep;
        if (diff !== 0) {
            // eslint-disable-next-line no-magic-numbers
            diff = diff < startStep / 2 ? 0 - diff : startStep - diff;
            this.startTimes[index] = this.startTimes[index].addSeconds(diff);
        }
    }
    return null;
};

Entry.prototype.applyRules = function (
    rules: TEntryRules,
    length: number,
    usePreferred: boolean,
    useHighResolution: boolean,
    fixedProperty: "start" | "end" | "length",
    isCreate: boolean,
    findNextShorterSlot: boolean,
    requestedDuration: number
) {
    const slots: any[] = [];
    for (let i = 0; i < this.startTimes.length; i++) {
        const slot = applyRulesOnIndex.call(
            this,
            i,
            rules,
            length,
            usePreferred,
            useHighResolution,
            fixedProperty,
            isCreate,
            findNextShorterSlot,
            requestedDuration
        );
        if (slot !== null) {
            slots.push(slot);
        }
    }
    return slots.filter((slot) => !_.isNullish(slot));
};

Entry.prototype.getSubentries = function (headerObjects: any[] = [], splitOnDays = true) {
    // Split entries by days
    let subentries = [_.clone(this)];
    if (splitOnDays) {
        const diffDays =
            this.endTimes[0].getMillenniumDate().getDayNumber() -
            this.startTimes[0].getMillenniumDate().getDayNumber();
        subentries = _.range(0, diffDays + 1).map((i) => {
            const subentry = _.clone(this);

            if (i > 0) {
                subentry.startTimes = subentry.startTimes.map((time) =>
                    time.addDays(i).getStartOfDay()
                );
            }
            if (i < diffDays) {
                subentry.endTimes = subentry.startTimes.map((time) =>
                    time.getEndOfDay().addSeconds(-1)
                );
            }

            return subentry;
        });
    } else {
        // Ensure entries ending at midnight are rendered as if they end one second before midnight
        subentries[0].endTimes = this.endTimes.map((time) => {
            if (time.isMidnight()) {
                return time.addSeconds(-1);
            }
            return time;
        });
    }

    // Split entries by objects
    if (headerObjects.length > 0 && this.kind === EntryKind.INFO) {
        const objects = headerObjects.reduce(
            (prevValue, currentValue) => prevValue.concat(currentValue.objects),
            []
        );
        subentries = _.flatten(
            subentries.map((subentry) =>
                _.unique(objects).map((object) => _.extend({}, subentry, { objects: [object] }))
            )
        );
    }

    // Filter out zero-length entries
    return subentries.filter((subentry) => {
        const length = subentry.endTimes[0].getMts() - subentry.startTimes[0].getMts();
        return length > 0;
    });
};

Entry.equals = function (e1: TEntry, e2: TEntry) {
    return (
        _.every(e1.startTimes, (time, i) => time.equals(e2.startTimes[i])) &&
        _.every(e1.endTimes, (time, i) => time.equals(e2.endTimes[i])) &&
        _.isEqual(e1.objects, e2.objects)
    );
};

Entry.timeEquals = function (e1: TEntry, e2: TEntry) {
    return (
        _.every(e1.startTimes, (time, i) => time.equals(e2.startTimes[i])) &&
        _.every(e1.endTimes, (time, i) => time.equals(e2.endTimes[i]))
    );
};

Entry.isBlue = function (entry: TEntry) {
    return (
        entry.kind === EntryKind.COMPLETE ||
        entry.kind === EntryKind.RESERVATION ||
        entry.kind === EntryKind.GROUP_COMPLETE ||
        entry.kind === EntryKind.GROUP
    );
};

Entry.isReservation = function (entry: TEntry) {
    return _.contains(
        [
            EntryKind.COMPLETE,
            EntryKind.RESERVATION,
            EntryKind.OBSTACLE,
            EntryKind.GROUP_COMPLETE,
            EntryKind.GROUP,
            EntryKind.OBSTACLE_GROUP,
        ],
        entry.kind
    );
};

Entry.prototype.equals = function (entry: TEntry) {
    return Entry.equals(this, entry);
};

Entry.prototype.overlaps = function (otherEntry: TEntry) {
    return (
        (_.some(this.startTimes, (startTime) =>
            _.some(otherEntry.startTimes, (otherStart) => startTime.mts <= otherStart.mts)
        ) &&
            _.some(this.endTimes, (endTime) =>
                _.some(otherEntry.startTimes, (otherStart) => endTime.mts >= otherStart.mts)
            )) ||
        (_.some(this.startTimes, (startTime) =>
            _.some(otherEntry.endTimes, (otherEnd) => startTime.mts <= otherEnd.mts)
        ) &&
            _.some(this.endTimes, (endTime) =>
                _.some(otherEntry.endTimes, (otherEnd) => endTime.mts >= otherEnd.mts)
            ))
    );
};

const toMillenniumDateTime = function (timestamp: number[] | number) {
    if (!Array.isArray(timestamp)) {
        // eslint-disable-next-line no-param-reassign
        timestamp = [timestamp];
    }
    return timestamp.map((tm) => new MillenniumDateTime(tm));
};

Entry.prototype.clone = function () {
    return Object.keys(this).reduce(
        (entry, key) => _.extend(entry, { [key]: this[key] }),
        new Entry()
    );
};

Entry.prototype.isLocked = function (unlockedReservations) {
    if (!unlockedReservations || unlockedReservations.length === 0) {
        return false;
    }
    return !_.some(this.reservationids, (id) => unlockedReservations.indexOf(id) !== -1);
};

// For new lock functionality - should of course be isLocked, and probably a property instead of a function
// Rename - or see if we can remove - above lock function when we get closer to release.
Entry.prototype.hasLock = function () {
    return false;
};

Entry.prototype.hasPadding = function () {
    return (
        this.padding &&
        (this.padding.before !== EMPTY_PADDING || this.padding.after !== EMPTY_PADDING)
    );
};

Entry.create = function (data) {
    const entry: TEntry = new Entry();
    entry.startTimes = toMillenniumDateTime(data.begins || data.begin);
    entry.endTimes = toMillenniumDateTime(data.ends || data.end);
    entry.groups = (data.group ? [data.group] : data.groups) || [];
    entry.text = data.text || "";
    entry.layer = data.layer || 0;
    entry.reservationids = data.reservationids || (data.reservationid ? [data.reservationid] : []);
    entry.entrypropertyid = data.entrypropertyid;
    entry.objects = data.objects || [];
    entry.headerObjects = _.filter(
        entry.objects,
        // eslint-disable-next-line no-shadow
        (etr, index) =>
            data.isheaderobjects &&
            data.isheaderobjects.length > 0 &&
            data.isheaderobjects[index] === true
    );
    entry.colorObjects = data.colorobjects || [];
    entry.colors = data.colors || [];
    entry.conflictingObjects = data.conflictingObjects || [];
    entry.lock = data.lock || undefined;
    [
        "incomplete",
        "kind",
        "occupied",
        "physical",
        "membership",
        "status",
        "position",
        "modifiable",
        "readable",
        "overlapCountable",
        "grouped",
        "obstacleGrouped",
        "complete",
        "hideOnDrag",
        "clusterId",
        "periodIds",
    ].forEach((prop) => {
        entry[prop] = data[prop] || Entry.getDefaults(entry.entrypropertyid)[prop];
        if (prop === "status" && _.isObject(entry.status)) {
            entry.status = (entry.status as any).status;
        }
    });

    if (Entry.isReservation(entry) && !entry.modifiable) {
        entry.isPartial = true;
    }
    entry.overlapCount = data.overlap || 0;
    entry.overlapGroup = data.overlapGroup || 0;
    entry.memberExceptionCount = data.memberExceptionCount || 0;
    entry.padding = Object.assign({}, EMPTY_PADDINGS);
    if (data.paddingBefore) {
        entry.padding.before = data.paddingBefore;
    }
    if (data.paddingAfter) {
        entry.padding.after = data.paddingAfter;
    }
    entry.isPadding = data.isPadding || false;
    return entry;
};

Entry.getDefaults = (id) => _.find(TimeEdit.entryPropertyDefinitions, (item) => item.id === id);

module.exports = Entry;
