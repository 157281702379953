const PropTypes = require("prop-types");
const React = require("react");
import { MillenniumDate, MillenniumDateTime } from "millennium-time";
const ReservationListSettings = require("./ReservationListSettings");
const Language = require("../lib/Language");
const _ = require("underscore");
const TemplateKind = require("../models/TemplateKind");
const Reservation = require("../models/Reservation");
const ReservationTypeList = require("./ReservationTypeList");
const RC = require("../lib/ReservationConstants");
const API = require("../lib/TimeEditAPI");

const COL_IDS = {
    ID: 0,
    DATE: 1,
    TIME: 2,
    LENGTH: 10,
    TYPES: 3,
    FIELDS: 4,
};

const getAvailableColumns = (context) => {
    const columns = [
        { name: Language.get("cal_reservation_list_column_id"), sortable: true, id: 0 },
        { name: Language.get("cal_reservation_list_column_date"), sortable: true, id: 1 },
        { name: Language.get("cal_reservation_list_column_time"), sortable: false, id: 2 },
        { name: Language.get("cal_reservation_list_column_length"), sortable: false, id: 10 },
        { name: Language.get("cal_reservation_list_column_type_all"), sortable: false, id: 3 },
        { name: Language.get("cal_reservation_list_column_field_all"), sortable: false, id: 4 },
        { name: Language.get("cal_reservation_list_column_created"), sortable: true, id: 5 },
        { name: Language.get("cal_reservation_list_column_modified"), sortable: true, id: 6 },
        { name: Language.get("cal_reservation_list_column_week"), sortable: false, id: 8 },
        { name: Language.get("cal_reservation_list_column_week_day"), sortable: false, id: 9 },
        { name: Language.get("cal_reservation_list_column_status"), sortable: false, id: 11 },
        { name: Language.get("nc_reservation_exception_title"), sortable: false, id: 12 },
        { name: Language.get("nc_tags_title"), sortable: true, id: 13 },
    ];
    if (context.useNewReservationGroups) {
        columns.push({
            name: Language.get("nc_reservation_group_list_title"),
            sortable: false,
            id: 14,
        });
    }
    if (true) {
        // TODO Check if timezone column should be active
        columns.push({
            name: Language.get("cal_res_side_user_time_zone"),
            sortable: false,
            id: 15,
        });
    }
    return columns;
};

class ReservationList extends React.Component {
    static contextTypes = {
        user: PropTypes.object,
        fireEvent: PropTypes.func,
        presentModal: PropTypes.func,
        update: PropTypes.func,
        hasReservationExceptions: PropTypes.bool,
        useNewReservationGroups: PropTypes.bool,
    };

    constructor(props, context) {
        super(props, context);
        const timeLimit = this.getTimeLimits();

        this.state = {
            validStatus: [],
            allStatus: [
                RC.STATUS.CONFIRMED,
                RC.STATUS.PRELIMINARY,
                RC.STATUS.PLANNED,
                RC.STATUS.REQUESTED,
                RC.STATUS.REJECTED,
            ],
            searchOptions: {
                templateKind: TemplateKind.RESERVATION,
                searchString: "",
                beginTime: timeLimit.begin,
                endTime: timeLimit.end,
                name: "",
                allReservations: true,
                reservationStatus: [
                    RC.STATUS.CONFIRMED,
                    RC.STATUS.PRELIMINARY,
                    RC.STATUS.PLANNED,
                    RC.STATUS.REQUESTED,
                    RC.STATUS.REJECTED,
                ],
                includeMembers: RC.MEMBERS.EXCLUDE,
                useTemplateGroup: false,
                templateGroup: 0,
                reservationVariant: RC.VARIANT.RESERVATION,
                complete: true,
                incomplete: true,
                permission: RC.PERMISSION.READ,
                objectCategories: {},
                reservationCategories: {},
                ignoreExceptions: false,
                reservationGroupsOnly: false,
                placeholderType: null,
            },
            progress: null,
            availableReservationCategories: [],
            availableReservationCheckboxes: [],
            availableObjectCategories: [],
            availableObjectCheckboxes: [],
        };
    }

    componentDidMount() {
        API.getObjectCategories(this.setState.bind(this));
    }

    // eslint-disable-next-line no-unused-vars
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.data.limits !== this.props.data.limits) {
            const timeLimits = this.getTimeLimits();
            if (
                timeLimits.begin !== this.state.searchOptions.beginTime ||
                timeLimits.endTime !== this.state.searchOptions.endTime
            ) {
                // eslint-disable-next-line react/no-did-update-set-state
                this.setState({
                    searchOptions: _.extend({}, this.state.searchOptions, {
                        beginTime: timeLimits.begin,
                        endTime: timeLimits.end,
                    }),
                });
            }
        }
    }

    onSelectionChanged(newSelectedReservations) {
        const status = _.uniq(
            _.flatten(
                newSelectedReservations.map((reservation) => {
                    if (reservation.status) {
                        return reservation.status.map((st) => st.status);
                    }
                    return [RC.STATUS.CONFIRMED];
                })
            ).filter((st) => st >= RC.STATUS.CONFIRMED && st < RC.STATUS.TIME)
        );
        if (status.length === 0) {
            status.push(RC.STATUS.CONFIRMED);
        }
        Reservation.getValidStatus(status, (result) => {
            this.setState({ validStatus: result.map((st) => st.status) });
        });
    }

    getTimeLimits = () => {
        try {
            return {
                begin: MillenniumDateTime.fromDate(this.props.data.limits.getStartDate()).getMts(),
                end: MillenniumDateTime.fromDate(this.props.data.limits.getEndDate()).getMts(),
            };
        } catch (error) {
            return {
                begin: MillenniumDateTime.fromDate(MillenniumDate.today()).getMts(),
                end: 0,
            };
        }
    };

    onSearchSettingsChange = (settings, cb) => {
        let newSettings = _.extend(
            {},
            _.omit(settings, (value) => value === undefined)
        );
        // Reservation list should always search for reservations, not cancellations
        newSettings = _.extend(newSettings, { reservationVariant: RC.VARIANT.RESERVATION });

        this.setState({ searchOptions: _.extend({}, this.state.searchOptions, newSettings) }, cb);
    };

    limitTo = (categories, allowedCategories) => {
        const result = _.omit(
            categories,
            (value, category) =>
                !_.some(
                    allowedCategories,
                    (allowedCategory) =>
                        String(allowedCategory.id) === category &&
                        _.every(value, (val) => _.contains(allowedCategory.categories, val))
                )
        );
        return result;
    };

    getSettingsComponent = (
        saveDefaultSettings,
        saveSettings,
        getSavedSettings,
        removeSettings
    ) => {
        const opts = this.state.searchOptions;

        return (
            <ReservationListSettings
                prefix={"RL"}
                onChange={this.onSearchSettingsChange}
                allReservations={opts.allReservations}
                reservationStatus={opts.reservationStatus}
                includeMembers={opts.includeMembers}
                saveDefaultSettings={saveDefaultSettings}
                saveSettings={saveSettings}
                removeSettings={removeSettings}
                getSavedSettings={getSavedSettings}
                templateKind={opts.templateKind}
                complete={opts.complete}
                incomplete={opts.incomplete}
                permission={opts.permission}
                useTemplateGroup={opts.useTemplateGroup}
                objectCategories={this.limitTo(
                    opts.objectCategories,
                    this.state.availableObjectCategories.concat(
                        this.state.availableObjectCheckboxes
                    )
                )}
                reservationCategories={this.limitTo(
                    opts.reservationCategories,
                    this.state.availableReservationCategories.concat(
                        this.state.availableReservationCheckboxes
                    )
                )}
                availableReservationCategories={this.state.availableReservationCategories}
                availableReservationCheckboxes={this.state.availableReservationCheckboxes}
                availableObjectCategories={this.state.availableObjectCategories}
                availableObjectCheckboxes={this.state.availableObjectCheckboxes}
                ignoreExceptions={opts.ignoreExceptions}
                reservationGroupsOnly={opts.reservationGroupsOnly}
                hasReservationExceptions={this.context.hasReservationExceptions}
                placeholderType={opts.placeholderType}
            />
        );
    };

    render() {
        return (
            <ReservationTypeList
                {...this.props}
                onSearchSettingsChange={this.onSearchSettingsChange}
                onSelectionChanged={this.onSelectionChanged.bind(this)}
                searchOptions={this.state.searchOptions}
                defaultStatus={RC.STATUS.TIME}
                progress={this.state.progress}
                columns={getAvailableColumns(this.context)}
                defaultSelectedColumns={[COL_IDS.DATE, COL_IDS.TIME, COL_IDS.TYPES]}
                getSettingsComponent={this.getSettingsComponent}
                title={Language.get("nc_reserv_list_title")}
                allowMultiSelection
                supportsEmail
                supportsMassChange
                allStatus={this.state.allStatus}
                validStatus={this.state.validStatus}
                addTypeColumns={TemplateKind.equals(
                    TemplateKind.RESERVATION,
                    this.state.searchOptions.templateKind
                )}
                supportsBatchOperations={true}
            />
        );
    }
}

module.exports = ReservationList;
