import { MillenniumDateTime } from "millennium-time";

export enum SourceType {
    Membership,
    Relation,
    Availability,
    OptionalRelation,
}

export class ScratchpadObject {
    objectId: number;
    typeId: number | undefined;
    label: string;
    sourceObjectId = 0;
    sourceType: SourceType;
    destinationObjectId = 0;
    pinDate: number = 0;
    constructor(
        objectId: number,
        sourceObjectId: number,
        sourceType: SourceType,
        destinationObjectId: number,
        label: string,
        typeId: number | undefined,
        pinDate: number | undefined
    ) {
        this.objectId = objectId;
        this.sourceObjectId = sourceObjectId || 0;
        this.sourceType = sourceType;
        this.destinationObjectId = destinationObjectId || 0;
        this.label = label || String(objectId);
        this.typeId = typeId;
        const now = new Date();
        this.pinDate =
            pinDate ||
            MillenniumDateTime.fromYyyyMMddHHmmss(
                now.getFullYear(),
                now.getMonth() + 1,
                now.getDate(),
                now.getHours(),
                now.getMinutes(),
                now.getSeconds()
            ).getMts();
    }
}

export class Scratchpad {
    objects: ScratchpadObject[] = [];
    onChangeListener: any = null;
    constructor(onChangeListener) {
        this.onChangeListener = onChangeListener;
    }
    notify() {
        if (this.onChangeListener) {
            this.onChangeListener(this.objects);
        }
    }
    addObjects(objects: ScratchpadObject[]) {
        // Add many, notify once
        objects.forEach((object) => {
            if (this.isOnScratchpad(object.objectId)) {
                return;
            }
            this.objects.push(object);
        });
        this.notify();
    }
    addObject(object: ScratchpadObject) {
        if (this.isOnScratchpad(object.objectId)) {
            return;
        }
        this.objects.push(object);
        this.notify();
    }
    removeObjects(objectIds: number[]) {
        const removed = this.objects.filter((object) => objectIds.indexOf(object.objectId) > -1);
        this.objects = this.objects.filter((object) => objectIds.indexOf(object.objectId) === -1);
        this.notify();
        return removed;
    }
    isOnScratchpad(objectId: number) {
        return this.objects.some((object) => object.objectId === objectId);
    }
    getObjects(sourceType: SourceType | undefined) {
        if (!sourceType) {
            return this.objects;
        }
        return this.objects.filter((object) => object.sourceType === sourceType);
    }
    setObjects(objects: ScratchpadObject[]) {
        this.objects = Array.isArray(objects)
            ? objects.filter((obj) => obj.objectId !== null && obj.objectId !== undefined)
            : [];
        this.notify();
    }
}
