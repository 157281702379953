import { MillenniumDate, MillenniumTime } from "millennium-time";
const _ = require("underscore");

const HOUR_IN_SECONDS = 3600;
const Limits = function (limits) {
    this.dayCount = limits.dayCount;
    this.startDay = limits.startDay;
    this.isStartDayAbsolute = limits.isStartDayAbsolute;
    this.startTime = limits.startTime - (limits.startTime % HOUR_IN_SECONDS);
    this.timeCount = limits.timeCount;
    if (this.timeCount % HOUR_IN_SECONDS > 0) {
        this.timeCount = this.timeCount + (HOUR_IN_SECONDS - (this.timeCount % HOUR_IN_SECONDS));
    }
    this._today = MillenniumDate.today();
};

Limits.prototype.getStartDate = function () {
    if (!this.isStartDayAbsolute) {
        return new MillenniumDate(this._today.getDayNumber() + this.startDay);
    }

    return new MillenniumDate(this.startDay);
};

Limits.prototype.getEndDate = function () {
    if (!this.isStartDayAbsolute) {
        return new MillenniumDate(this._today.getDayNumber() + this.startDay + this.dayCount);
    }

    return new MillenniumDate(this.startDay + this.dayCount);
};

Limits.prototype.getStartTime = function () {
    return new MillenniumTime(this.startTime);
};

Limits.prototype.getEndTime = function () {
    return new MillenniumTime(this.startTime + this.timeCount);
};

Limits.prototype.setStartDay = function (startDay) {
    const limits = _.clone(this);
    limits.startDay = startDay;
    limits.dayCount = this.dayCount + (this.startDay - startDay);
    if (limits.dayCount < 0) {
        limits.dayCount = 0;
    }
    return new Limits(limits);
};

Limits.prototype.setStartDayFromDate = function (date) {
    const limits = _.clone(this);
    let diff;
    if (this.isStartDayAbsolute) {
        limits.startDay = date.getDayNumber();
        diff = this.startDay - limits.startDay;
    } else {
        limits.startDay = date.getDayNumber() - this._today.getDayNumber();
        diff = this.startDay - limits.startDay;
    }

    limits.dayCount = this.dayCount + diff;
    return new Limits(limits);
};

Limits.prototype.setDayCountFromDate = function (date, subtractOne = false) {
    const limits = _.clone(this);
    if (this.isStartDayAbsolute) {
        limits.dayCount = date.getDayNumber() - this.startDay;
    } else {
        limits.dayCount = date.getDayNumber() - (this.startDay + this._today.getDayNumber());
    }
    if (subtractOne) {
        limits.dayCount = limits.dayCount - 1;
    }
    return new Limits(limits);
};

Limits.prototype.setStartTimeFromTime = function (time) {
    const limits = _.clone(this);
    limits.startTime = time.getTimeNumber();
    if (limits.startTime % HOUR_IN_SECONDS > 0) {
        limits.startTime -= limits.startTime % HOUR_IN_SECONDS;
    }
    const diff = this.startTime - limits.startTime;
    limits.timeCount = this.timeCount + diff;
    return new Limits(limits);
};

Limits.prototype.setTimeCountFromTime = function (time) {
    const limits = _.clone(this);
    let count = time.getTimeNumber();

    if (count % HOUR_IN_SECONDS > 0) {
        count = count + (HOUR_IN_SECONDS - (count % HOUR_IN_SECONDS));
    }
    limits.timeCount = count - this.startTime;
    return new Limits(limits);
};

Limits.prototype.setIsStartDayAbsolute = function (value) {
    const limits = _.clone(this);
    limits.isStartDayAbsolute = value;

    if (this.isStartDayAbsolute && !value) {
        limits.startDay = limits.startDay - this._today.getDayNumber();
    } else if (!this.isStartDayAbsolute && value) {
        limits.startDay = limits.startDay + this._today.getDayNumber();
    }

    return new Limits(limits);
};

const DEFAULT_DAY_COUNT = 630;

Limits.getDefaultList = () =>
    new Limits({
        startTime: 0,
        timeCount: 86400,
        isStartDayAbsolute: false,
        startDay: 0,
        dayCount: DEFAULT_DAY_COUNT,
    });

Limits.getDefault = function () {
    return new Limits({
        startTime: 0,
        timeCount: 86400,
        isStartDayAbsolute: false,
        startDay: -90, // About 3 months ago
        dayCount: DEFAULT_DAY_COUNT,
    });
};

Limits.toJSON = function () {
    return {
        dayCount: this.dayCount,
        startDay: this.startDay,
        isStartDayAbsolute: this.isStartDayAbsolute,
        startTime: this.startTime,
        timeCount: this.timeCount,
    };
};

module.exports = Limits;
