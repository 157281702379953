import { MillenniumDate, MillenniumWeek, SimpleDateFormat } from "millennium-time";
const PeriodHeader = require("./PeriodHeader");
const Language = require("../lib/Language");
const _ = require("underscore");

const DatePeriodHeader = function (visibleValues, firstVisibleValue, subheader) {
    PeriodHeader.call(this, visibleValues || 1, firstVisibleValue, subheader, "DatePeriodHeader");
    this.isWeekPeriod = false;
    this.weeks = [];
    this.futureWeeksOnly = false;
};

const getFilteredValues = (weeks, values) => {
    const weekNumbers = weeks.map((week) => week.week(true));
    const isDateInWeekList = (date) =>
        _.contains(
            weekNumbers,
            date.getWeek(true, Language.firstDayOfWeek, Language.daysInFirstWeek)
        );

    return values.map((dates) => dates.filter(isDateInWeekList));
};

// eslint-disable-next-line no-unused-vars
DatePeriodHeader.parse = function (data, limits) {
    // eslint-disable-line no-unused-vars
    const header = new DatePeriodHeader(data.visibleValues, data.firstValue);
    header.id = data.id;
    header.name = data.name;
    header.names = data.names;
    header.size = data.size;

    header.isWeekPeriod = data.isWeekPeriod || header.isWeekPeriod;
    header.weeks = [];
    if (data.weeks) {
        header.weeks = data.weeks.map((week) =>
            MillenniumWeek.create(week, Language.firstDayOfWeek, Language.daysInFirstWeek)
        );
    }
    header.futureWeeksOnly = data.futureWeeksOnly || false;

    header.values = [];
    const toMillenniumDate = (dateNumber) => new MillenniumDate(dateNumber);
    for (let i = 0; i < data.periods.length; i++) {
        header.values.push(data.periods[i].map(toMillenniumDate));
    }

    header._filteredValues = getFilteredValues(header.weeks, header.values);

    header.validateValues();

    return header;
};

DatePeriodHeader.prototype = Object.create(PeriodHeader.prototype);

DatePeriodHeader.prototype.getKind = function () {
    return PeriodHeader.KIND.DATE;
};

DatePeriodHeader.prototype.getValues = function () {
    if (this.weeks.length === 0) {
        return this.values;
    }

    return this._filteredValues;
};

DatePeriodHeader.prototype.getLabel = function (value, size, index, onlyVisible = false) {
    if (index === undefined) {
        throw new Error(
            "DatePeriodHeader requires the index of the sought period value to get its label."
        );
    }

    const absoluteIndex = onlyVisible ? index + this.firstVisibleValue : index;
    const label = this.names[absoluteIndex];

    return `(${value.length}) ${label}`;
};

DatePeriodHeader.prototype.getInfo = function (values, size, customWeekNames = []) {
    if (!this.isWeekPeriod || values.length === 0) {
        return null;
    }

    const allWeeks = this.getWeeks(customWeekNames);
    const selectedWeeks = _.filter(allWeeks, (week) => week.selected);
    const weekItems = _.map(selectedWeeks, (week) => week.value);

    const weekInfo = weekItems || _.map(allWeeks, (week) => week.value);
    const weekText =
        customWeekNames.length > 0
            ? (selectedWeeks || allWeeks).map((week) => week.label).join(", ")
            : MillenniumWeek.toString(weekInfo);
    const headerText = `${this.name} (${weekText})`;

    const firstVisible = this.values[this.firstVisibleValue];
    if (values === firstVisible) {
        return headerText;
    }

    const firstVisibleContainsAllValues = _.every(values, (value) =>
        _.some(firstVisible, (item) => value.equals(item))
    );
    return firstVisibleContainsAllValues ? headerText : null;
};

const getCustomWeekName = (week, customWeekNames) => {
    const name = _.find(
        customWeekNames,
        (customWeek) => customWeek.dayNumber === week.date.dayNumber
    );
    if (name) {
        return name.getLongestName();
    }
    return null;
};

DatePeriodHeader.prototype.getWeeks = function (customWeekNames = []) {
    const allDates = _.flatten(this.values);
    const allWeeks = _.uniq(
        allDates.map((date) =>
            date.getMillenniumWeek(Language.firstDayOfWeek, Language.daysInFirstWeek)
        ),
        false,
        (week) => week.week(true)
    );
    const allWeeksSorted = _.sortBy(allWeeks, (week) => week.week(true));

    return allWeeksSorted.map((week) => {
        const label =
            getCustomWeekName(week, customWeekNames) ||
            SimpleDateFormat.format(
                week.getStartOfWeek(),
                Language.getDateFormat("date_f_yyyy_ww_l")
            );
        return {
            value: week,
            label,
            selected:
                this.weeks.length === 0 ||
                this.weeks.some((filterWeek) => week.week(true) === filterWeek.week(true)),
        };
    });
};

DatePeriodHeader.prototype.getIndexOfDate = function (date, onlyVisible = false) {
    const soughtDay = date.getDayNumber();
    const values = onlyVisible ? this.getVisibleValues() : this.getValues();
    return values.findIndex((dates) => dates.some((dt) => dt.getDayNumber() === soughtDay));
};

DatePeriodHeader.prototype.setWeeks = function (weeks) {
    const values = getFilteredValues(weeks, this.values);
    return this.immutableSet({
        weeks,
        _filteredValues: values,
    });
};

// eslint-disable-next-line no-unused-vars
DatePeriodHeader.prototype.getSettings = function (providers) {
    // eslint-disable-line no-unused-vars
    const settings = PeriodHeader.prototype.getSettings.call(this);

    if (!this.isWeekPeriod) {
        return settings;
    }

    const self = this;
    settings.items.push({
        id: "weeks",
        label: Language.get("cal_list_weeks"),
        type: "array",
        limit: 0,
        get: self.getWeeks.bind(self),
        set(val) {
            const allWeeks = self.getWeeks();
            if (allWeeks.length === val.length) {
                return self.setWeeks([]);
            }

            return self.setWeeks(val);
        },
    });

    return settings;
};

DatePeriodHeader.prototype.getSimplePeriodIndex = function (entry, onlyVisible = false) {
    if (!this.isSimplePeriod()) {
        throw new Error("Cannot use getSimplePeriodIndex if period is not a simple period.");
    }

    const isMatch = (value) => value.equals(entry.startTimes[0].getMillenniumDate());
    const index = _.flatten(this.getValues()).findIndex(isMatch);
    if (!onlyVisible) {
        return index;
    }
    return index - this.firstVisibleValue;
};

DatePeriodHeader.prototype.periodsToJSON = function () {
    return _.flatten(
        this.getValues().map((dates) => {
            if (dates.length > 0) {
                return dates.map((date) => date.getDayNumber());
            }
            return [null];
        })
    );
};

DatePeriodHeader.prototype.toJSON = function () {
    const json = PeriodHeader.prototype.toJSON.call(this);
    return _.extend(json, {
        dayProvider: true,
        kind: "perioddate",
        periods: this.values.map((dates) => dates.map((date) => date.getDayNumber())),
        weeks: this.weeks.map((week) => `20${week.week()}`),
        futureWeeksOnly: this.futureWeeksOnly,
    });
};

module.exports = DatePeriodHeader;
