import {
    MillenniumDate,
    MillenniumWeek,
    MillenniumWeekday,
    SimpleDateFormat,
} from "millennium-time";
const Header = require("./Header");
const Locale = require("../lib/Language");
const _ = require("underscore");

const WEEK_SIZE = 7;
const VISIBLE_WEEKS = 4;

const WeekHeader = function (visibleValues, firstVisibleValue, subheader) {
    Header.call(this, visibleValues || 1, firstVisibleValue, subheader, "WeekHeader");
    this.start = new MillenniumWeek(
        MillenniumDate.today(),
        Locale.firstDayOfWeek,
        Locale.daysInFirstWeek
    );
    this.weekdays = [Locale.firstDayOfWeek];
    this.numberOfWeeks = 52;
    this.size = 18;
};

WeekHeader.parse = function (data, limits) {
    const header = new WeekHeader(VISIBLE_WEEKS);

    const startDate = limits.getStartDate();
    const endDate = limits.getEndDate();
    header.start = new MillenniumWeek(startDate, Locale.firstDayOfWeek, Locale.daysInFirstWeek);
    const endWeek = new MillenniumWeek(endDate, Locale.firstDayOfWeek, Locale.daysInFirstWeek);
    header.numberOfWeeks = endWeek.weeksBetween(header.start);
    header.size = data.size;

    const currentWeek = new MillenniumWeek(
        startDate.addDays(data.firstValue * WEEK_SIZE),
        Locale.firstDayOfWeek,
        Locale.daysInFirstWeek
    );
    header._firstVisibleValue = header.indexOf(currentWeek, false);
    header.visibleValues = data.visibleValues;

    header.weekdays = [];
    for (let i = 0; i < data.weekDays.length; i++) {
        header.weekdays.push(MillenniumWeekday.getByRepresentation(data.weekDays[i]));
    }
    if (header.weekdays.length === 0) {
        header.weekdays.push(Locale.firstDayOfWeek);
    }

    return header;
};

WeekHeader.prototype = Object.create(Header.prototype);

WeekHeader.prototype.indexOf = function (entry, onlyVisible) {
    let firstVisible = this.start;
    if (onlyVisible) {
        const firstVisibleDay = firstVisible
            .getStartOfWeek()
            .addWeeks(this.firstVisibleValue)
            .getMillenniumDate();
        firstVisible = new MillenniumWeek(
            firstVisibleDay,
            Locale.firstDayOfWeek,
            Locale.daysInFirstWeek
        );
    }
    let entryWeek;
    if (entry instanceof MillenniumWeek) {
        entryWeek = entry;
    } else {
        entryWeek = new MillenniumWeek(
            entry.startTimes[0],
            Locale.firstDayOfWeek,
            Locale.daysInFirstWeek
        );
    }
    return entryWeek.weeksBetween(firstVisible);
};

WeekHeader.prototype.lastIndexOf = function (entry, onlyVisible) {
    let firstVisible = this.start;
    if (onlyVisible) {
        const firstVisibleDay = firstVisible
            .getStartOfWeek()
            .addWeeks(this.firstVisibleValue)
            .getMillenniumDate();
        firstVisible = new MillenniumWeek(
            firstVisibleDay,
            Locale.firstDayOfWeek,
            Locale.daysInFirstWeek
        );
    }
    let entryWeek;
    if (entry instanceof MillenniumWeek) {
        entryWeek = entry;
    } else {
        entryWeek = new MillenniumWeek(
            entry.endTimes[0],
            Locale.firstDayOfWeek,
            Locale.daysInFirstWeek
        );
    }
    return entryWeek.weeksBetween(firstVisible) + 1;
};

WeekHeader.prototype.valueAt = function (index, onlyVisible = true) {
    if (index < 0 || (onlyVisible && index >= this.visibleValues)) {
        throw new Error(`Index out of bounds in WeekHeader.valueAt(${index})`);
    }

    const values = onlyVisible ? this.getVisibleValues() : this.getValues();
    return values[index];
};

WeekHeader.prototype.setLimits = function (limits) {
    const header = Header.prototype.setLimits.call(this, limits);

    const diff = {};
    const startDate = limits.getStartDate();
    const endDate = limits.getEndDate();
    diff.start = new MillenniumWeek(startDate, Locale.firstDayOfWeek, Locale.daysInFirstWeek);
    const endWeek = new MillenniumWeek(endDate, Locale.firstDayOfWeek, Locale.daysInFirstWeek);
    diff.numberOfWeeks = endWeek.weeksBetween(diff.start);

    diff.firstVisibleValue = header.firstVisibleValue;
    diff.firstVisibleValue =
        diff.firstVisibleValue +
        (diff.start.week() < this.start.week()
            ? this.start.weeksBetween(diff.start)
            : -this.start.weeksBetween(diff.start));
    if (diff.firstVisibleValue < 0) {
        diff.firstVisibleValue = 0;
    }

    if (diff.start.addWeeks(diff.firstVisibleValue + this.visibleValues).week() > endWeek.week()) {
        diff.firstVisibleValue = diff.numberOfWeeks - this.visibleValues;
    }
    if (diff.firstVisibleValue < 0) {
        diff.visibleValues = this.visibleValues - Math.abs(diff.firstVisibleValue);
        diff.firstVisibleValue = 0;
    }

    return header.immutableSet(diff);
};

WeekHeader.prototype.getInfo = function (value) {
    if (value.week() !== this.getVisibleValues()[0].week()) {
        return null;
    }

    const text = [];
    const weekdays = this.weekdays;
    const numDays = weekdays.length;
    let i;
    for (i = 0; i < numDays; i++) {
        text.push(SimpleDateFormat.format(weekdays[i], "EEE"));
    }
    return text.join(", ");
};

const getCustomWeek = (week, customWeekNames) => {
    const name = _.find(
        customWeekNames,
        (customWeek) => customWeek.dayNumber === week.date.dayNumber
    );
    if (name) {
        return name;
    }
    return null;
};

WeekHeader.prototype.getLabel = function (value, size, index, onlyVisible, customWeekNames = []) {
    if (customWeekNames.length > 0) {
        const customWeek = getCustomWeek(value, customWeekNames);
        if (customWeek !== null) {
            if (size < Header.Label.L) {
                return customWeek.getShortestName();
            }
            return customWeek.getLongestName();
        }
    }
    if (size < Header.Label.L) {
        return SimpleDateFormat.format(value.date, Locale.getDateFormat("date_f_w"));
    }

    return SimpleDateFormat.format(value.date, Locale.getDateFormat("date_f_yyyy_ww"));
};

WeekHeader.prototype.isCurrent = function (week, currentDateTime) {
    if (!currentDateTime) {
        return false;
    }
    return new MillenniumWeek(
        currentDateTime.getMillenniumDate(),
        Locale.firstDayOfWeek,
        Locale.daysInFirstWeek
    ).isSameWeekAs(week);
};

const getValues = function (startDate, limit) {
    const values = [];
    let i;
    let date = startDate;
    for (i = 0; i < limit; i++) {
        values.push(new MillenniumWeek(date, Locale.firstDayOfWeek, Locale.daysInFirstWeek));
        date = new MillenniumDate(date.getDayNumber() + WEEK_SIZE);
    }
    return values;
};

WeekHeader.prototype.getValues = function () {
    return getValues(this.start.date, this.numberOfWeeks);
};

WeekHeader.prototype.getVisibleValues = function () {
    return getValues(
        new MillenniumDate(this.start.date.addWeeks(this.firstVisibleValue)),
        this.visibleValues
    );
};

WeekHeader.prototype.containsDate = function (date) {
    const week = new MillenniumWeek(date, Locale.firstDayOfWeek, Locale.daysInFirstWeek);
    return this.getValues().some((headerWeek) => headerWeek.isSameWeekAs(week));
};

WeekHeader.prototype.getIndexOfDate = function (date) {
    const week = new MillenniumWeek(date, Locale.firstDayOfWeek, Locale.daysInFirstWeek);
    let weekIndex = -1;
    this.getValues().forEach((headerWeek, index) => {
        if (headerWeek.isSameWeekAs(week)) {
            weekIndex = index;
        }
    });
    return weekIndex;
};

WeekHeader.prototype.getSettings = function (providers) {
    const settings = Header.prototype.getSettings.call(this);
    const self = this;

    if (providers.weekday === true) {
        return settings;
    }

    settings.items.push({
        id: "weekdays",
        label: Locale.get("cal_list_weekdays"),
        type: "array",
        limit: WEEK_SIZE,
        get: self.getWeekdays.bind(self),
        set: (val) => self.immutableSet({ weekdays: val }),
    });

    return settings;
};

WeekHeader.prototype.getWeekdays = function () {
    const labels = Locale.getWeekdayLabels();
    const values = MillenniumWeekday.getLocalizedWeekdayList(Locale.firstDayOfWeek).map((val) => ({
        value: val,
        label: labels[val.getDay()],
    }));

    return values.map((item) =>
        _.extend(item, {
            selected: this.weekdays.some((weekday) => weekday === item.value),
        })
    );
};

WeekHeader.prototype.toJSON = function () {
    const json = Header.prototype.toJSON.call(this);
    return _.extend(json, {
        dayProvider: true,
        kind: "week",
        size: this.size,
        weekdays: this.weekdays.map((weekday) => weekday.getRepresentation()),
    });
};

module.exports = WeekHeader;
