const React = require("react");
const ObjectDefinition = require("./ObjectDefinition");
const Language = require("../lib/Language");

class ObjectManager extends React.Component {
    handleHideObjectManager = (event, checkCallback) => {
        this.props.handleHideObjectManager(event, checkCallback);
    };

    reset = () => {
        if (this.refs.objectDefinition) {
            this.refs.objectDefinition.reset();
        }
    };

    render() {
        const MANAGER_WIDTH_DEDUCTION = 400;
        const MANAGER_HEIGHT_DEDUCTION = 200;
        const managerStyle = {
            width: this.props.width - MANAGER_WIDTH_DEDUCTION,
            height: this.props.height - MANAGER_HEIGHT_DEDUCTION,
            top: 0,
            left: 0,
        };
        const MANAGER_TOP_DIVISOR = 3;
        const MANAGER_LEFT_DIVISOR = 2;
        managerStyle.top = (this.props.height - managerStyle.height) / MANAGER_TOP_DIVISOR;
        managerStyle.left = (this.props.width - managerStyle.width) / MANAGER_LEFT_DIVISOR;

        const MANAGER_SCROLL_AREA_HEIGHT_DEDUCTION = 70;
        const managerScrollStyle = {
            top: 0,
            left: 0,
            width: "100%",
            height: managerStyle.height - MANAGER_SCROLL_AREA_HEIGHT_DEDUCTION,
        };

        return (
            <div id="objectManager" className={"modal"} style={managerStyle}>
                <div className="close" onClick={this.handleHideObjectManager}>
                    X
                </div>
                <h1>{this.props.title}</h1>
                <div className="verticalScrollArea" style={managerScrollStyle}>
                    <ObjectDefinition
                        ref="objectDefinition"
                        useCreateLabel={
                            this.props.title === Language.get("cal_selected_copy_object")
                        }
                        viewOnly={this.props.viewOnly}
                        types={this.props.types}
                        objectIds={this.props.objectIds}
                        incompleteObjects={this.props.incompleteObjects}
                        createCopy={this.props.createCopy}
                        onCancel={this.handleHideObjectManager}
                        onSave={this.props.onSave}
                        setOnCloseCheck={this.props.setOnCloseCheck}
                        newFieldValues={this.props.newFieldValues}
                    />
                </div>
            </div>
        );
    }
}

module.exports = ObjectManager;
