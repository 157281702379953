import {
    MillenniumDate,
    MillenniumWeek,
    MillenniumWeekday,
    SimpleDateFormat,
} from "millennium-time";
const PeriodHeader = require("./PeriodHeader");
const Language = require("../lib/Language");
const _ = require("underscore");

const DAYS_IN_WEEK = 7;

const getWeekLimits = (limits) => ({
    start: new MillenniumWeek(
        limits.getStartDate(),
        Language.firstDayOfWeek,
        Language.daysInFirstWeek
    ),
    end: new MillenniumWeek(limits.getEndDate(), Language.firstDayOfWeek, Language.daysInFirstWeek),
});

const WeekdayPeriodHeader = function (visibleValues, firstVisibleValue, subheader) {
    PeriodHeader.call(
        this,
        visibleValues || 1,
        firstVisibleValue,
        subheader,
        "WeekdayPeriodHeader"
    );
    this.weeks = [];
    this.futureWeeksOnly = false;
};

// eslint-disable-next-line no-unused-vars
WeekdayPeriodHeader.parse = function (data, limits) {
    const header = new WeekdayPeriodHeader(data.visibleValues, data.firstValue);
    header.id = data.id;
    header.name = data.name;
    header.names = data.names;
    header.size = data.size;

    header.values = [];
    let tmp;
    const weekdaySort = function (a, b) {
        return a.getAbsoluteWeekdayNumber() - b.getAbsoluteWeekdayNumber();
    };
    for (let i = 0; i < data.periods.length; i++) {
        tmp = [];
        const periodValues = _.uniq(data.periods[i]);
        for (let j = 0; j < periodValues.length; j++) {
            tmp.push(MillenniumWeekday.getByRepresentation(periodValues[j]));
        }
        tmp.sort(weekdaySort);
        header.values.push(tmp);
    }

    header.weeks = MillenniumWeek.create(
        data.weeks,
        Language.firstDayOfWeek,
        Language.daysInFirstWeek
    );
    if (header.weeks.length === 0) {
        header.weeks.push(MillenniumWeek.today(Language.firstDayOfWeek, Language.daysInFirstWeek));
    }
    header.futureWeeksOnly = data.futureWeeksOnly || false;

    header.validateValues();

    return header;
};

WeekdayPeriodHeader.prototype = Object.create(PeriodHeader.prototype);

WeekdayPeriodHeader.prototype.getKind = function () {
    return PeriodHeader.KIND.WEEKDAY;
};

WeekdayPeriodHeader.prototype.getSettings = function (providers) {
    const settings = PeriodHeader.prototype.getSettings.call(this);
    const self = this;

    const labels = Language.getWeekdayLabels();
    settings.items.push({
        id: "period",
        label: Language.get("cal_list_weekdays"),
        type: "groups",
        availableValues() {
            return MillenniumWeekday.getLocalizedWeekdayList(Language.firstDayOfWeek).map(
                (weekday) => ({ value: weekday.getDay(), label: labels[weekday.getDay()] })
            );
        },
        get() {
            return {
                names: self.names,
                values: self.values.map((period) => period.map((item) => item.getDay())),
            };
        },
        set(groups) {
            return self.immutableSet({
                names: groups.names,
                values: groups.values.map((period) =>
                    period.map((item) => MillenniumWeekday.getByWeekdayNumber(item))
                ),
            });
        },
    });

    if (providers.week === true) {
        return settings;
    }

    settings.items.push({
        id: "weeks",
        label: Language.get("cal_list_weeks"),
        type: "array",
        limit: 0,
        get: self.getWeeks.bind(self),
        set(val) {
            return self.immutableSet({ weeks: val });
        },
    });

    return settings;
};

WeekdayPeriodHeader.prototype.getSimplePeriodIndex = function (entry, onlyVisible = false) {
    if (!this.isSimplePeriod()) {
        throw new Error("Cannot use getSimplePeriodIndex if period is not a simple period.");
    }

    const isMatch = (value) => value.getAbsoluteWeekdayNumber() === entry.startTimes[0].getDay();
    const index = _.flatten(this.getValues()).findIndex(isMatch);
    if (!onlyVisible) {
        return index;
    }
    return index - this.firstVisibleValue;
};

WeekdayPeriodHeader.prototype.getIndexOfDate = function (date, onlyVisible = false) {
    const weekday = date.getDay(); // 0 (Sunday) to 6 (Saturday)
    // Not found if weekday not displayed
    const values = onlyVisible ? this.getVisibleValues() : this.values;
    return values.findIndex((weekdays) => {
        const days = weekdays.map((wd) => wd.getAbsoluteWeekdayNumber());
        return days.indexOf(weekday) !== -1;
    });
};

const getCustomWeekName = (week, customWeekNames) => {
    const name = _.find(
        customWeekNames,
        (customWeek) => customWeek.dayNumber === week.date.dayNumber
    );
    if (name) {
        return name.getLongestName();
    }
    return null;
};

WeekdayPeriodHeader.prototype.getWeeks = function (customWeekNames = []) {
    const values = [];

    const startDay = this.limits.getStartDate().getDayNumber();
    const weekLimits = getWeekLimits(this.limits);
    for (let day = startDay; day < startDay + this.limits.dayCount; day = day + DAYS_IN_WEEK) {
        const week = new MillenniumWeek(
            new MillenniumDate(day),
            Language.firstDayOfWeek,
            Language.daysInFirstWeek
        );
        const label =
            getCustomWeekName(week, customWeekNames) ||
            SimpleDateFormat.format(
                week.getStartOfWeek(),
                Language.getDateFormat("date_f_yyyy_ww_l")
            );
        if (week.week() >= weekLimits.start.week() && week.week() <= weekLimits.end.week()) {
            values.push({
                value: week,
                label,
            });
        }
    }

    return values.map((item) =>
        _.extend(item, {
            selected: this.weeks.some((week) => week.week() === item.value.week()),
        })
    );
};

WeekdayPeriodHeader.prototype.periodsToJSON = function () {
    const weekdayList = MillenniumWeekday.getLocalizedWeekdayList(Language.firstDayOfWeek);
    return _.flatten(
        this.values.map((weekdays) =>
            weekdays.map((weekday) =>
                weekdayList.findIndex(
                    (element) => element.absoluteWeekdayNumber === weekday.absoluteWeekdayNumber
                )
            )
        ) // Return relative weekday numbers, first day of week being 0
    );
};

WeekdayPeriodHeader.prototype.toJSON = function () {
    const json = PeriodHeader.prototype.toJSON.call(this);
    return _.extend(json, {
        dayProvider: true,
        kind: "periodweekday",
        periods: this.values.map((weekdays) =>
            weekdays.map((weekday) => weekday.getRepresentation())
        ),
        weeks: this.weeks.map((week) => `20${week.week()}`),
        futureWeeksOnly: this.futureWeeksOnly,
    });
};

module.exports = WeekdayPeriodHeader;
