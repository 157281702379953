import { MillenniumTime } from "millennium-time";
const PeriodHeader = require("./PeriodHeader");
const _ = require("underscore");

const TimePeriodHeader = function (visibleValues, firstVisibleValue, subheader) {
    PeriodHeader.call(this, visibleValues || 1, firstVisibleValue, subheader, "TimePeriodHeader");
};

// eslint-disable-next-line no-unused-vars
TimePeriodHeader.parse = function (data, limits) {
    // eslint-disable-line no-unused-vars
    const header = new TimePeriodHeader(data.visibleValues, data.firstValue);
    header.id = data.id;
    header.name = data.name;
    header.names = data.names;
    header.size = data.size;

    const timestampsToObjects = function (range) {
        return {
            start: new MillenniumTime(range.start),
            end: new MillenniumTime(range.end),
        };
    };

    header.values = [];
    for (let i = 0; i < data.periods.length; i++) {
        header.values.push(data.periods[i].map(timestampsToObjects));
    }

    header.validateValues();

    return header;
};

TimePeriodHeader.prototype = Object.create(PeriodHeader.prototype);

TimePeriodHeader.prototype.getKind = function () {
    return PeriodHeader.KIND.TIME;
};

/**
 * A simple period is a period where all cells only contains a single value and
 * no values overlap eachother. However, since entries are only split into subentries
 * on a per-date-basis, one such entry can be part of multiple periods. Therefore
 * we must handle all time periods as non-simple.
 */
// eslint-disable-next-line no-unused-vars
TimePeriodHeader.prototype.isSimplePeriod = function (index) {
    // eslint-disable-line no-unused-vars
    return false;
};

TimePeriodHeader.prototype.periodsToJSON = function () {
    return this.values.map((times) =>
        times.map((range) => ({
            start: range.start.getTimeNumber(),
            end: range.end.getTimeNumber(),
        }))
    );
};

TimePeriodHeader.prototype.toJSON = function () {
    const json = PeriodHeader.prototype.toJSON.call(this);
    return _.extend(json, {
        timeProvider: true,
        kind: "periodtime",
        periods: this.periodsToJSON(),
    });
};

module.exports = TimePeriodHeader;
