const Model = require("./Model");
const TemplateKind = require("./TemplateKind");
const Language = require("../lib/Language");
const Selection = require("./Selection");
import { MillenniumDate, MillenniumDateTime } from "millennium-time";
const Limits = require("./Limits");
const Settings = require("./Settings");

const DEFAULT_LIST_ID = 4;

const CancellationSearch = function (limits = Limits.getDefaultList()) {
    Model.call(this, "CancellationSearch");
    this.templateKind = TemplateKind.RESERVATION;
    this.privateSelected = true;
    this.selection = new Selection();
    this.limits = limits;
    this.listId = DEFAULT_LIST_ID;
    this.isTimePeriod = false;
    this.periodId = 0;
};

CancellationSearch.prototype = Object.create(Model.prototype);

CancellationSearch.prototype.getSettings = function () {
    const self = this;
    const settings = [
        {
            id: "isPrivate",
            label: Language.get("cal_header_topleft_ignore_selected"),
            type: "boolean",
            get() {
                return self.privateSelected;
            },
            set(isPrivate) {
                if (isPrivate === self.privateSelected) {
                    return self;
                }

                let selection = null;
                if (isPrivate) {
                    selection = new Selection();
                }

                return self.immutableSet({
                    privateSelected: isPrivate,
                    selection,
                });
            },
        },
        {
            id: "isStartDayAbsolute",
            label: Language.get("nc_cal_res_side_view_limit_type"),
            type: "array",
            limit: 1,
            get() {
                return [
                    {
                        label: Language.get("nc_cal_res_side_view_relative_limits"),
                        value: false,
                        selected: self.limits.isStartDayAbsolute === false,
                    },
                    {
                        label: Language.get("nc_cal_res_side_view_absolute_limits"),
                        value: true,
                        selected: self.limits.isStartDayAbsolute === true && !self.isTimePeriod,
                    },
                    {
                        label: Language.get("nc_header_type_time_period"),
                        value: null,
                        selected:
                            self.isTimePeriod === true && self.limits.isStartDayAbsolute === true,
                    },
                ];
            },
            set(val) {
                let isTimePeriod = false;
                if (val === null) {
                    isTimePeriod = true;
                    // eslint-disable-next-line no-param-reassign
                    val = true;
                }

                return self
                    .setLimits(self.limits.setIsStartDayAbsolute(val))
                    .setIsTimePeriod(isTimePeriod);
            },
        },
    ];
    if (self.limits.isStartDayAbsolute && !self.isTimePeriod) {
        settings.push({
            id: "startDate",
            label: Language.get("cal_res_side_view_start_day"),
            type: "date",
            get() {
                return self.limits.getStartDate();
            },
            set(val) {
                if (self.limits.getStartDate().equals(val)) {
                    return self;
                }

                return self.setLimits(self.limits.setStartDayFromDate(val));
            },
        });
        settings.push({
            id: "endDate",
            label: Language.get("cal_res_side_view_end_day"),
            type: "date",
            get() {
                return self.limits.getEndDate();
            },
            set(val) {
                if (self.limits.getEndDate().equals(val)) {
                    return self;
                }

                return self.setLimits(self.limits.setDayCountFromDate(val));
            },
        });
    } else if (self.limits.isStartDayAbsolute === true && self.isTimePeriod) {
        settings.push({
            id: "period",
            label: Language.get("nc_header_type_time_period"),
            type: "periodtype",
            limit: 1,
            get() {
                return self.periodId; // Map periods, setting selected if start and end match what's set in the calendar
            },
            set(val) {
                const newLimits = self.limits
                    .setIsStartDayAbsolute(true)
                    .setStartDay(val.start_date);
                return self
                    .setLimits(
                        newLimits.setDayCountFromDate(new MillenniumDate(val.end_date), true)
                    )
                    .setPeriodId(val.id);
            },
        });
    } else {
        settings.push({
            id: "startDate",
            label: Language.get("cal_res_side_view_start_day"),
            type: "dateoffset",
            get() {
                return self.limits.startDay;
            },
            set(val) {
                if (self.limits.startDay === val) {
                    return self;
                }

                return self.setLimits(self.limits.setStartDay(val));
            },
        });
        settings.push({
            id: "endDate",
            label: Language.get("cal_res_side_view_end_day"),
            type: "dateoffset",
            get() {
                return self.limits.dayCount + self.limits.startDay;
            },
            set(val) {
                if (self.limits.dayCount === val) {
                    return self;
                }

                const newLimits = new Limits(self.limits);
                newLimits.dayCount = val - self.limits.startDay;
                return self.setLimits(newLimits);
            },
        });
    }
    return new Settings(settings);
};

CancellationSearch.prototype.getMacroFunctions = function () {
    const macros = [];
    return macros;
};

CancellationSearch.prototype.setLimits = function (limits) {
    if (!(limits instanceof Limits)) {
        // eslint-disable-next-line no-param-reassign
        limits = new Limits(limits);
    }

    return this.immutableSet({
        limits,
    });
};

CancellationSearch.prototype.setIsTimePeriod = function (isTimePeriod) {
    return this.immutableSet({ isTimePeriod }).immutableSet({ periodId: 0 });
};

CancellationSearch.prototype.setPeriodId = function (periodId) {
    return this.immutableSet({ periodId });
};

CancellationSearch.prototype.updateTimeRange = function (newBeginTime, newEndTime) {
    let limits = new Limits(this.limits);
    limits = limits.setStartDayFromDate(new MillenniumDateTime(newBeginTime).getMillenniumDate());
    limits = limits.setDayCountFromDate(new MillenniumDateTime(newEndTime).getMillenniumDate());
    return this.immutableSet({
        limits,
    });
};

// What about the search settings object and such? How to even get hold of it from here?
CancellationSearch.prototype.toJSON = function () {
    return {
        limits: this.limits,
        privateSelectedList: this.privateSelected,
        function: this.getMacroFunctions(),
        templateKind: this.templateKind.mode,
        isList: false,
        isCancellationList: true,
        listId: this.listId,
        periodId: this.periodId,
    };
};

module.exports = CancellationSearch;
